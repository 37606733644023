import React from 'react';
import { Loader } from '@aws-amplify/ui-react';

import { Row, TypographyText } from 'components/UI/atoms';

export const Loading = ({
  mode,
  message = '잠시만 기다려주세요.',
  circleColor = 'gold',
  circleWidth = '80px',
  circleHeight = '80px',
}: {
  mode?: string;
  message?: string;
  circleColor?: string;
  circleWidth?: string;
  circleHeight?: string;
}) => {
  let backgroundStyle: any = {
    top: 0,
    left: '14rem',
    zIndex: 990,
    height: '100vh',
    width: 'calc(100% - 14rem)', // 사이드바 너비 14rem
  };
  switch (mode) {
    case 'global':
    case 'globalWithMessage':
      backgroundStyle = {
        ...backgroundStyle,
        zIndex: 999,
        left: 0,
        width: '100%',
        background: 'rgba(28,37,44, 0.5)',
      };
      break;
  }
  return (
    <Row
      style={{
        position: 'fixed',
        ...backgroundStyle,
      }}
    >
      <Row style={{ height: '100%' }}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {mode === 'globalWithMessage' ? (
            <Row
              style={{
                padding: '2.4rem 3.3rem',
                background: '#ffffff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                rowGap: '1.5rem',
                textAlign: 'center',
              }}
            >
              <Loader size="large" filledColor={circleColor} width={circleWidth} height={circleHeight} />
              <TypographyText fontSize="1.2rem" lineHeight="1.2em" whiteSpace="pre-wrap" wordBreak="keep-all">
                {message}
              </TypographyText>
            </Row>
          ) : (
            <Loader size="large" filledColor={circleColor} width={circleWidth} height={circleHeight} />
          )}
        </Row>
      </Row>
    </Row>
  );
};
