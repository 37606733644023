export const getReportForByeonjeung = /* GraphQL */ `
  query GetReportCustom($treatmentID: ID!) {
    getReport(treatmentID: $treatmentID) {
      treatmentID
      byeonjeung
    }
  }
`;

export const updateTreatment = /* GraphQL */ `
  mutation UpdateTreatment($input: UpdateTreatmentInput!, $condition: ModelTreatmentConditionInput) {
    updateTreatment(input: $input, condition: $condition) {
      id
      clinicID
      surveyID
      patientID
      save
      feedbackID
      mainSymptom
      userID
      status
      cure
      memo
      lastVisitedAt
      selectSymptom {
        code
        check
        __typename
      }
      symptomCheck
      name
      birth
      gender
      prescriptionStatus
      updatedAt
      createdAt
      amount
      isVisited
      isDeleted
      surveyCheck
      herbsForRecommanded
      treatmentedAt
      treatmentStatus
      patientNameForSearching
      selectedBCode
      prescriptionMode
      prescribedRecipe {
        pc
        amt
        __typename
      }
      prescriptionId
      customHerbalFormulaId
      prescriptionDocument
      footprintsLog
      __typename
    }
  }
`;

export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey($input: UpdateSurveyInput!, $condition: ModelSurveyConditionInput) {
    updateSurvey(input: $input, condition: $condition) {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
  }
`;

export const updateSelfRegistrationQR = /* GraphQL */ `
  mutation UpdateSelfRegistrationQR(
    $input: UpdateSelfRegistrationQRInput!
    $condition: ModelSelfRegistrationQRConditionInput
  ) {
    updateSelfRegistrationQR(input: $input, condition: $condition) {
      QRID
      clinicID
      expiredDate
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole($input: UpdateUserRoleInput!, $condition: ModelUserRoleConditionInput) {
    updateUserRole(input: $input, condition: $condition) {
      id
      clinicID
      config
      clinicName
      status
      email
      phone
      userID
      roleName
      userName
      isDeleted
      testFlag
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient($input: UpdatePatientInput!, $condition: ModelPatientConditionInput) {
    updatePatient(input: $input, condition: $condition) {
      id
      clinicID
      patientName
      birth
      residentRegistrationNumber
      gender
      phone
      recentActivity
      recentActivityMessage
      recentActivityCode
      createdAt
      prescriptionStatus
      isWaiting
      isVisited
      symptomID
      mainSymptom
      prescriptionID
      surveyID
      userID
      treatmentID
      patientNameForSorting
      surveyedAt
      updatedAt
      treatmentStatus
      treatmentStatusDateForSorting
      address
      registrationPath
      isDeleted
      __typename
    }
  }
`;

export const updateCustomHerbalFormula = /* GraphQL */ `
  mutation UpdateCustomHerbalFormula(
    $input: UpdateCustomHerbalFormulaInput!
    $condition: ModelCustomHerbalFormulaConditionInput
  ) {
    updateCustomHerbalFormula(input: $input, condition: $condition) {
      id
      ownerUserID
      originalHerbalFormulaID
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createCustomHerbalFormula = /* GraphQL */ `
  mutation CreateCustomHerbalFormula(
    $input: CreateCustomHerbalFormulaInput!
    $condition: ModelCustomHerbalFormulaConditionInput
  ) {
    createCustomHerbalFormula(input: $input, condition: $condition) {
      id
      ownerUserID
      originalHerbalFormulaID
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteCustomHerbalFormula = /* GraphQL */ `
  mutation DeleteCustomHerbalFormula(
    $input: DeleteCustomHerbalFormulaInput!
    $condition: ModelCustomHerbalFormulaConditionInput
  ) {
    deleteCustomHerbalFormula(input: $input, condition: $condition) {
      id
      ownerUserID
      originalHerbalFormulaID
      insrCCode
      insrDsCode
      InsrPrscrType
      kn
      herbCodes
      processingCodes
      recipe {
        pc
        amt
        __typename
      }
      scoringType
      memo
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createClinicsForPromotion = /* GraphQL */ `
  mutation CreateClinicsForPromotion(
    $input: CreateClinicsForPromotionInput!
    $condition: ModelClinicsForPromotionConditionInput
  ) {
    createClinicsForPromotion(input: $input, condition: $condition) {
      id
      promotionType
      clinicID
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey($input: CreateSurveyInput!, $condition: ModelSurveyConditionInput) {
    createSurvey(input: $input, condition: $condition) {
      id
      otp
      patientID
      clinicID
      link
      surveyResult
      mainSymptom
      symptoms
      visitCount
      firstLinkClickDt
      surveyedAt
      updatedAt
      createdAt
      sendCount
      isSend
      treatmentID
      feedbacks
      feedbackResult
      feedbackAt
      feedbackLink
      feedbackDone
      isFeedback
      additionalSymptoms
      feedbackSendTime
      feedbackSchedule_GSI_PK
      feedbackScheduleStatus
      surveyDone
      surveyGrade
      surveySatisfaction
      feedbackGrade
      feedbackSatisfaction
      surveyStatus
      expiredAt
      mainSymptomCategory
      sender
      surveyType
      insuranceType
      surveySurveyBizTalkHistoryId
      surveyFeedbackBizTalkHistoryId
      __typename
    }
  }
`;
