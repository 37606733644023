import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
  GetPatientQuery,
  ModelSortDirection,
  PatientsByClinicQuery,
  UpdatePatientInput,
  UpdateDailyPatientCountInput,
  UpdateDailyPatientCountMutation,
} from '../API';

import _ from 'lodash';
import { repeatCallingListApiToLimit } from 'utils/util';
import * as customs from '../graphql/custom';

class PatientRepository {
  // Basic Query
  getPatient = async (patientId: string) => {
    // console.log("단일 환자 조회");
    const patient = (await API.graphql(
      graphqlOperation(
        queries.getPatient,
        { id: patientId },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as {
      data: GetPatientQuery;
    };

    return patient.data;
  };

  listDailyPatientCounts = async (nextToken?: string) => {
    const res = await repeatCallingListApiToLimit({
      limit: Infinity,
      queryLimitBeforeFilter: 3000,
      nextToken,
      targetGraphqlListQuery: queries.listDailyPatientCounts,
      targetGraphqlListFunctionDataFieldName: 'listDailyPatientCounts',
      targetGraphqlListQueryParam: { nextToken },
    });
    return res;
  };

  updateDailyPatientCount = async (input: UpdateDailyPatientCountInput) => {
    const response = (await API.graphql(
      graphqlOperation(
        mutations.updateDailyPatientCount,
        { input },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: UpdateDailyPatientCountMutation };
    return response.data;
  };

  patientsByRecentActivity = async ({
    clinicID,
    nextToken,
    searchParam,
  }: {
    clinicID: string;
    nextToken?: string;
    searchParam?: {
      patientNameOrPhone?: string;
      selectedFilter?: string[];
    };
  }) => {
    // console.log("최근활동순 환자 조회");
    const param = {
      clinicID: clinicID,
      sortDirection: ModelSortDirection.DESC,
      filter: {
        isDeleted: { ne: true },
      },
    };
    if (nextToken) param['nextToken'] = nextToken;
    if (searchParam?.patientNameOrPhone) {
      param['filter']['and'] = [
        ...(param['filter']['and'] ?? []),
        { patientNameForSorting: { contains: searchParam.patientNameOrPhone.toLowerCase() } },
      ];
    }
    if (!_.isEmpty(searchParam?.selectedFilter)) {
      param['filter']['or'] = [
        ...(param['filter']['or'] ?? []),
        ...searchParam.selectedFilter.map((e) => ({ recentActivityCode: { eq: e } })),
      ];
    }
    const res = await repeatCallingListApiToLimit({
      nextToken,
      targetGraphqlListQuery: queries.patientsByRecentActivity,
      targetGraphqlListFunctionDataFieldName: 'patientsByRecentActivity',
      targetGraphqlListQueryParam: param,
    });
    return res;
  };

  patientsByClinic = async ({
    clinicID,
    createdAtArr,
    limit,
  }: {
    clinicID: string;
    createdAtArr?: string[];
    limit?: number;
  }) => {
    const param: any = {
      clinicID,
      filter: {},
    };
    if (createdAtArr?.length === 2) {
      param.filter.createdAt = { between: [createdAtArr[0], createdAtArr[1]] };
    }
    if (limit) {
      param.limit = limit;
    }
    const patients = (await API.graphql(
      graphqlOperation(
        queries.patientsByClinic,
        param,
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: PatientsByClinicQuery };

    return patients.data.patientsByClinic;
  };

  allPatientsByClinic = async ({ clinicID, nextToken }: { clinicID: string; nextToken?: string }) => {
    const param: any = {
      clinicID,
      filter: {},
    };
    const res = await repeatCallingListApiToLimit({
      limit: Infinity,
      queryLimitBeforeFilter: 5000,
      nextToken,
      targetGraphqlListQuery: queries.patientsByClinic,
      targetGraphqlListFunctionDataFieldName: 'patientsByClinic',
      targetGraphqlListQueryParam: param,
    });
    return res;
  };

  listpatientsByClinic = async ({ clinicID, nextToken }: { clinicID: string; nextToken?: string }) => {
    const param: any = {
      clinicID,
      filter: {},
    };
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      limit: Infinity,
      queryLimitBeforeFilter: 5000,
      nextToken,
      targetGraphqlListQuery: queries.listPatients,
      targetGraphqlListFunctionDataFieldName: 'listPatients',
      targetGraphqlListQueryParam: param,
    });
    return res;
  };

  updatePateint = async (params: UpdatePatientInput) => {
    await API.graphql(
      graphqlOperation(
        customs.updatePatient,
        { input: params },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    );
  };

  listPatients = async ({ params, nextToken }: { params?: { isDeleted?: boolean }; nextToken?: string }) => {
    const param = {
      filter: {},
    };
    if (!params?.isDeleted) {
      param['filter'] = { ...param['filter'], isDeleted: { ne: true } };
    }
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      limit: Infinity,
      queryLimitBeforeFilter: 5000,
      nextToken,
      targetGraphqlListQuery: queries.listPatients,
      targetGraphqlListFunctionDataFieldName: 'listPatients',
      targetGraphqlListQueryParam: param,
    });
    return res;
  };

  patientsByTreatmentStatus = async ({
    clinicID,
    nextToken,
    searchParam,
  }: {
    clinicID: string;
    nextToken?: string;
    searchParam?: {
      patientNameOrPhone?: string;
    };
  }) => {
    const param = {
      clinicID: clinicID,
      treatmentStatusDateForSorting: { beginsWith: '30' },
      sortDirection: ModelSortDirection.DESC,
      filter: {
        treatmentStatus: { between: [30, 50] },
      },
    };

    if (!_.isEmpty(searchParam)) {
      if (searchParam?.patientNameOrPhone) {
        param.filter['patientNameForSorting'] = { contains: searchParam.patientNameOrPhone.toLowerCase() };
      }
    }
    if (nextToken) param['nextToken'] = nextToken;

    const res = await repeatCallingListApiToLimit({
      nextToken,
      targetGraphqlListQuery: queries.patientsByTreatmentStatus,
      targetGraphqlListFunctionDataFieldName: 'patientsByTreatmentStatus',
      targetGraphqlListQueryParam: param,
    });
    return res;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PatientRepository();
