/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import message from 'antd-message';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStores } from 'stores/Context';
import TreatmentContentLayout from 'components/templates/TreatmentContentLayout';
import TreatmentAnalysis from 'components/UI/organisms/treatment/progress/cure/TreatmentAnalysis';
import TreatmentPrescription from 'components/UI/organisms/treatment/progress/prescription/TreatmentPrescription';
import TreatmentSymptomLeft from 'components/UI/organisms/treatment/progress/symptom/TreatmentSymptomLeft';
import TreatmentSymptomRight from 'components/UI/organisms/treatment/progress/symptom/TreatmentSymptomRight';
import Footer from 'components/UI/organisms/treatment/footer';
import { getBrowser } from 'utils/util';
import { treatmentModeMap } from 'constant';
import { isEnableTillStaging } from 'config';
import tr from 'repository/TreatmentRepository';

import TreatmentLayout from '../../templates/TreatmentLayout';
import _ from 'lodash';

const TreatmentDetailPage = () => {
  const {
    treatmentPageStore,
    symptomStore,
    surveyStore,
    treatmentStore,
    clinicStore,
    clientStore,
    patientStore,
    feedbackStore,
  } = useStores();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isTestMode, setIsTestMode] = useState(
    sessionStorage.getItem('treatmentAnalysisTestMode') || 'false',
  );
  const [formulaSearchKeyword, setFormulaSearchKeyword] = useState('');
  const [herbSearchKeyword, setHerbSearchKeyword] = useState('');
  const [selectedProcessingCodes, setSelectedProcessingCodes] = useState([]);
  const [zeroScoringTypeprescription, setZeroScoringTypeprescription] = useState([]);
  const [prescribedHerbs, setPrescribedHerbs] = useState({});
  const [selectedPrescriptionData, setSelectedPrescriptionData] = useState({});
  const [selectedMyPrescriptionData, setSelectedMyPrescriptionData] = useState({});
  const [prescriptionCount, setPrescriptionCount] = useState(20); // 약 첩수
  const [memo, setMemo] = useState('');
  const [treatmentMode, setTreatmentMode] = useState<string>();

  const { id } = useParams();

  // 진료실 이동 기록 갱신
  const updateFootprintsLog = useCallback(async (pageInitial: string) => {
    const res = await tr.getTreatment(id);
    const footprintsLog = res?.getTreatment?.footprintsLog || [];
    await tr.updateTreatment({
      id: treatmentStore.treatment?.id!,
      footprintsLog: [...footprintsLog, pageInitial],
    });
  }, []);

  useEffect(() => {
    // todo
    // treatmentMode가 변경되면
    if (!treatmentModeMap.has(treatmentMode) || treatmentMode === 'GENERAL') {
      // 비급여
      // 처방 초기화
      setSelectedPrescriptionData({});
      setSelectedMyPrescriptionData({});
      setPrescribedHerbs({});
    } else {
      // 급여
      setPrescriptionCount(20); // 첩 수 20개로 변경
      // 처방 초기화
      setSelectedPrescriptionData({});
      setSelectedMyPrescriptionData({});
      setPrescribedHerbs({});
    }
  }, [treatmentMode]);

  const getTreatmentAndSurvey = async () => {
    try {
      await setLoad(true);
      await clinicStore.getClinic(clientStore.metaData?.userRole.clinicID, false);
      // if (!['MASTER', 'DOCTOR'].includes(clinicStore.currentUserRole)) { // todo 한의사 초대 기획 구현되면 복구
      //   message.error(`접근 권한이 없습니다.`, 2500);
      //   navigate('/treatment');
      // } else {
      const treatmentRes = await treatmentStore.getTreatment(id!);

      if (isEnableTillStaging) console.log(JSON.parse(JSON.stringify(treatmentRes)));
      if (treatmentRes?.memo) {
        setMemo(treatmentRes.memo);
      }
      if (treatmentModeMap.has(treatmentRes?.survey?.insuranceType)) {
        setTreatmentMode(treatmentRes?.survey?.insuranceType);
      }
      if (_.isEmpty(treatmentStore.treatment) || treatmentStore.treatment.isDeleted) {
        message.error(`진료 정보를 찾을 수 없습니다.`, 2500);
        navigate('/treatment');
      } else if (treatmentStore.treatment.prescriptionStatus === 2) {
        message.error(`이미 완료된 진료 건입니다.`, 2500);
        navigate('/treatment');
      } else {
        await Promise.all([
          patientStore.getPatient(treatmentStore.treatment?.patientID),
          surveyStore.getSurvey(treatmentStore.treatment?.surveyID!),
        ]);
        treatmentPageStore.setPage(treatmentStore.treatment?.save!);
        setLoad(false);
      }
      // }
    } catch (error) {
      console.error(error);
      message.error(`진료 정보를 찾을 수 없습니다.`, 2500);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    getTreatmentAndSurvey();
    treatmentPageStore.setPage(treatmentStore.treatment?.save!);
    document.getElementsByTagName('body')?.[0].classList.add('content-hidden');

    // safari fullscreenchange event
    if (getBrowser() === 'Safari') {
      document.addEventListener('webkitfullscreenchange', () => {
        // @ts-ignore
        if (document.webkitFullscreenElement) {
          setIsFullScreen(true);
        } else {
          setIsFullScreen(false);
        }
      });
    } else {
      window.addEventListener('fullscreenchange', () => {
        if (document.fullscreenElement) {
          setIsFullScreen(true);
        } else {
          setIsFullScreen(false);
        }
      });
    }

    return () => {
      document.getElementsByTagName('body')?.[0].classList.remove('content-hidden');
      treatmentPageStore.setPage(0);
      treatmentStore.treatmentReset();
      symptomStore.resetSymptom();
      surveyStore.surveyReset();
      patientStore.resetPatient();
      feedbackStore.resetFeedback();
      if (getBrowser() === 'Safari') {
        window.removeEventListener('webkitfullscreenchange', (e) => console.log(e));
      } else {
        window.removeEventListener('fullscreenchange', (e) => console.log(e));
      }
    };
  }, []);

  let content = <></>;
  let footer = <></>;
  let serviceGuideLink;
  let youtubeLinkParam;

  switch (treatmentPageStore.page) {
    case 0:
      content = (
        <TreatmentContentLayout
          tab={treatmentPageStore.page}
          left={
            <TreatmentSymptomLeft
              setTreatmentMode={setTreatmentMode}
              setLoad={setLoad}
              updateFootprintsLog={updateFootprintsLog}
            />
          }
          leftWidthRatio={60}
          leftContentPadding={0}
          rightContentPadding={1}
          right={<TreatmentSymptomRight memo={memo} setMemo={setMemo} />}
        />
      );
      footer = (
        <Footer
          mode="symptom"
          memo={memo}
          prescribedHerbs={prescribedHerbs}
          selectedMyPrescriptionData={selectedMyPrescriptionData}
          selectedPrescriptionData={selectedPrescriptionData}
          prescriptionCount={prescriptionCount}
          treatmentMode={treatmentMode}
        />
      );
      youtubeLinkParam = {
        title: 'Ye-jin Treatment',
        src: 'https://www.youtube.com/embed/gfVThuggBGs?autoplay=1&start=4',
      };
      break;
    case 1:
      content = (
        <TreatmentContentLayout
          tab={treatmentPageStore.page}
          leftWidthRatio={100}
          left={
            <TreatmentAnalysis
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              updateFootprintsLog={updateFootprintsLog}
            />
          }
          isFullScreen={isFullScreen}
        />
      );
      footer = (
        <Footer
          mode="cure"
          prescribedHerbs={prescribedHerbs}
          selectedPrescriptionData={selectedPrescriptionData}
          selectedMyPrescriptionData={selectedMyPrescriptionData}
          prescriptionCount={prescriptionCount}
          treatmentMode={treatmentMode}
        />
      );
      youtubeLinkParam = {
        title: 'Ye-jin Treatment',
        src: 'https://www.youtube.com/embed/gfVThuggBGs?autoplay=1&start=16',
      };
      break;
    case 2:
    case 3:
    case 4:
      content = (
        <TreatmentContentLayout
          tab={treatmentPageStore.page}
          leftWidthRatio={100}
          left={
            <TreatmentPrescription
              treatmentMode={treatmentMode}
              setTreatmentMode={setTreatmentMode}
              formulaSearchKeyword={formulaSearchKeyword}
              setFormulaSearchKeyword={setFormulaSearchKeyword}
              herbSearchKeyword={herbSearchKeyword}
              setHerbSearchKeyword={setHerbSearchKeyword}
              selectedProcessingCodes={selectedProcessingCodes}
              setSelectedProcessingCodes={setSelectedProcessingCodes}
              zeroScoringTypeprescription={zeroScoringTypeprescription}
              setZeroScoringTypeprescription={setZeroScoringTypeprescription}
              prescribedHerbs={prescribedHerbs}
              setPrescribedHerbs={setPrescribedHerbs}
              selectedPrescriptionData={selectedPrescriptionData}
              setSelectedPrescriptionData={setSelectedPrescriptionData}
              prescriptionCount={prescriptionCount}
              setPrescriptionCount={setPrescriptionCount}
              selectedMyPrescriptionData={selectedMyPrescriptionData}
              setSelectedMyPrescriptionData={setSelectedMyPrescriptionData}
              updateFootprintsLog={updateFootprintsLog}
            />
          }
          isFullScreen={isFullScreen}
        />
      );
      footer = (
        <Footer
          mode="prescription"
          prescribedHerbs={prescribedHerbs}
          selectedMyPrescriptionData={selectedMyPrescriptionData}
          selectedPrescriptionData={selectedPrescriptionData}
          prescriptionCount={prescriptionCount}
          treatmentMode={treatmentMode}
        />
      );
      youtubeLinkParam = {
        title: 'Ye-jin Treatment',
        src: 'https://www.youtube.com/embed/gfVThuggBGs?autoplay=1&start=26',
      };
      break;
  }
  return (
    <>
      <TreatmentLayout
        isFullScreen={isFullScreen}
        treatmentData={treatmentStore?.treatment}
        content={content}
        footer={footer}
        sideType={2}
        loading={load}
        serviceGuideLink={serviceGuideLink}
        youtubeLinkParam={youtubeLinkParam}
        isTestMode={isTestMode}
        setIsTestMode={setIsTestMode}
      />
    </>
  );
};

export default observer(TreatmentDetailPage);
