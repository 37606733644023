import { useState, useEffect, useCallback, useMemo } from 'react';

import { Row, TypographyText, ModalWithJustX } from 'components/UI/atoms';
import { isEnableTillStaging } from 'config';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'stores/Context';
import { Loading } from 'components/UI/molecules';

import { SideLink } from 'components/UI/atoms/general/SideLink';
import { SideInterface } from './SideInterface';
import { permissionConverter } from 'utils/DataConvertUtil';
import us from 'repository/UserRepository';

// sideNaveProps
// 0 : 전부다 비활성화
// 1 : 환자 관리만 활성화
// 2 : 진료실만 활성화

const SideBar = ({ type }: { type: number }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenMediaModal, setIsOpenMediaModal] = useState(false);
  const [isOpenYoutubeTutorialNotice, setIsOpenYoutubeTutorialNotice] = useState(false);

  const { clientStore } = useStores();
  const navigate = useNavigate();

  const init = useCallback(async () => {
    const userRes = await clientStore.getUser(clientStore.metaData?.user.id!);
    const config = JSON.parse(userRes?.config ?? '{}');
    if (!config.nonDisplayYoutubeTutorial240524) {
      setIsOpenYoutubeTutorialNotice(true);
    }
  }, []);

  const updateUserConfig = useCallback(
    async ({ userId, userConfig = '{}' }: { userId: string; userConfig: string }) => {
      const config = JSON.parse(userConfig);
      const newConfig = JSON.stringify({
        ...config,
        nonDisplayYoutubeTutorial240524: true,
      });
      await us.updateUser({
        id: userId,
        config: newConfig,
      });
    },
    [],
  );

  useEffect(() => {
    init();
  }, []);

  const menuList = useMemo(() => {
    return [
      {
        label: '동영상 가이드',
        onClickFunc: () => {
          if (isOpenYoutubeTutorialNotice) {
            setIsOpenYoutubeTutorialNotice(false);
            updateUserConfig({
              userId: clientStore.metaData?.user.id!,
              userConfig: clientStore.user?.config,
            });
          }
          setIsOpenMediaModal(true);
        },
      },
      { label: '예진 사용 현황', onClickFunc: () => navigate('/subscription') },
      {
        label: '예진 고객센터',
        onClickFunc: () => window.open('http://pf.kakao.com/_xlixodxj/chat', '_blank'),
      },
    ];
  }, []);

  return (
    <>
      {isLoading && <Loading mode="global" />}
      <Row
        style={{
          width: '14rem',
          backgroundColor: '#F7F9FA',
          padding: '2rem 1.5rem',
          height: '100%',
          position: 'fixed',
          zIndex: 1,
          top: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1.5rem',
          justifyContent: 'space-between',
        }}
      >
        <Row>
          <Row flex alignItems="flex-end" style={{ columnGap: '0.45rem' }}>
            <Row style={{ height: '1.78rem' }}>
              <img
                alt={'yejin'}
                src={`${process.env.PUBLIC_URL}/img/logo_black.webp`}
                style={{ width: '5rem', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/patient');
                }}
              />
            </Row>
            {clientStore.metaData?.userRole?.clinic?.businessType !== 'PERSONAL' && (
              <TypographyText fontSize={'0.8rem'} style={{ whiteSpace: 'pre' }}>
                {permissionConverter(clientStore.metaData?.userRole?.roleName!)}
              </TypographyText>
            )}
          </Row>
          <Row flex style={{ marginTop: '3.4rem' }}>
            <TypographyText>{clientStore.metaData?.userRole?.clinic?.clinicName}</TypographyText>
          </Row>
          <SideInterface setIsLoading={setIsLoading} />
          <Row style={{ marginTop: '1rem', borderBottom: '1px solid #D2D2D2' }} />
          <Row style={{ marginTop: '3rem', display: 'flex', flexDirection: 'column', rowGap: '1.6rem' }}>
            <SideLink to={'/patient'} isActived={type === 1}>
              {`환자관리`}
            </SideLink>
            <SideLink to={'/treatment'} isActived={type === 2}>
              {`진료실`}
            </SideLink>
            <SideLink to={'/treatmentHistory'} isActived={type === 3}>
              {`진료내역`}
            </SideLink>
          </Row>
        </Row>

        <Row
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Row flex flexDirection="column" style={{ rowGap: '0.75rem' }}>
            <Row
              className="faq-button"
              flex
              alignItems="center"
              style={{ cursor: 'pointer', width: 'fit-content', borderBottom: '1px solid #F7F9FA' }}
              onClick={() =>
                window.open(
                  'https://futuristic-yarrow-f15.notion.site/d1064d4192444c018c43f3535f3b0db0',
                  '_blank',
                )
              }
            >
              <TypographyText fontSize="0.8rem" color="#505A6D">
                {'예진 사용법'}
              </TypographyText>
            </Row>
            <Row
              className="faq-button"
              flex
              alignItems="center"
              style={{ cursor: 'pointer', width: 'fit-content', borderBottom: '1px solid #F7F9FA' }}
              onClick={() =>
                window.open(
                  'https://futuristic-yarrow-f15.notion.site/51f791db9792432c9d9e223ad3eedbdb',
                  '_blank',
                )
              }
            >
              <TypographyText fontSize="0.8rem" color="#505A6D">
                {'자주 묻는 질문'}
              </TypographyText>
            </Row>
            <Row flex flexDirection="column" style={{ rowGap: '0.75rem', position: 'relative' }}>
              {menuList.map((item, index) => (
                <button
                  className="sidebar-button"
                  key={index}
                  style={{
                    padding: '0.45rem 1.9rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    columnGap: '0.7rem',
                    borderRadius: '3px',
                    background: '#EEEFF3',
                    height: '2rem',
                  }}
                  onClick={item.onClickFunc}
                >
                  <img
                    alt={'icon'}
                    src={`${process.env.PUBLIC_URL}/img/sidebar-${index + 1}.webp`}
                    style={{ width: '1.2rem' }}
                  />
                  <TypographyText fontSize="0.8rem" color="#505A6D" fontWeight={700}>
                    {item.label}
                  </TypographyText>
                </button>
              ))}
              {isOpenYoutubeTutorialNotice && ( // 동영상 가이드에만 적용
                <div
                  className="youtube-tutorial-component"
                  style={{
                    position: 'absolute',
                    right: '-2.2rem',
                    top: '1rem',
                    translate: '100% -50%',
                  }}
                >
                  <Row style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <TypographyText
                      style={{
                        fontSize: '1rem',
                        lineHeight: '1.2em',
                        color: '#ffffff',
                        whiteSpace: 'pre',
                        zIndex: 1,
                      }}
                    >
                      {`예진이 처음이신가요?
`}
                      <strong>[동영상 가이드]</strong>
                      {` 버튼을 클릭하여
5분만에 예진 사용 방법을 익혀보세요!`}
                    </TypographyText>
                    <Row
                      style={{ display: 'flex', width: '0.75rem', cursor: 'pointer' }}
                      onClick={() => {
                        setIsOpenYoutubeTutorialNotice(false);
                        updateUserConfig({
                          userId: clientStore.metaData?.user.id!,
                          userConfig: clientStore.user?.config,
                        });
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/img/x_white.webp`}
                        style={{ width: '0.75rem', whiteSpace: 'pre' }}
                        alt="x"
                      />
                    </Row>
                  </Row>
                </div>
              )}
            </Row>

            <Row flex justifyContent="center" alignItems="center">
              <TypographyText fontSize="0.75rem" lineHeight="1.333em" color="#656565" whiteSpace="pre">
                {`문의 전화: 070-7782-7672
(10:00 ~ 18:00 주말, 공휴일 제외)`}
              </TypographyText>
            </Row>
          </Row>
        </Row>
        <Row
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            color: isEnableTillStaging ? '#afafaf' : '#F1F7FC',
            fontSize: '0.8rem',
          }}
        >
          v2.5.1
        </Row>
      </Row>
      {isOpenMediaModal && (
        <ModalWithJustX
          isOpen={isOpenMediaModal}
          width={`48rem`}
          height={`27rem`}
          content={
            <Row
              style={{
                position: 'relative',
                width: '100%',
                paddingBottom: '56.25%',
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0,
                }}
                src={`https://www.youtube.com/embed/pvio36YBoeY?autoplay=1&hd=1`}
                title="Introduce Ye-jin"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen={true}
              />
            </Row>
          }
          cancelFunction={() => setIsOpenMediaModal(false)}
        />
      )}
    </>
  );
};

export default observer(SideBar);
