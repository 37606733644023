import TreatmentWaitngList from 'components/UI/organisms/treatment/list/TreatmentWaitngList';
import AdminLayout from 'components/templates/AdminLayout';

const TreatmentPage = () => {
  return (
    <AdminLayout
      title={'진료실'}
      content={<TreatmentWaitngList />}
      sideType={2}
      youtubeLinkParam={{
        title: 'Ye-jin Treatment',
        src: 'https://www.youtube.com/embed/gfVThuggBGs?autoplay=1&start=0',
      }}
    />
  );
};

export default TreatmentPage;
