/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState, useRef } from 'react';
import message from 'antd-message';
import { observer } from 'mobx-react';
import { css } from '@emotion/css';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Loader } from '@aws-amplify/ui-react';

import { CreateSurveyInput, UpdatePatientInput } from 'API';
import { TypographyText, Row, Col } from 'components/UI/atoms';
import { useStores } from 'stores/Context';
import { v4 } from 'uuid';
import _ from 'lodash';
import { TreatmentStatus } from 'constant';
import { url } from 'config';
import { Loading } from 'components/UI/molecules';
import sr from 'repository/SurveyRepository';

import SurveyCard from './SurveyHistoryCard';
import SurveyDetail from './SurveyHistoryDetail';

const SurveyHistoryList = () => {
  const isMounted = useRef(false);
  const { id } = useParams();
  const [surveyDataList, setSurveyDataList] = useState<any[]>([]);
  const [selectedSurveyData, setSelectedSurveyData] = useState<any>({});
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(true);
  const [isListLoading, setIsListLoading] = useState<boolean>(false);
  const [isAdditionalListLoading, setIsAdditionalListLoading] = useState<boolean>(false);
  const [target, setTarget] = useState<Element | null>(null);
  const [nextToken, setNextToken] = useState<string | null | undefined>(undefined);

  const { surveyStore, historyStore, patientStore } = useStores();

  const handleSearch = async ({
    initLoadingFlag = false,
    resetParamFlag = false,
    patientId,
    nextToken,
  }: {
    initLoadingFlag?: boolean;
    resetParamFlag?: boolean;
    patientId?: string;
    nextToken?: string;
  }) => {
    try {
      if (initLoadingFlag) {
        await setIsGlobalLoading(true);
      } else {
        await setIsAdditionalListLoading(true);
      }

      if (resetParamFlag) {
        setNextToken(undefined);
        setIsListLoading(true);
      }
      let res = {
        items: [],
        nextToken: undefined,
      };
      res = await sr.surveyByDate({
        patientID: patientId,
        nextToken: resetParamFlag ? undefined : nextToken,
      });

      setSurveyDataList(
        resetParamFlag || nextToken === undefined ? res?.items : [...surveyDataList, ...res?.items],
      );
      if (resetParamFlag) {
        setSelectedSurveyData(res?.items?.[0]);
        setIsListLoading(false);
      }
      setNextToken(res?.nextToken);
    } catch (e) {
      console.log(e);
      message.error('설문내역 조회에 실패했습니다.');
    } finally {
      setIsGlobalLoading(false);
      setIsAdditionalListLoading(false);
      setIsListLoading(false);
    }
  };

  useEffect(() => {
    const ob = new IntersectionObserver(
      (e) => {
        if (e?.[0]?.isIntersecting) {
          handleSearch({ nextToken, patientId: id });
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    target && ob.observe(target);

    return () => {
      ob.disconnect();
    };
  }, [target]);

  useEffect(() => {
    if (!_.isEmpty(selectedSurveyData)) {
      // treatmentHistoryDetailWarpper 스크롤 위치 초기화
      const surveyHistoryDetailWarpper = document.getElementById('surveyHistoryDetailWarpper');
      surveyHistoryDetailWarpper?.scrollTo(0, 0);
    }
  }, [selectedSurveyData]);

  const copySymptom = useCallback(async (survey) => {
    const token = v4().toString();
    const nowDtISOString = new Date().toISOString();
    await surveyStore
      .createSurvey({
        patientID: survey.patientID,
        clinicID: survey.clinicID,
        otp: token,
        surveyedAt: moment().toISOString(),
        symptoms: survey.symptoms,
        surveyResult: survey.surveyResult,
        mainSymptom: survey.mainSymptom,
        mainSymptomCategory: survey?.mainSymptomCategory,
        additionalSymptoms: survey.additionalSymptoms,
        feedbackDone: false,
        surveyDone: true,
        feedbackLink: `${url.feedbackLink}${token}`,
        surveyType: survey.surveyType ?? 'GENERAL',
        insuranceType: survey.insuranceType ?? 'GENERAL',
      } as CreateSurveyInput)
      .then((e: any) => {
        historyStore.createHistory({
          patientID: survey.patientID,
          clinicID: survey.clinicID,
          type: 'copySymptom',
          messageCode: `AM004`,
        });
        patientStore
          .updatePatient({
            id: survey.patientID,
            userID: null,
            surveyID: e.createSurvey.id,
            treatmentStatus: TreatmentStatus.SurveySubmit,
            treatmentStatusDateForSorting: `${TreatmentStatus.SurveySubmit}_${nowDtISOString}`,
            recentActivity: nowDtISOString,
            recentActivityCode: `AM004`,
            surveyedAt: moment().toISOString(),
          } as UpdatePatientInput)
          .then(() => {
            patientStore.getPatient(patientStore.patient?.id!).then((e: any) => {
              message.success(`설문이 입력되어 진료대기에 등록되었습니다.`, 2500);
            });
          });
      })
      .then(async () => {
        await patientStore.getPatient(id);
        handleSearch({ resetParamFlag: true, patientId: id });
      });
  }, []);

  useEffect(() => {
    document.getElementsByTagName('body')?.[0].classList.add('content-hidden');
    handleSearch({ resetParamFlag: true, patientId: id, initLoadingFlag: !isMounted.current });
    if (isMounted.current) {
      isMounted.current = true;
    }
    patientStore.getPatient(id);

    return () => {
      surveyStore.resetSurveys();
      document.getElementsByTagName('body')?.[0].classList.remove('content-hidden');
    };
  }, []);

  return (
    <Row
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: 'calc(100% + 4.8rem)',
        marginLeft: '-2.4rem',
        padding: '1.4rem 2.4rem',
        flex: 1,
      }}
    >
      {isGlobalLoading ? (
        <Loading />
      ) : (
        <Row
          style={{
            height: '100%',
            flex: '1 1 0',
            display: 'flex',
            columnGap: '0.9rem',
          }}
        >
          {_.isEmpty(surveyDataList) ? (
            <Row
              style={{
                position: 'absolute',
                width: '100%',
                top: '15%',
                left: '45%',
              }}
            >
              <TypographyText yjTypeTypographyText="symptom-table-body" color="#AFAFAF">
                {`설문 내역이 없습니다.`}
              </TypographyText>
            </Row>
          ) : (
            <>
              <Col
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '525 1 0',
                  // height: '100%',
                }}
              >
                <Row
                  style={{
                    position: 'relative',
                    flex: '1 1 0',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '0.75rem',
                    height: '100%',
                    maxHeight: '100%',
                    overflowY: 'scroll',
                    paddingRight: '0.35rem',
                  }}
                  className={css`
                    &::-webkit-scrollbar {
                      width: 0.35rem;
                    }
                    &::-webkit-scrollbar-thumb {
                      background-color: #ebebeb;
                      border-radius: 0;
                      cursor: pointer;
                    }
                  `}
                >
                  {isListLoading ? (
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '90%',
                      }}
                    >
                      <Loader size="large" filledColor={'gold'} width={'4rem'} height={'4rem'} />
                    </Row>
                  ) : (
                    surveyDataList.map((s, index) => {
                      return (
                        <div key={s.id} ref={surveyDataList.length - 1 === index ? setTarget : null}>
                          <SurveyCard
                            surveyData={s}
                            selectedFlag={s.id === selectedSurveyData?.id}
                            setSelectedSurveyData={setSelectedSurveyData}
                          />
                        </div>
                      );
                    })
                  )}
                  {!isListLoading && isAdditionalListLoading && (
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Loader size="small" filledColor={'gold'} width={'4rem'} height={'4rem'} />
                    </Row>
                  )}
                </Row>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1010 1 0',
                  // height: '100%',
                }}
              >
                <Row
                  id="surveyHistoryDetailWarpper"
                  style={{
                    flex: '1 1 0',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    maxHeight: '100%',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                  }}
                  className={css`
                    &::-webkit-scrollbar {
                      width: 0.35rem;
                    }
                    &::-webkit-scrollbar-thumb {
                      background-color: #ebebeb;
                      border-radius: 0;
                      cursor: pointer;
                    }
                  `}
                >
                  <SurveyDetail
                    surveyData={selectedSurveyData}
                    patientData={patientStore.patient}
                    copySymptom={copySymptom}
                  />
                </Row>
              </Col>
            </>
          )}
        </Row>
      )}
    </Row>
  );
};

export default observer(SurveyHistoryList);
