import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
  CreateClinicsForPromotionInput,
  CreateClinicsForPromotionMutation,
  GetClinicQuery,
  GetClinicsForPromotionQuery,
  ModelSortDirection,
  UpdateClinicInput,
  UpdateClinicMutation,
  ListUsageStatisticsQuery,
  UpdateSelfRegistrationQRInput,
  UpdateSelfRegistrationQRMutation,
} from '../API';
import * as mutations from '../graphql/mutations';
import * as customs from '../graphql/custom';
import * as queries from '../graphql/queries';
import { repeatCallingListApiToLimit } from 'utils/util';
class ClinicRepository {
  getClinic = async (clinicID: string) => {
    const clinic = (await API.graphql(
      graphqlOperation(
        queries.getClinic,
        { id: clinicID },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: GetClinicQuery };

    // console.log(clinic);

    return clinic.data;
  };

  getUsageStatistics = async ({
    clinicID,
    dateRangeArr,
    limit = 31,
  }: {
    clinicID: string;
    dateRangeArr?: string[];
    limit?: number;
  }) => {
    try {
      const params = {
        clinicID,
        recordDate: { between: [dateRangeArr?.[0], dateRangeArr?.[1]] },
        limit,
      };
      const response = (await API.graphql(
        graphqlOperation(
          queries.listUsageStatistics.replace(/createdAt/g, '').replace(/updatedAt/g, ''),
          params,
          (await Auth.currentSession()).getIdToken().getJwtToken(),
        ),
      )) as { data: ListUsageStatisticsQuery };
      return response.data.listUsageStatistics;
    } catch (e) {
      console.log(e);
    }
  };

  listClinics = async (params, nextToken?: string) => {
    const param = {
      typename: 'Clinic',
      sortDirection: ModelSortDirection.DESC,
      filter: {},
    };
    if (!params?.isDeleted) {
      param['filter'] = { ...param['filter'], isDeleted: { ne: true } };
    }
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      limit: Infinity,
      queryLimitBeforeFilter: 5000,
      nextToken,
      targetGraphqlListQuery: queries.clinicByDate,
      targetGraphqlListFunctionDataFieldName: 'clinicByDate',
      targetGraphqlListQueryParam: param,
    });
    return res;
  };

  getClinicsForPromotion = async (id: string) => {
    const clinic = (await API.graphql(
      graphqlOperation(
        queries.getClinicsForPromotion,
        { id },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: GetClinicsForPromotionQuery };
    return clinic.data;
  };

  listClinicsForPromotionBypromotionType = async ({
    promotionType,
    nextToken,
  }: {
    promotionType: string;
    nextToken?: string;
  }) => {
    const param = {
      promotionType,
      filter: {},
    };
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      limit: Infinity,
      queryLimitBeforeFilter: 5000,
      nextToken,
      targetGraphqlListQuery: queries.listClinicsForPromotionBypromotionType,
      targetGraphqlListFunctionDataFieldName: 'listClinicsForPromotionBypromotionType',
      targetGraphqlListQueryParam: param,
    });
    return res;
  };

  createClinicForPromotion = async (input: CreateClinicsForPromotionInput) => {
    const createdClinic = (await API.graphql(
      graphqlOperation(
        customs.createClinicsForPromotion,
        { input },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: CreateClinicsForPromotionMutation };
    return createdClinic;
  };

  removeClinicForPromotion = async (id: string) => {
    await API.graphql(
      graphqlOperation(
        mutations.deleteClinicsForPromotion,
        { input: { id } },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    );
  };

  listClinicsByCreatedAt = async ({ params, nextToken }: { params?: any; nextToken?: string }) => {
    const param = {
      typename: 'Clinic',
      sortDirection: ModelSortDirection.DESC,
      filter: {},
    };
    if (!params?.isDeleted) {
      param['filter'] = { ...param['filter'], isDeleted: { ne: true } };
    }
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      nextToken,
      targetGraphqlListQuery: queries.clinicByDate,
      targetGraphqlListFunctionDataFieldName: 'clinicByDate',
      targetGraphqlListQueryParam: param,
      limit: Infinity,
      queryLimitBeforeFilter: 5000,
    });
    return res;
  };

  updateClinic = async (clinic: UpdateClinicInput) => {
    const updatedClinic = (await API.graphql(
      graphqlOperation(
        mutations.updateClinic,
        { input: clinic },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: UpdateClinicMutation };
    // console.log(updatedClinic);
    return updatedClinic.data;
  };

  updateQR = async (input: UpdateSelfRegistrationQRInput) => {
    const updatedQR = (await API.graphql(
      graphqlOperation(
        customs.updateSelfRegistrationQR,
        { input },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: UpdateSelfRegistrationQRMutation };
    // console.log(updatedQR);
    return updatedQR.data;
  };

  listQRs = async (params, nextToken?: string) => {
    const param = {
      ...params,
    };
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      nextToken,
      targetGraphqlListQuery: queries.listSelfRegistrationQRS,
      targetGraphqlListFunctionDataFieldName: 'listSelfRegistrationQRS',
      targetGraphqlListQueryParam: param,
      limit: 1000,
    });
    return res;
  };

  listQRsByClinicId = async (params, nextToken?: string) => {
    if (!params.clinicID) throw new Error('clinicID is required');
    const param = {
      ...params,
    };
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      nextToken,
      targetGraphqlListQuery: queries.QRByClinic,
      targetGraphqlListFunctionDataFieldName: 'QRByClinic',
      targetGraphqlListQueryParam: param,
      limit: 1000,
    });
    return res;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ClinicRepository();
