import { useMemo, useState, useEffect } from 'react';

import PatientInfo from './tabs/PatientInfo';
import SurveyHistoryList from './tabs/SurveyHistoryList';
import PrescriptionHistory from './tabs/PrescriptionHistory';
import { Row, TypographyText } from 'components/UI/atoms';

const PatientDetailTab = ({ isGlobalLoading }) => {
  const tabList = useMemo(
    () => [
      {
        tabId: 'patinetInfo',
        tabName: '환자정보',
      },
      {
        tabId: 'prescriptionHistory',
        tabName: '진료내역',
      },
      {
        tabId: 'surveyHistory',
        tabName: '설문내역',
      },
    ],
    [],
  );
  const tab = sessionStorage.getItem('patientDetailTab') ?? 'patinetInfo';
  const [selectedTab, setSelectedTab] = useState(tab);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('patientDetailTab');
    };
  }, []);

  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        flex: '1 1 0',
      }}
    >
      <Row
        style={{
          display: 'flex',
          columnGap: '2rem',
          borderBottom: '1px solid #e5e5e5',
        }}
      >
        {tabList.map((tab, index) => (
          <div
            key={tab.tabId}
            style={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              height: '2.2rem',
              cursor: 'pointer',
              borderBottom: `2px solid ${selectedTab === tab.tabId ? '#1c252c' : 'transparent'}`,
            }}
            onClick={() => {
              setSelectedTab(tab.tabId);
              sessionStorage.setItem('patientDetailTab', tab.tabId);
            }}
          >
            <TypographyText
              color={selectedTab === tab.tabId ? '#1c252c' : '#AFAFAF'}
              fontSize="1.2rem"
              lineHeight="1.2em"
              fontWeight={700}
            >
              {tab.tabName}
            </TypographyText>
          </div>
        ))}
      </Row>
      {selectedTab === 'patinetInfo' && <PatientInfo isGlobalLoading={isGlobalLoading} />}
      {selectedTab === 'surveyHistory' && <SurveyHistoryList />}
      {selectedTab === 'prescriptionHistory' && <PrescriptionHistory />}
    </Row>
  );
};

export default PatientDetailTab;
