/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Loader } from '@aws-amplify/ui-react';
import { UpdateTreatmentInput } from 'API';
import { API, Auth } from 'aws-amplify';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import message from 'antd-message';

import { useStores } from 'stores/Context';
import { codeMap } from 'utils/code';
import { Button } from 'components/UI/atoms/inputs/Button';
import { genderConverter } from 'utils/DataConvertUtil';
import { Row, Col, Input, TypographyText, ModalType2, Modal } from 'components/UI/atoms';
import { UpdatePatientInput } from '../../../../../API';
import { TreatmentStatus as TreatmentStatusEnum } from 'constant';
import { Loading } from 'components/UI/molecules';
import SymptomTable from 'components/UI/molecules/table/commonSymptomTable';
import pr from 'repository/PatientRepository';
import tr from 'repository/TreatmentRepository';
import { selectSurvey } from 'utils/util';

import _ from 'lodash';

const TreatmentWaitngList = () => {
  const { patientStore, clientStore, historyStore, clinicStore } = useStores();
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const [nameOrPhoneForSearch, setNameOrPhoneForSearch] = useState<string>();
  const [nameOrPhone, setNameOrPhone] = useState<string>();
  const [targetSurvey, setTargetSurvey] = useState<any>({});
  const [targetPatient, setTargetPatient] = useState<any>({});
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false);
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [isAdditionalListLoading, setIsAdditionalListLoading] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string | null | undefined>(undefined);
  const [treatmentList, setTreatmentList] = useState<any[]>([]);
  const [target, setTarget] = useState<Element | null>(null);

  const startTreatment = useCallback(async (clinicID: string, patientID: string) => {
    const response = await API.post('yejinadminrestapi', '/treatment', {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      response: true,
      body: {
        clinicID,
        patientID,
      },
    });
    return response.data;
  }, []);

  const handleSearch = async ({
    initLoadingFlag = false,
    resetParamFlag = false,
    value,
    nextToken,
  }: {
    initLoadingFlag?: boolean;
    resetParamFlag?: boolean;
    value?: string;
    nextToken?: string;
  }) => {
    try {
      if (initLoadingFlag) {
        await setIsListLoading(true);
      } else {
        await setIsAdditionalListLoading(true);
      }

      if (resetParamFlag) {
        setNextToken(undefined);
        setIsListLoading(true);
      }

      let res = {
        items: [],
        nextToken: undefined,
      };

      res = await pr.patientsByTreatmentStatus({
        clinicID: clientStore.metaData?.userRole.clinic?.id,
        nextToken: resetParamFlag ? undefined : nextToken,
        searchParam: {
          patientNameOrPhone: value,
        },
      });

      await setTreatmentList(
        resetParamFlag || nextToken === undefined ? res?.items : [...treatmentList, ...res?.items],
      );

      if (resetParamFlag) {
        setIsListLoading(false);
      }

      setNextToken(res?.nextToken);
    } catch (e) {
      message.error(`진료데이터를 가져오는 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 8000);
    } finally {
      await setIsListLoading(false);
      await setIsAdditionalListLoading(false);
    }
  };

  useEffect(() => {
    const ob = new IntersectionObserver(
      (e) => {
        if (e?.[0]?.isIntersecting) {
          handleSearch({ value: nameOrPhoneForSearch, nextToken });
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    target && ob.observe(target);

    return () => {
      ob.disconnect();
    };
  }, [target]);

  useEffect(() => {
    clinicStore.getClinic(clientStore.metaData?.userRole.clinicID!, false);
    handleSearch({
      resetParamFlag: true,
      value: nameOrPhoneForSearch,
      initLoadingFlag: !isMounted.current,
    });
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, []);

  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1.4rem',
        paddingBottom: '1rem',
        height: '100%',
        flex: '1 1 0',
      }}
    >
      {isGlobalLoading && <Loading mode="global" />}
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Row style={{ display: 'flex', alignItems: 'center', columnGap: '0.4rem' }}>
          <Col style={{ position: 'relative' }}>
            <Input
              style={{
                height: '2.1rem',
                width: '14rem',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                fontSize: '0.8rem',
                lineHeight: '0.8rem',
                outline: 'none',
                textIndent: '1.2rem',
              }}
              value={nameOrPhone}
              placeholder="환자명 또는 전화번호 뒷자리"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  setNameOrPhoneForSearch(nameOrPhone);
                  handleSearch({ value: nameOrPhone, resetParamFlag: true });
                }
              }}
              onChange={(e) => {
                setNameOrPhone(e.target.value);
              }}
              autoComplete="off"
            />
            {nameOrPhone?.length > 0 && (
              <Col
                style={{
                  position: 'absolute',
                  display: 'flex',
                  right: '0.5rem',
                  top: '0.6rem',
                  cursor: 'pointer',
                  height: '1rem',
                }}
                onClick={() => {
                  setNameOrPhone(undefined);
                  setNameOrPhoneForSearch(undefined);
                  handleSearch({ resetParamFlag: true });
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/serach_initialization_icon.webp`}
                  style={{ width: '1rem' }}
                  alt="serach_initialization_icon"
                />
              </Col>
            )}
            <Col
              style={{
                position: 'absolute',
                display: 'flex',
                left: '0.5rem',
                top: 'calc(50% + 0.05rem)',
                transform: 'translateY(-50%)',
                height: '1rem',
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/search_icon.webp`}
                style={{ width: '1rem' }}
                alt="search_icon"
              />
            </Col>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              height: '2.1rem',
              border: '1px solid #346AFF',
              borderRadius: '3px',
              background: '#ffffff',
              cursor: 'pointer',
              flexShrink: 0,
            }}
            onClick={() => {
              setNameOrPhoneForSearch(nameOrPhone);
              handleSearch({ value: nameOrPhone, resetParamFlag: true });
            }}
          >
            <TypographyText
              color="#346AFF"
              fontSize="1rem"
              letterSpacing="0.05em"
              fontWeight={700}
              lineHeight="1.2rem"
            >
              검색
            </TypographyText>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              height: '2.1rem',
              border: '1px solid #afafaf',
              borderRadius: '3px',
              background: '#ffffff',
              cursor: 'pointer',
              flexShrink: 0,
            }}
            onClick={() => {
              setNameOrPhone(undefined);
              setNameOrPhoneForSearch(undefined);
              handleSearch({ resetParamFlag: true });
            }}
          >
            <TypographyText
              color="#1c252c"
              fontSize="1rem"
              letterSpacing="0.05em"
              fontWeight={700}
              lineHeight="1.2rem"
            >
              초기화
            </TypographyText>
          </Col>
        </Row>
      </Row>
      <table
        className={`list-page-common-table treatment-list-page-table  ${
          isListLoading && 'height-100 flex-1'
        }`}
      >
        <thead>
          <tr>
            <th>
              <span>이름</span>
            </th>
            <th>
              <span>생년월일</span>
            </th>
            <th>
              <span>성별</span>
            </th>
            <th>
              <span>초진/재진</span>
            </th>
            <th>
              <span>설문 입력 일시</span>
            </th>
            <th>
              <span>주소증</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isListLoading ? (
            <tr>
              <td colSpan={7}>
                <Row
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Row style={{ display: 'flex', marginTop: '-4rem', marginLeft: '-1rem' }}>
                    <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
                  </Row>
                </Row>
              </td>
            </tr>
          ) : treatmentList?.length > 0 ? (
            treatmentList.map((patient, i) => {
              let mainSymptomCategoryText = '';
              const survey = patient?.survey ?? {};

              if (survey.mainSymptomCategory && codeMap.has(survey.mainSymptomCategory)) {
                mainSymptomCategoryText = codeMap.get(survey.mainSymptomCategory)?.label ?? '';
              }

              return (
                <tr key={patient.id} ref={treatmentList.length - 1 === i ? setTarget : null}>
                  <td>
                    <span>{patient.patientName}</span>
                  </td>
                  <td>
                    <span>{moment(patient.birth).format('YY/MM/DD')}</span>
                  </td>
                  <td>
                    <span>{genderConverter(patient.gender!)}</span>
                  </td>
                  <td>
                    <span>{patient.isVisited ? '재진' : '초진'}</span>
                  </td>
                  <td>
                    <span
                      style={{
                        color: '#afafaf',
                      }}
                    >
                      {moment(patient.survey?.surveyedAt).format('YY/MM/DD HH:mm')}
                    </span>
                  </td>
                  <td>
                    <div>
                      <span>{`${mainSymptomCategoryText ? `[${mainSymptomCategoryText}] ` : ''}${
                        patient.survey?.mainSymptom
                      }`}</span>
                    </div>
                  </td>
                  <td>
                    <Row
                      style={{
                        display: 'flex',
                        flexShrink: 0,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        columnGap: '2rem',
                      }}
                    >
                      <Col style={{ display: 'flex', columnGap: '0.4rem' }}>
                        <Button
                          width={'5rem'}
                          backgroundColor={'#ffffff'}
                          fontSize={'0.75rem'}
                          padding={0}
                          height={'2rem'}
                          onClick={() => {
                            setTargetSurvey({ ...patient.survey, patient });
                          }}
                        >
                          설문요약
                        </Button>
                        {patient.treatmentStatus === 30 ? (
                          <Button
                            style={{
                              color: '#ffffff',
                              background: '#346AFF',
                              border: '1px solid #346AFF',
                              padding: 0,
                              height: '2rem',
                              width: '5.5rem',
                            }}
                            onClick={async () => {
                              // todo [YEJ-60] 해결되면, roleName이 ENUM으로 변경되면 하드코딩된 roleName 수정
                              // todo 한의사 초대 구현되면 조건문 복구
                              // if (!['MASTER', 'DOCTOR'].includes(clinicStore.currentUserRole)) {
                              //   // 의사 미만의 권한을 가진 사람이 진료시작을 하려한다면 메세지띄우고 동작안함
                              //   message.error(`한의사만 진료가 가능합니다.`, 2500);
                              // } else {
                              try {
                                await setIsGlobalLoading(true);
                                const { body } = await startTreatment(
                                  clientStore.metaData?.userRole.clinicID,
                                  patient.id,
                                );
                                const resBody = JSON.parse(body);
                                await setIsGlobalLoading(false);
                                navigate(`/treatment/${resBody.treatmentID}`);
                              } catch (e) {
                                await setIsGlobalLoading(false);
                                message.error(`진료시작 과정에서 문제가 발생했습니다.`, 2500);
                              }
                              // }
                            }}
                          >
                            <TypographyText
                              style={{
                                fontSize: '0.75rem',
                                whiteSpace: 'pre',
                                color: '#ffffff',
                              }}
                            >
                              진료시작
                            </TypographyText>
                          </Button>
                        ) : (
                          <Button
                            height={'2rem'}
                            style={{
                              padding: 0,
                              width: '5.5rem',
                              height: '2rem',
                              border: '1px solid #346AFF',
                            }}
                            onClick={async () => {
                              // if (!['MASTER', 'DOCTOR'].includes(clinicStore.currentUserRole)) {
                              //   // 의사 미만의 권한을 가진 사람이 진료시작을 하려한다면 메세지띄우고 동작안함
                              //   message.error(`한의사만 진료가 가능합니다.`, 2500);
                              // } else {
                              // 한의사 초대 구현되면 조건문 복구
                              navigate(`/treatment/${patient.treatmentID}`);
                              // }
                            }}
                          >
                            <TypographyText
                              style={{
                                fontSize: '0.75rem',
                                whiteSpace: 'pre',
                                color: '#346AFF',
                              }}
                            >
                              이어서 진료
                            </TypographyText>
                          </Button>
                        )}
                      </Col>
                      <Col
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', flexShrink: 0 }}
                      >
                        <img
                          onClick={() => {
                            setIsOpenDeleteModal(true);
                            setTargetPatient(patient);
                          }}
                          style={{ width: '1.4rem' }}
                          src={`${process.env.PUBLIC_URL}/img/treatment-cancel.webp`}
                          alt="yejin-delete-treatment-icon"
                        />
                      </Col>
                    </Row>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <span
                  style={{
                    color: '#afafaf',
                  }}
                >
                  등록된 환자가 없습니다.
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!isListLoading && isAdditionalListLoading && (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Loader size="large" filledColor="gold" width={'4rem'} height={'4rem'} />
        </Row>
      )}
      <ModalType2
        isOpen={!_.isEmpty(targetSurvey)}
        width={50}
        title={`${targetSurvey?.patient?.patientName} 설문요약 | ${moment(targetSurvey?.surveyedAt).format(
          'YY/MM/DD',
        )}`}
        content={
          <>
            <Row style={{ flexShrink: 0, marginBottom: '0.75rem' }}>
              <TypographyText fontWeight={400} fontSize={'0.8rem'} color={'#AFAFAF'}>
                {moment(targetSurvey?.surveyedAt).format('YYYY/MM/DD HH:mm')} 설문입력
              </TypographyText>
            </Row>
            <SymptomTable
              survey={targetSurvey ?? {}}
              symptoms={selectSurvey(targetSurvey?.symptoms!)}
              mode="patientSymptom"
            />
            <Row style={{ marginBottom: '1rem' }} />
          </>
        }
        cancelFunction={() => setTargetSurvey({})}
      />
      {isOpenDeleteModal && !_.isEmpty(targetPatient) && (
        <Modal
          contentMsg={`${targetPatient.patientName} 환자의 진료를 취소하시겠습니까?`}
          cancelMsg="닫기"
          okMsg="네, 취소하겠습니다."
          additionalContent={
            <Row style={{ marginTop: '0.4rem', lineHeight: '0.95rem' }}>
              <TypographyText
                fontSize="0.8rem"
                letterSpacing="-0.02em"
                color="#AFAFAF"
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {`다시 증상을 입력하면 자동으로 진료실에 등록됩니다.`}
              </TypographyText>
            </Row>
          }
          okFunction={async () => {
            try {
              setIsOpenDeleteModal(false);
              setIsGlobalLoading(true);

              if (targetPatient.treatmentID) {
                await tr.updateTreatment({
                  id: targetPatient.treatmentID,
                  isDeleted: true,
                } as UpdateTreatmentInput);
              }

              await patientStore.updatePatient({
                id: targetPatient.id,
                userID: null,
                surveyID: null,
                treatmentID: null,
                treatmentStatus: TreatmentStatusEnum.None,
                recentActivity: new Date().toISOString(),
                recentActivityCode: `AM006`,
              } as UpdatePatientInput);

              await historyStore.createHistory({
                patientID: targetPatient.id,
                clinicID: clientStore.metaData?.userRole.clinic?.id!, // mobx에서 현재 세션에서의 clinicID를 가져옴
                messageCode: 'AM006',
              });

              // 실제로 다시 쿼리해오지는 않고 뷰에서만 지움
              setTreatmentList(treatmentList.filter((e) => e.id !== targetPatient.id));
              message.success(`${targetPatient.patientName} 환자의 진료가 취소되었습니다.`, 2500);
            } catch (e) {
              message.error(`진료취소 과정에서 문제가 발생했습니다. error: ${JSON.stringify(e)}`, 2500);
            } finally {
              setTargetPatient({});
              setIsGlobalLoading(false);
            }
          }}
          cancelFunction={() => {
            setTargetPatient({});
            setIsOpenDeleteModal(false);
          }}
        />
      )}
    </Row>
  );
};

export default observer(TreatmentWaitngList);
