/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Report = {
  __typename: "Report",
  treatmentID: string,
  sendCount?: number | null,
  byeonjeung?: string | null,
  startedAt?: string | null,
  birth?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type CreateHerbInput = {
  code: string,
  herbName: string,
  nameForSearching?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelHerbConditionInput = {
  herbName?: ModelStringInput | null,
  nameForSearching?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHerbConditionInput | null > | null,
  or?: Array< ModelHerbConditionInput | null > | null,
  not?: ModelHerbConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Herb = {
  __typename: "Herb",
  code: string,
  herbName: string,
  nameForSearching?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateHerbInput = {
  code: string,
  herbName?: string | null,
  nameForSearching?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteHerbInput = {
  code: string,
};

export type CreateCustomHerbalFormulaInput = {
  id?: string | null,
  ownerUserID: string,
  originalHerbalFormulaID?: string | null,
  insrCCode?: string | null,
  insrDsCode?: Array< string | null > | null,
  InsrPrscrType?: number | null,
  kn?: string | null,
  herbCodes?: Array< string | null > | null,
  processingCodes?: Array< string | null > | null,
  recipe?: Array< PRInput | null > | null,
  scoringType?: number | null,
  memo?: string | null,
  isDeleted?: boolean | null,
  createdAt?: string | null,
};

export type PRInput = {
  pc?: string | null,
  amt?: number | null,
};

export type ModelCustomHerbalFormulaConditionInput = {
  ownerUserID?: ModelIDInput | null,
  originalHerbalFormulaID?: ModelIDInput | null,
  insrCCode?: ModelStringInput | null,
  insrDsCode?: ModelStringInput | null,
  InsrPrscrType?: ModelIntInput | null,
  kn?: ModelStringInput | null,
  herbCodes?: ModelStringInput | null,
  processingCodes?: ModelStringInput | null,
  scoringType?: ModelIntInput | null,
  memo?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCustomHerbalFormulaConditionInput | null > | null,
  or?: Array< ModelCustomHerbalFormulaConditionInput | null > | null,
  not?: ModelCustomHerbalFormulaConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type CustomHerbalFormula = {
  __typename: "CustomHerbalFormula",
  id: string,
  ownerUserID: string,
  originalHerbalFormulaID?: string | null,
  originalHerbalFormulaData?: HerbalFormula | null,
  insrCCode?: string | null,
  insrDsCode?: Array< string | null > | null,
  InsrPrscrType?: number | null,
  kn?: string | null,
  herbCodes?: Array< string | null > | null,
  processingCodes?: Array< string | null > | null,
  recipe?:  Array<PR | null > | null,
  scoringType?: number | null,
  memo?: string | null,
  isDeleted?: boolean | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type HerbalFormula = {
  __typename: "HerbalFormula",
  id: string,
  category?: string | null,
  insrCCode?: string | null,
  insrDsCode?: Array< string | null > | null,
  InsrPrscrType?: number | null,
  kn?: string | null,
  cn?: string | null,
  herbCodes?: Array< string | null > | null,
  processingCodes?: Array< string | null > | null,
  recipe?:  Array<PR | null > | null,
  gun?: Array< string | null > | null,
  sin?: Array< string | null > | null,
  scoringType?: number | null,
  source?: string | null,
  postfix?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type PR = {
  __typename: "PR",
  pc?: string | null,
  amt?: number | null,
};

export type UpdateCustomHerbalFormulaInput = {
  id: string,
  ownerUserID?: string | null,
  originalHerbalFormulaID?: string | null,
  insrCCode?: string | null,
  insrDsCode?: Array< string | null > | null,
  InsrPrscrType?: number | null,
  kn?: string | null,
  herbCodes?: Array< string | null > | null,
  processingCodes?: Array< string | null > | null,
  recipe?: Array< PRInput | null > | null,
  scoringType?: number | null,
  memo?: string | null,
  isDeleted?: boolean | null,
  createdAt?: string | null,
};

export type DeleteCustomHerbalFormulaInput = {
  id: string,
};

export type CreateHerbalFormulaInput = {
  id?: string | null,
  category?: string | null,
  insrCCode?: string | null,
  insrDsCode?: Array< string | null > | null,
  InsrPrscrType?: number | null,
  kn?: string | null,
  cn?: string | null,
  herbCodes?: Array< string | null > | null,
  processingCodes?: Array< string | null > | null,
  recipe?: Array< PRInput | null > | null,
  gun?: Array< string | null > | null,
  sin?: Array< string | null > | null,
  scoringType?: number | null,
  source?: string | null,
  postfix?: string | null,
};

export type ModelHerbalFormulaConditionInput = {
  category?: ModelStringInput | null,
  insrCCode?: ModelStringInput | null,
  insrDsCode?: ModelStringInput | null,
  InsrPrscrType?: ModelIntInput | null,
  kn?: ModelStringInput | null,
  cn?: ModelStringInput | null,
  herbCodes?: ModelStringInput | null,
  processingCodes?: ModelStringInput | null,
  gun?: ModelStringInput | null,
  sin?: ModelStringInput | null,
  scoringType?: ModelIntInput | null,
  source?: ModelStringInput | null,
  postfix?: ModelStringInput | null,
  and?: Array< ModelHerbalFormulaConditionInput | null > | null,
  or?: Array< ModelHerbalFormulaConditionInput | null > | null,
  not?: ModelHerbalFormulaConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateHerbalFormulaInput = {
  id: string,
  category?: string | null,
  insrCCode?: string | null,
  insrDsCode?: Array< string | null > | null,
  InsrPrscrType?: number | null,
  kn?: string | null,
  cn?: string | null,
  herbCodes?: Array< string | null > | null,
  processingCodes?: Array< string | null > | null,
  recipe?: Array< PRInput | null > | null,
  gun?: Array< string | null > | null,
  sin?: Array< string | null > | null,
  scoringType?: number | null,
  source?: string | null,
  postfix?: string | null,
};

export type DeleteHerbalFormulaInput = {
  id: string,
};

export type CreateSurveyInput = {
  id?: string | null,
  otp: string,
  patientID: string,
  clinicID: string,
  link?: string | null,
  surveyResult?: string | null,
  mainSymptom?: string | null,
  symptoms?: string | null,
  visitCount?: number | null,
  firstLinkClickDt?: number | null,
  surveyedAt?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  sendCount?: number | null,
  isSend?: boolean | null,
  treatmentID?: string | null,
  feedbacks?: string | null,
  feedbackResult?: string | null,
  feedbackAt?: string | null,
  feedbackLink?: string | null,
  feedbackDone?: boolean | null,
  isFeedback?: boolean | null,
  additionalSymptoms?: string | null,
  feedbackSendTime?: string | null,
  feedbackSchedule_GSI_PK?: string | null,
  feedbackScheduleStatus?: number | null,
  surveyDone?: boolean | null,
  surveyGrade?: number | null,
  surveySatisfaction?: string | null,
  feedbackGrade?: number | null,
  feedbackSatisfaction?: string | null,
  surveyStatus?: SurveyStatus | null,
  expiredAt?: string | null,
  mainSymptomCategory?: string | null,
  sender?: string | null,
  surveyType?: SurveyType | null,
  insuranceType?: string | null,
  surveySurveyBizTalkHistoryId?: string | null,
  surveyFeedbackBizTalkHistoryId?: string | null,
};

export enum SurveyStatus {
  WAITING = "WAITING",
  SUBMIT = "SUBMIT",
}


export enum SurveyType {
  INSURANCE = "INSURANCE",
  GENERAL = "GENERAL",
}


export type ModelSurveyConditionInput = {
  otp?: ModelStringInput | null,
  patientID?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  link?: ModelStringInput | null,
  surveyResult?: ModelStringInput | null,
  mainSymptom?: ModelStringInput | null,
  symptoms?: ModelStringInput | null,
  visitCount?: ModelIntInput | null,
  firstLinkClickDt?: ModelIntInput | null,
  surveyedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  sendCount?: ModelIntInput | null,
  isSend?: ModelBooleanInput | null,
  treatmentID?: ModelIDInput | null,
  feedbacks?: ModelStringInput | null,
  feedbackResult?: ModelStringInput | null,
  feedbackAt?: ModelStringInput | null,
  feedbackLink?: ModelStringInput | null,
  feedbackDone?: ModelBooleanInput | null,
  isFeedback?: ModelBooleanInput | null,
  additionalSymptoms?: ModelStringInput | null,
  feedbackSendTime?: ModelStringInput | null,
  feedbackSchedule_GSI_PK?: ModelStringInput | null,
  feedbackScheduleStatus?: ModelIntInput | null,
  surveyDone?: ModelBooleanInput | null,
  surveyGrade?: ModelIntInput | null,
  surveySatisfaction?: ModelStringInput | null,
  feedbackGrade?: ModelIntInput | null,
  feedbackSatisfaction?: ModelStringInput | null,
  surveyStatus?: ModelSurveyStatusInput | null,
  expiredAt?: ModelStringInput | null,
  mainSymptomCategory?: ModelStringInput | null,
  sender?: ModelStringInput | null,
  surveyType?: ModelSurveyTypeInput | null,
  insuranceType?: ModelStringInput | null,
  and?: Array< ModelSurveyConditionInput | null > | null,
  or?: Array< ModelSurveyConditionInput | null > | null,
  not?: ModelSurveyConditionInput | null,
  surveySurveyBizTalkHistoryId?: ModelIDInput | null,
  surveyFeedbackBizTalkHistoryId?: ModelIDInput | null,
};

export type ModelSurveyStatusInput = {
  eq?: SurveyStatus | null,
  ne?: SurveyStatus | null,
};

export type ModelSurveyTypeInput = {
  eq?: SurveyType | null,
  ne?: SurveyType | null,
};

export type Survey = {
  __typename: "Survey",
  id: string,
  otp: string,
  patientID: string,
  clinicID: string,
  link?: string | null,
  surveyResult?: string | null,
  mainSymptom?: string | null,
  symptoms?: string | null,
  visitCount?: number | null,
  firstLinkClickDt?: number | null,
  surveyedAt?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  sendCount?: number | null,
  isSend?: boolean | null,
  treatmentID?: string | null,
  feedbacks?: string | null,
  feedbackResult?: string | null,
  feedbackAt?: string | null,
  feedbackLink?: string | null,
  feedbackDone?: boolean | null,
  isFeedback?: boolean | null,
  additionalSymptoms?: string | null,
  feedbackSendTime?: string | null,
  feedbackSchedule_GSI_PK?: string | null,
  feedbackScheduleStatus?: number | null,
  surveyDone?: boolean | null,
  surveyGrade?: number | null,
  surveySatisfaction?: string | null,
  feedbackGrade?: number | null,
  feedbackSatisfaction?: string | null,
  surveyStatus?: SurveyStatus | null,
  expiredAt?: string | null,
  surveyBizTalkHistory?: BiztalkHistory | null,
  feedbackBizTalkHistory?: BiztalkHistory | null,
  mainSymptomCategory?: string | null,
  sender?: string | null,
  surveyType?: SurveyType | null,
  insuranceType?: string | null,
  surveySurveyBizTalkHistoryId?: string | null,
  surveyFeedbackBizTalkHistoryId?: string | null,
};

export type BiztalkHistory = {
  __typename: "BiztalkHistory",
  msgIdx: string,
  type?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type UpdateSurveyInput = {
  id: string,
  otp?: string | null,
  patientID?: string | null,
  clinicID?: string | null,
  link?: string | null,
  surveyResult?: string | null,
  mainSymptom?: string | null,
  symptoms?: string | null,
  visitCount?: number | null,
  firstLinkClickDt?: number | null,
  surveyedAt?: string | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  sendCount?: number | null,
  isSend?: boolean | null,
  treatmentID?: string | null,
  feedbacks?: string | null,
  feedbackResult?: string | null,
  feedbackAt?: string | null,
  feedbackLink?: string | null,
  feedbackDone?: boolean | null,
  isFeedback?: boolean | null,
  additionalSymptoms?: string | null,
  feedbackSendTime?: string | null,
  feedbackSchedule_GSI_PK?: string | null,
  feedbackScheduleStatus?: number | null,
  surveyDone?: boolean | null,
  surveyGrade?: number | null,
  surveySatisfaction?: string | null,
  feedbackGrade?: number | null,
  feedbackSatisfaction?: string | null,
  surveyStatus?: SurveyStatus | null,
  expiredAt?: string | null,
  mainSymptomCategory?: string | null,
  sender?: string | null,
  surveyType?: SurveyType | null,
  insuranceType?: string | null,
  surveySurveyBizTalkHistoryId?: string | null,
  surveyFeedbackBizTalkHistoryId?: string | null,
};

export type DeleteSurveyInput = {
  id: string,
};

export type CreateHistoryInput = {
  id?: string | null,
  clinicID: string,
  patientID: string,
  type?: string | null,
  typeID?: string | null,
  messageCode?: string | null,
  message?: string | null,
  createdAt?: string | null,
};

export type ModelHistoryConditionInput = {
  clinicID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  type?: ModelStringInput | null,
  typeID?: ModelIDInput | null,
  messageCode?: ModelStringInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelHistoryConditionInput | null > | null,
  or?: Array< ModelHistoryConditionInput | null > | null,
  not?: ModelHistoryConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type History = {
  __typename: "History",
  id: string,
  clinicID: string,
  patientID: string,
  type?: string | null,
  typeID?: string | null,
  messageCode?: string | null,
  message?: string | null,
  createdAt?: string | null,
  updatedAt: string,
};

export type UpdateHistoryInput = {
  id: string,
  clinicID?: string | null,
  patientID?: string | null,
  type?: string | null,
  typeID?: string | null,
  messageCode?: string | null,
  message?: string | null,
  createdAt?: string | null,
};

export type DeleteHistoryInput = {
  id: string,
};

export type CreatePatientInput = {
  id?: string | null,
  clinicID: string,
  patientName?: string | null,
  birth?: string | null,
  residentRegistrationNumber?: string | null,
  gender?: Gender | null,
  phone?: string | null,
  recentActivity?: string | null,
  recentActivityMessage?: string | null,
  recentActivityCode?: string | null,
  createdAt?: string | null,
  prescriptionStatus?: number | null,
  isWaiting?: boolean | null,
  isVisited?: boolean | null,
  symptomID?: string | null,
  mainSymptom?: string | null,
  prescriptionID?: string | null,
  surveyID?: string | null,
  userID?: string | null,
  treatmentID?: string | null,
  patientNameForSorting?: string | null,
  surveyedAt?: string | null,
  updatedAt?: string | null,
  treatmentStatus?: number | null,
  treatmentStatusDateForSorting?: string | null,
  address?: string | null,
  registrationPath?: string | null,
  isDeleted?: boolean | null,
};

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  UNKNOWN = "UNKNOWN",
}


export type ModelPatientConditionInput = {
  clinicID?: ModelIDInput | null,
  patientName?: ModelStringInput | null,
  birth?: ModelStringInput | null,
  residentRegistrationNumber?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  phone?: ModelStringInput | null,
  recentActivity?: ModelStringInput | null,
  recentActivityMessage?: ModelStringInput | null,
  recentActivityCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  prescriptionStatus?: ModelIntInput | null,
  isWaiting?: ModelBooleanInput | null,
  isVisited?: ModelBooleanInput | null,
  symptomID?: ModelIDInput | null,
  mainSymptom?: ModelStringInput | null,
  prescriptionID?: ModelIDInput | null,
  surveyID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  treatmentID?: ModelIDInput | null,
  patientNameForSorting?: ModelStringInput | null,
  surveyedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  treatmentStatus?: ModelIntInput | null,
  treatmentStatusDateForSorting?: ModelStringInput | null,
  address?: ModelStringInput | null,
  registrationPath?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  and?: Array< ModelPatientConditionInput | null > | null,
  or?: Array< ModelPatientConditionInput | null > | null,
  not?: ModelPatientConditionInput | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type Patient = {
  __typename: "Patient",
  id: string,
  clinicID: string,
  patientName?: string | null,
  birth?: string | null,
  residentRegistrationNumber?: string | null,
  gender?: Gender | null,
  phone?: string | null,
  recentActivity?: string | null,
  recentActivityMessage?: string | null,
  recentActivityCode?: string | null,
  createdAt?: string | null,
  prescriptionStatus?: number | null,
  isWaiting?: boolean | null,
  isVisited?: boolean | null,
  symptomID?: string | null,
  mainSymptom?: string | null,
  prescriptionID?: string | null,
  surveyID?: string | null,
  survey?: Survey | null,
  userID?: string | null,
  treatmentID?: string | null,
  patientNameForSorting?: string | null,
  surveyedAt?: string | null,
  updatedAt?: string | null,
  treatmentStatus?: number | null,
  treatmentStatusDateForSorting?: string | null,
  address?: string | null,
  registrationPath?: string | null,
  isDeleted?: boolean | null,
};

export type UpdatePatientInput = {
  id: string,
  clinicID?: string | null,
  patientName?: string | null,
  birth?: string | null,
  residentRegistrationNumber?: string | null,
  gender?: Gender | null,
  phone?: string | null,
  recentActivity?: string | null,
  recentActivityMessage?: string | null,
  recentActivityCode?: string | null,
  createdAt?: string | null,
  prescriptionStatus?: number | null,
  isWaiting?: boolean | null,
  isVisited?: boolean | null,
  symptomID?: string | null,
  mainSymptom?: string | null,
  prescriptionID?: string | null,
  surveyID?: string | null,
  userID?: string | null,
  treatmentID?: string | null,
  patientNameForSorting?: string | null,
  surveyedAt?: string | null,
  updatedAt?: string | null,
  treatmentStatus?: number | null,
  treatmentStatusDateForSorting?: string | null,
  address?: string | null,
  registrationPath?: string | null,
  isDeleted?: boolean | null,
};

export type DeletePatientInput = {
  id: string,
};

export type CreatePatientDeletedInput = {
  id?: string | null,
  clinicID: string,
  patientName?: string | null,
  birth?: string | null,
  gender?: Gender | null,
  phone?: string | null,
  residentRegistrationNumber?: string | null,
  recentActivity?: string | null,
  recentActivityMessage?: string | null,
  createdAt?: string | null,
  prescriptionStatus?: number | null,
  isWaiting?: boolean | null,
  isVisited?: boolean | null,
  symptomID?: string | null,
  mainSymptom?: string | null,
  prescriptionID?: string | null,
  surveyID?: string | null,
  userID?: string | null,
  treatmentID?: string | null,
  patientNameForSorting?: string | null,
  surveyedAt?: string | null,
  updatedAt?: string | null,
  treatmentStatus?: number | null,
  treatmentStatusDateForSorting?: string | null,
  address?: string | null,
  type: string,
  privacyTerms?: string | null,
};

export type ModelPatientDeletedConditionInput = {
  clinicID?: ModelIDInput | null,
  patientName?: ModelStringInput | null,
  birth?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  phone?: ModelStringInput | null,
  residentRegistrationNumber?: ModelStringInput | null,
  recentActivity?: ModelStringInput | null,
  recentActivityMessage?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  prescriptionStatus?: ModelIntInput | null,
  isWaiting?: ModelBooleanInput | null,
  isVisited?: ModelBooleanInput | null,
  symptomID?: ModelIDInput | null,
  mainSymptom?: ModelStringInput | null,
  prescriptionID?: ModelIDInput | null,
  surveyID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  treatmentID?: ModelIDInput | null,
  patientNameForSorting?: ModelStringInput | null,
  surveyedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  treatmentStatus?: ModelIntInput | null,
  treatmentStatusDateForSorting?: ModelStringInput | null,
  address?: ModelStringInput | null,
  type?: ModelStringInput | null,
  privacyTerms?: ModelStringInput | null,
  and?: Array< ModelPatientDeletedConditionInput | null > | null,
  or?: Array< ModelPatientDeletedConditionInput | null > | null,
  not?: ModelPatientDeletedConditionInput | null,
};

export type PatientDeleted = {
  __typename: "PatientDeleted",
  id: string,
  clinicID: string,
  patientName?: string | null,
  birth?: string | null,
  gender?: Gender | null,
  phone?: string | null,
  residentRegistrationNumber?: string | null,
  recentActivity?: string | null,
  recentActivityMessage?: string | null,
  createdAt?: string | null,
  prescriptionStatus?: number | null,
  isWaiting?: boolean | null,
  isVisited?: boolean | null,
  symptomID?: string | null,
  mainSymptom?: string | null,
  prescriptionID?: string | null,
  surveyID?: string | null,
  survey?: Survey | null,
  userID?: string | null,
  treatmentID?: string | null,
  patientNameForSorting?: string | null,
  surveyedAt?: string | null,
  updatedAt?: string | null,
  treatmentStatus?: number | null,
  treatmentStatusDateForSorting?: string | null,
  address?: string | null,
  type: string,
  privacyTerms?: string | null,
};

export type UpdatePatientDeletedInput = {
  id: string,
  clinicID?: string | null,
  patientName?: string | null,
  birth?: string | null,
  gender?: Gender | null,
  phone?: string | null,
  residentRegistrationNumber?: string | null,
  recentActivity?: string | null,
  recentActivityMessage?: string | null,
  createdAt?: string | null,
  prescriptionStatus?: number | null,
  isWaiting?: boolean | null,
  isVisited?: boolean | null,
  symptomID?: string | null,
  mainSymptom?: string | null,
  prescriptionID?: string | null,
  surveyID?: string | null,
  userID?: string | null,
  treatmentID?: string | null,
  patientNameForSorting?: string | null,
  surveyedAt?: string | null,
  updatedAt?: string | null,
  treatmentStatus?: number | null,
  treatmentStatusDateForSorting?: string | null,
  address?: string | null,
  type?: string | null,
  privacyTerms?: string | null,
};

export type DeletePatientDeletedInput = {
  id: string,
};

export type CreateUserRoleInput = {
  id?: string | null,
  clinicID: string,
  config?: string | null,
  clinicName?: string | null,
  status?: string | null,
  email?: string | null,
  phone?: string | null,
  userID: string,
  roleName: string,
  userName: string,
  isDeleted?: boolean | null,
  testFlag?: boolean | null,
};

export type ModelUserRoleConditionInput = {
  clinicID?: ModelIDInput | null,
  config?: ModelStringInput | null,
  clinicName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  roleName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  testFlag?: ModelBooleanInput | null,
  and?: Array< ModelUserRoleConditionInput | null > | null,
  or?: Array< ModelUserRoleConditionInput | null > | null,
  not?: ModelUserRoleConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UserRole = {
  __typename: "UserRole",
  id: string,
  clinicID: string,
  clinic?: Clinic | null,
  config?: string | null,
  clinicName?: string | null,
  status?: string | null,
  email?: string | null,
  phone?: string | null,
  userID: string,
  user?: User | null,
  roleName: string,
  userName: string,
  isDeleted?: boolean | null,
  testFlag?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Clinic = {
  __typename: "Clinic",
  id: string,
  clinicName?: string | null,
  doctorName?: string | null,
  doctorNumber?: string | null,
  phone?: string | null,
  fax?: string | null,
  medicalInstitutionNumber?: string | null,
  prescriptionValidityPeriod?: number | null,
  address?: string | null,
  feedbackDate?: number | null,
  isDeleted?: boolean | null,
  pricingInfo?: PricingInfo | null,
  stepPayID?: string | null,
  numberOfPatients?: number | null,
  numberOfDoctors?: number | null,
  numberOfSteps?: number | null,
  clinicEmail?: string | null,
  updatedAt?: string | null,
  planInfo?: string | null,
  planCheckPassFlag?: boolean | null,
  createdAt?: string | null,
  typename: string,
  config?: string | null,
  businessType?: BusinessType | null,
  promotion?: string | null,
};

export type PricingInfo = {
  __typename: "PricingInfo",
  currentPricingId?: string | null,
  nextPricingId?: string | null,
};

export enum BusinessType {
  BUSINESS = "BUSINESS",
  PERSONAL = "PERSONAL",
}


export type User = {
  __typename: "User",
  id: string,
  email?: string | null,
  username?: string | null,
  phone?: string | null,
  terms?: Array< boolean | null > | null,
  termsVersion?: string | null,
  termsAgreedAt?: string | null,
  createdAt?: string | null,
  isDeleted?: boolean | null,
  roleName?: string | null,
  config?: string | null,
  licenseNumber?: string | null,
  licensePendingReviewFlag?: boolean | null,
  updatedAt: string,
};

export type UpdateUserRoleInput = {
  id: string,
  clinicID?: string | null,
  config?: string | null,
  clinicName?: string | null,
  status?: string | null,
  email?: string | null,
  phone?: string | null,
  userID?: string | null,
  roleName?: string | null,
  userName?: string | null,
  isDeleted?: boolean | null,
  testFlag?: boolean | null,
};

export type DeleteUserRoleInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  email?: string | null,
  username?: string | null,
  phone?: string | null,
  terms?: Array< boolean | null > | null,
  termsVersion?: string | null,
  termsAgreedAt?: string | null,
  createdAt?: string | null,
  isDeleted?: boolean | null,
  roleName?: string | null,
  config?: string | null,
  licenseNumber?: string | null,
  licensePendingReviewFlag?: boolean | null,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  username?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  terms?: ModelBooleanInput | null,
  termsVersion?: ModelStringInput | null,
  termsAgreedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  roleName?: ModelStringInput | null,
  config?: ModelStringInput | null,
  licenseNumber?: ModelStringInput | null,
  licensePendingReviewFlag?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserInput = {
  id: string,
  email?: string | null,
  username?: string | null,
  phone?: string | null,
  terms?: Array< boolean | null > | null,
  termsVersion?: string | null,
  termsAgreedAt?: string | null,
  createdAt?: string | null,
  isDeleted?: boolean | null,
  roleName?: string | null,
  config?: string | null,
  licenseNumber?: string | null,
  licensePendingReviewFlag?: boolean | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateUserPoolInput = {
  id?: string | null,
};

export type ModelUserPoolConditionInput = {
  and?: Array< ModelUserPoolConditionInput | null > | null,
  or?: Array< ModelUserPoolConditionInput | null > | null,
  not?: ModelUserPoolConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UserPool = {
  __typename: "UserPool",
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserPoolInput = {
  id: string,
};

export type DeleteUserPoolInput = {
  id: string,
};

export type CreateRoleInput = {
  id?: string | null,
  clinicID: string,
  roleName?: string | null,
  permisstions?: Array< string | null > | null,
};

export type ModelRoleConditionInput = {
  clinicID?: ModelIDInput | null,
  roleName?: ModelStringInput | null,
  permisstions?: ModelStringInput | null,
  and?: Array< ModelRoleConditionInput | null > | null,
  or?: Array< ModelRoleConditionInput | null > | null,
  not?: ModelRoleConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Role = {
  __typename: "Role",
  id: string,
  clinicID: string,
  roleName?: string | null,
  permisstions?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateRoleInput = {
  id: string,
  clinicID?: string | null,
  roleName?: string | null,
  permisstions?: Array< string | null > | null,
};

export type DeleteRoleInput = {
  id: string,
};

export type CreateClinicsForPromotionInput = {
  id?: string | null,
  promotionType?: string | null,
  clinicID: string,
};

export type ModelClinicsForPromotionConditionInput = {
  promotionType?: ModelStringInput | null,
  clinicID?: ModelIDInput | null,
  and?: Array< ModelClinicsForPromotionConditionInput | null > | null,
  or?: Array< ModelClinicsForPromotionConditionInput | null > | null,
  not?: ModelClinicsForPromotionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ClinicsForPromotion = {
  __typename: "ClinicsForPromotion",
  id: string,
  promotionType?: string | null,
  clinicID: string,
  clinic?: Clinic | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateClinicsForPromotionInput = {
  id: string,
  promotionType?: string | null,
  clinicID?: string | null,
};

export type DeleteClinicsForPromotionInput = {
  id: string,
};

export type CreateClinicInput = {
  id?: string | null,
  clinicName?: string | null,
  doctorName?: string | null,
  doctorNumber?: string | null,
  phone?: string | null,
  fax?: string | null,
  medicalInstitutionNumber?: string | null,
  prescriptionValidityPeriod?: number | null,
  address?: string | null,
  feedbackDate?: number | null,
  isDeleted?: boolean | null,
  pricingInfo?: PricingInfoInput | null,
  stepPayID?: string | null,
  numberOfPatients?: number | null,
  numberOfDoctors?: number | null,
  numberOfSteps?: number | null,
  clinicEmail?: string | null,
  updatedAt?: string | null,
  planInfo?: string | null,
  planCheckPassFlag?: boolean | null,
  createdAt?: string | null,
  typename: string,
  config?: string | null,
  businessType?: BusinessType | null,
  promotion?: string | null,
};

export type PricingInfoInput = {
  currentPricingId?: string | null,
  nextPricingId?: string | null,
};

export type ModelClinicConditionInput = {
  clinicName?: ModelStringInput | null,
  doctorName?: ModelStringInput | null,
  doctorNumber?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  medicalInstitutionNumber?: ModelStringInput | null,
  prescriptionValidityPeriod?: ModelIntInput | null,
  address?: ModelStringInput | null,
  feedbackDate?: ModelIntInput | null,
  isDeleted?: ModelBooleanInput | null,
  stepPayID?: ModelStringInput | null,
  numberOfPatients?: ModelIntInput | null,
  numberOfDoctors?: ModelIntInput | null,
  numberOfSteps?: ModelIntInput | null,
  clinicEmail?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  planInfo?: ModelStringInput | null,
  planCheckPassFlag?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  typename?: ModelStringInput | null,
  config?: ModelStringInput | null,
  businessType?: ModelBusinessTypeInput | null,
  promotion?: ModelStringInput | null,
  and?: Array< ModelClinicConditionInput | null > | null,
  or?: Array< ModelClinicConditionInput | null > | null,
  not?: ModelClinicConditionInput | null,
};

export type ModelBusinessTypeInput = {
  eq?: BusinessType | null,
  ne?: BusinessType | null,
};

export type UpdateClinicInput = {
  id: string,
  clinicName?: string | null,
  doctorName?: string | null,
  doctorNumber?: string | null,
  phone?: string | null,
  fax?: string | null,
  medicalInstitutionNumber?: string | null,
  prescriptionValidityPeriod?: number | null,
  address?: string | null,
  feedbackDate?: number | null,
  isDeleted?: boolean | null,
  pricingInfo?: PricingInfoInput | null,
  stepPayID?: string | null,
  numberOfPatients?: number | null,
  numberOfDoctors?: number | null,
  numberOfSteps?: number | null,
  clinicEmail?: string | null,
  updatedAt?: string | null,
  planInfo?: string | null,
  planCheckPassFlag?: boolean | null,
  createdAt?: string | null,
  typename?: string | null,
  config?: string | null,
  businessType?: BusinessType | null,
  promotion?: string | null,
};

export type DeleteClinicInput = {
  id: string,
};

export type CreateTreatmentInput = {
  id?: string | null,
  clinicID: string,
  surveyID: string,
  patientID: string,
  save?: number | null,
  feedbackID?: string | null,
  mainSymptom?: string | null,
  userID: string,
  prescribedHerb?: Array< PrescribedHerbInput | null > | null,
  status?: string | null,
  cure?: Array< string | null > | null,
  memo?: string | null,
  lastVisitedAt?: string | null,
  selectSymptom?: Array< SelectSymptomInput | null > | null,
  symptomCheck?: Array< string | null > | null,
  name?: string | null,
  birth?: string | null,
  gender?: string | null,
  prescriptionStatus?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  amount?: number | null,
  isVisited?: boolean | null,
  isDeleted?: boolean | null,
  surveyCheck?: Array< string | null > | null,
  herbsForRecommanded?: Array< string | null > | null,
  treatmentedAt?: string | null,
  treatmentStatus?: TreatmentStatus | null,
  patientNameForSearching?: string | null,
  selectedBCode?: Array< string | null > | null,
  prescriptionMode?: string | null,
  prescribedRecipe?: Array< PRInput | null > | null,
  prescriptionId?: string | null,
  customHerbalFormulaId?: string | null,
  prescriptionDocument?: string | null,
  footprintsLog?: Array< string | null > | null,
};

export type PrescribedHerbInput = {
  code?: string | null,
  amount?: number | null,
  name?: string | null,
};

export type SelectSymptomInput = {
  code?: string | null,
  check?: boolean | null,
};

export enum TreatmentStatus {
  WAITING = "WAITING",
  DONE = "DONE",
  INPROGRESS = "INPROGRESS",
}


export type ModelTreatmentConditionInput = {
  clinicID?: ModelIDInput | null,
  surveyID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  save?: ModelIntInput | null,
  feedbackID?: ModelIDInput | null,
  mainSymptom?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  cure?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  lastVisitedAt?: ModelStringInput | null,
  symptomCheck?: ModelStringInput | null,
  name?: ModelStringInput | null,
  birth?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  prescriptionStatus?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  isVisited?: ModelBooleanInput | null,
  isDeleted?: ModelBooleanInput | null,
  surveyCheck?: ModelStringInput | null,
  herbsForRecommanded?: ModelStringInput | null,
  treatmentedAt?: ModelStringInput | null,
  treatmentStatus?: ModelTreatmentStatusInput | null,
  patientNameForSearching?: ModelStringInput | null,
  selectedBCode?: ModelStringInput | null,
  prescriptionMode?: ModelStringInput | null,
  prescriptionId?: ModelIDInput | null,
  customHerbalFormulaId?: ModelIDInput | null,
  prescriptionDocument?: ModelStringInput | null,
  footprintsLog?: ModelStringInput | null,
  and?: Array< ModelTreatmentConditionInput | null > | null,
  or?: Array< ModelTreatmentConditionInput | null > | null,
  not?: ModelTreatmentConditionInput | null,
};

export type ModelTreatmentStatusInput = {
  eq?: TreatmentStatus | null,
  ne?: TreatmentStatus | null,
};

export type Treatment = {
  __typename: "Treatment",
  id: string,
  clinicID: string,
  surveyID: string,
  patientID: string,
  patientData?: Patient | null,
  save?: number | null,
  survey?: Survey | null,
  feedbackID?: string | null,
  mainSymptom?: string | null,
  userID: string,
  prescribedHerb?:  Array<PrescribedHerb | null > | null,
  status?: string | null,
  cure?: Array< string | null > | null,
  memo?: string | null,
  lastVisitedAt?: string | null,
  selectSymptom?:  Array<SelectSymptom | null > | null,
  symptomCheck?: Array< string | null > | null,
  name?: string | null,
  birth?: string | null,
  gender?: string | null,
  prescriptionStatus?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  amount?: number | null,
  isVisited?: boolean | null,
  isDeleted?: boolean | null,
  surveyCheck?: Array< string | null > | null,
  herbsForRecommanded?: Array< string | null > | null,
  treatmentedAt?: string | null,
  treatmentStatus?: TreatmentStatus | null,
  patientNameForSearching?: string | null,
  selectedBCode?: Array< string | null > | null,
  prescriptionMode?: string | null,
  prescribedRecipe?:  Array<PR | null > | null,
  prescriptionId?: string | null,
  prescriptionData?: HerbalFormula | null,
  customHerbalFormulaId?: string | null,
  customHerbalFormulaData?: CustomHerbalFormula | null,
  prescriptionDocument?: string | null,
  footprintsLog?: Array< string | null > | null,
};

export type PrescribedHerb = {
  __typename: "PrescribedHerb",
  code?: string | null,
  amount?: number | null,
  name?: string | null,
};

export type SelectSymptom = {
  __typename: "SelectSymptom",
  code?: string | null,
  check?: boolean | null,
};

export type UpdateTreatmentInput = {
  id: string,
  clinicID?: string | null,
  surveyID?: string | null,
  patientID?: string | null,
  save?: number | null,
  feedbackID?: string | null,
  mainSymptom?: string | null,
  userID?: string | null,
  prescribedHerb?: Array< PrescribedHerbInput | null > | null,
  status?: string | null,
  cure?: Array< string | null > | null,
  memo?: string | null,
  lastVisitedAt?: string | null,
  selectSymptom?: Array< SelectSymptomInput | null > | null,
  symptomCheck?: Array< string | null > | null,
  name?: string | null,
  birth?: string | null,
  gender?: string | null,
  prescriptionStatus?: number | null,
  updatedAt?: string | null,
  createdAt?: string | null,
  amount?: number | null,
  isVisited?: boolean | null,
  isDeleted?: boolean | null,
  surveyCheck?: Array< string | null > | null,
  herbsForRecommanded?: Array< string | null > | null,
  treatmentedAt?: string | null,
  treatmentStatus?: TreatmentStatus | null,
  patientNameForSearching?: string | null,
  selectedBCode?: Array< string | null > | null,
  prescriptionMode?: string | null,
  prescribedRecipe?: Array< PRInput | null > | null,
  prescriptionId?: string | null,
  customHerbalFormulaId?: string | null,
  prescriptionDocument?: string | null,
  footprintsLog?: Array< string | null > | null,
};

export type DeleteTreatmentInput = {
  id: string,
};

export type CreateFormulaInput = {
  id?: string | null,
  formulaName?: string | null,
  chineseName?: string | null,
  herbs?: Array< string | null > | null,
  amount?: Array< number | null > | null,
  primaryCare?: Array< string | null > | null,
  tag?: Array< string | null > | null,
  category?: string | null,
  priority?: number | null,
  gun?: Array< string | null > | null,
  sin?: Array< string | null > | null,
};

export type ModelFormulaConditionInput = {
  formulaName?: ModelStringInput | null,
  chineseName?: ModelStringInput | null,
  herbs?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  primaryCare?: ModelStringInput | null,
  tag?: ModelStringInput | null,
  category?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  gun?: ModelStringInput | null,
  sin?: ModelStringInput | null,
  and?: Array< ModelFormulaConditionInput | null > | null,
  or?: Array< ModelFormulaConditionInput | null > | null,
  not?: ModelFormulaConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Formula = {
  __typename: "Formula",
  id: string,
  formulaName?: string | null,
  chineseName?: string | null,
  herbs?: Array< string | null > | null,
  amount?: Array< number | null > | null,
  primaryCare?: Array< string | null > | null,
  tag?: Array< string | null > | null,
  category?: string | null,
  priority?: number | null,
  gun?: Array< string | null > | null,
  sin?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFormulaInput = {
  id: string,
  formulaName?: string | null,
  chineseName?: string | null,
  herbs?: Array< string | null > | null,
  amount?: Array< number | null > | null,
  primaryCare?: Array< string | null > | null,
  tag?: Array< string | null > | null,
  category?: string | null,
  priority?: number | null,
  gun?: Array< string | null > | null,
  sin?: Array< string | null > | null,
};

export type DeleteFormulaInput = {
  id: string,
};

export type CreateBiztalkHistoryInput = {
  msgIdx: string,
  type?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelBiztalkHistoryConditionInput = {
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBiztalkHistoryConditionInput | null > | null,
  or?: Array< ModelBiztalkHistoryConditionInput | null > | null,
  not?: ModelBiztalkHistoryConditionInput | null,
};

export type UpdateBiztalkHistoryInput = {
  msgIdx: string,
  type?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteBiztalkHistoryInput = {
  msgIdx: string,
};

export type CreateYejinSignupRequestInput = {
  id?: string | null,
  userName?: string | null,
  email?: string | null,
  clinicName?: string | null,
  clinicAddress?: string | null,
  clinicPhone?: string | null,
  terms?: Array< boolean | null > | null,
  termsVersion?: string | null,
  termsAgreedAt?: string | null,
  permission?: string | null,
  licenseNumber?: string | null,
  clinicEmail?: string | null,
  businessType?: BusinessType | null,
  createdAt?: string | null,
  approvedAt?: string | null,
  requestStatus?: SignupRequestStatus | null,
};

export enum SignupRequestStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}


export type ModelYejinSignupRequestConditionInput = {
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  clinicName?: ModelStringInput | null,
  clinicAddress?: ModelStringInput | null,
  clinicPhone?: ModelStringInput | null,
  terms?: ModelBooleanInput | null,
  termsVersion?: ModelStringInput | null,
  termsAgreedAt?: ModelStringInput | null,
  permission?: ModelStringInput | null,
  licenseNumber?: ModelStringInput | null,
  clinicEmail?: ModelStringInput | null,
  businessType?: ModelBusinessTypeInput | null,
  createdAt?: ModelStringInput | null,
  approvedAt?: ModelStringInput | null,
  requestStatus?: ModelSignupRequestStatusInput | null,
  and?: Array< ModelYejinSignupRequestConditionInput | null > | null,
  or?: Array< ModelYejinSignupRequestConditionInput | null > | null,
  not?: ModelYejinSignupRequestConditionInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelSignupRequestStatusInput = {
  eq?: SignupRequestStatus | null,
  ne?: SignupRequestStatus | null,
};

export type YejinSignupRequest = {
  __typename: "YejinSignupRequest",
  id?: string | null,
  userName?: string | null,
  email?: string | null,
  clinicName?: string | null,
  clinicAddress?: string | null,
  clinicPhone?: string | null,
  terms?: Array< boolean | null > | null,
  termsVersion?: string | null,
  termsAgreedAt?: string | null,
  permission?: string | null,
  licenseNumber?: string | null,
  clinicEmail?: string | null,
  businessType?: BusinessType | null,
  createdAt?: string | null,
  approvedAt?: string | null,
  requestStatus?: SignupRequestStatus | null,
  updatedAt: string,
};

export type UpdateYejinSignupRequestInput = {
  id: string,
  userName?: string | null,
  email?: string | null,
  clinicName?: string | null,
  clinicAddress?: string | null,
  clinicPhone?: string | null,
  terms?: Array< boolean | null > | null,
  termsVersion?: string | null,
  termsAgreedAt?: string | null,
  permission?: string | null,
  licenseNumber?: string | null,
  clinicEmail?: string | null,
  businessType?: BusinessType | null,
  createdAt?: string | null,
  approvedAt?: string | null,
  requestStatus?: SignupRequestStatus | null,
};

export type DeleteYejinSignupRequestInput = {
  id: string,
};

export type CreateReportInput = {
  treatmentID: string,
  sendCount?: number | null,
  byeonjeung?: string | null,
  startedAt?: string | null,
  birth?: string | null,
};

export type ModelReportConditionInput = {
  sendCount?: ModelIntInput | null,
  byeonjeung?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  birth?: ModelStringInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateReportInput = {
  treatmentID: string,
  sendCount?: number | null,
  byeonjeung?: string | null,
  startedAt?: string | null,
  birth?: string | null,
};

export type DeleteReportInput = {
  treatmentID: string,
};

export type CreateSelfRegistrationQRInput = {
  QRID: string,
  clinicID: string,
  expiredDate?: string | null,
};

export type ModelSelfRegistrationQRConditionInput = {
  clinicID?: ModelIDInput | null,
  expiredDate?: ModelStringInput | null,
  and?: Array< ModelSelfRegistrationQRConditionInput | null > | null,
  or?: Array< ModelSelfRegistrationQRConditionInput | null > | null,
  not?: ModelSelfRegistrationQRConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type SelfRegistrationQR = {
  __typename: "SelfRegistrationQR",
  QRID: string,
  clinicID: string,
  clinic?: Clinic | null,
  expiredDate?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSelfRegistrationQRInput = {
  QRID: string,
  clinicID?: string | null,
  expiredDate?: string | null,
};

export type DeleteSelfRegistrationQRInput = {
  QRID: string,
};

export type CreateDailySignupCountInput = {
  date: string,
  count?: number | null,
  memo?: string | null,
};

export type ModelDailySignupCountConditionInput = {
  count?: ModelIntInput | null,
  memo?: ModelStringInput | null,
  and?: Array< ModelDailySignupCountConditionInput | null > | null,
  or?: Array< ModelDailySignupCountConditionInput | null > | null,
  not?: ModelDailySignupCountConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type DailySignupCount = {
  __typename: "DailySignupCount",
  date: string,
  count?: number | null,
  memo?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDailySignupCountInput = {
  date: string,
  count?: number | null,
  memo?: string | null,
};

export type DeleteDailySignupCountInput = {
  date: string,
};

export type CreateDailyPatientCountInput = {
  date: string,
  count?: number | null,
};

export type ModelDailyPatientCountConditionInput = {
  count?: ModelIntInput | null,
  and?: Array< ModelDailyPatientCountConditionInput | null > | null,
  or?: Array< ModelDailyPatientCountConditionInput | null > | null,
  not?: ModelDailyPatientCountConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type DailyPatientCount = {
  __typename: "DailyPatientCount",
  date: string,
  count?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDailyPatientCountInput = {
  date: string,
  count?: number | null,
};

export type DeleteDailyPatientCountInput = {
  date: string,
};

export type ModelHerbFilterInput = {
  code?: ModelIDInput | null,
  herbName?: ModelStringInput | null,
  nameForSearching?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelHerbFilterInput | null > | null,
  or?: Array< ModelHerbFilterInput | null > | null,
  not?: ModelHerbFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelHerbConnection = {
  __typename: "ModelHerbConnection",
  items:  Array<Herb | null >,
  nextToken?: string | null,
};

export type ModelCustomHerbalFormulaFilterInput = {
  id?: ModelIDInput | null,
  ownerUserID?: ModelIDInput | null,
  originalHerbalFormulaID?: ModelIDInput | null,
  insrCCode?: ModelStringInput | null,
  insrDsCode?: ModelStringInput | null,
  InsrPrscrType?: ModelIntInput | null,
  kn?: ModelStringInput | null,
  herbCodes?: ModelStringInput | null,
  processingCodes?: ModelStringInput | null,
  scoringType?: ModelIntInput | null,
  memo?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCustomHerbalFormulaFilterInput | null > | null,
  or?: Array< ModelCustomHerbalFormulaFilterInput | null > | null,
  not?: ModelCustomHerbalFormulaFilterInput | null,
};

export type ModelCustomHerbalFormulaConnection = {
  __typename: "ModelCustomHerbalFormulaConnection",
  items:  Array<CustomHerbalFormula | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelHerbalFormulaFilterInput = {
  id?: ModelIDInput | null,
  category?: ModelStringInput | null,
  insrCCode?: ModelStringInput | null,
  insrDsCode?: ModelStringInput | null,
  InsrPrscrType?: ModelIntInput | null,
  kn?: ModelStringInput | null,
  cn?: ModelStringInput | null,
  herbCodes?: ModelStringInput | null,
  processingCodes?: ModelStringInput | null,
  gun?: ModelStringInput | null,
  sin?: ModelStringInput | null,
  scoringType?: ModelIntInput | null,
  source?: ModelStringInput | null,
  postfix?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHerbalFormulaFilterInput | null > | null,
  or?: Array< ModelHerbalFormulaFilterInput | null > | null,
  not?: ModelHerbalFormulaFilterInput | null,
};

export type ModelHerbalFormulaConnection = {
  __typename: "ModelHerbalFormulaConnection",
  items:  Array<HerbalFormula | null >,
  nextToken?: string | null,
};

export type ModelSurveyFilterInput = {
  id?: ModelIDInput | null,
  otp?: ModelStringInput | null,
  patientID?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  link?: ModelStringInput | null,
  surveyResult?: ModelStringInput | null,
  mainSymptom?: ModelStringInput | null,
  symptoms?: ModelStringInput | null,
  visitCount?: ModelIntInput | null,
  firstLinkClickDt?: ModelIntInput | null,
  surveyedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  sendCount?: ModelIntInput | null,
  isSend?: ModelBooleanInput | null,
  treatmentID?: ModelIDInput | null,
  feedbacks?: ModelStringInput | null,
  feedbackResult?: ModelStringInput | null,
  feedbackAt?: ModelStringInput | null,
  feedbackLink?: ModelStringInput | null,
  feedbackDone?: ModelBooleanInput | null,
  isFeedback?: ModelBooleanInput | null,
  additionalSymptoms?: ModelStringInput | null,
  feedbackSendTime?: ModelStringInput | null,
  feedbackSchedule_GSI_PK?: ModelStringInput | null,
  feedbackScheduleStatus?: ModelIntInput | null,
  surveyDone?: ModelBooleanInput | null,
  surveyGrade?: ModelIntInput | null,
  surveySatisfaction?: ModelStringInput | null,
  feedbackGrade?: ModelIntInput | null,
  feedbackSatisfaction?: ModelStringInput | null,
  surveyStatus?: ModelSurveyStatusInput | null,
  expiredAt?: ModelStringInput | null,
  mainSymptomCategory?: ModelStringInput | null,
  sender?: ModelStringInput | null,
  surveyType?: ModelSurveyTypeInput | null,
  insuranceType?: ModelStringInput | null,
  and?: Array< ModelSurveyFilterInput | null > | null,
  or?: Array< ModelSurveyFilterInput | null > | null,
  not?: ModelSurveyFilterInput | null,
  surveySurveyBizTalkHistoryId?: ModelIDInput | null,
  surveyFeedbackBizTalkHistoryId?: ModelIDInput | null,
};

export type ModelSurveyConnection = {
  __typename: "ModelSurveyConnection",
  items:  Array<Survey | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelHistoryFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  type?: ModelStringInput | null,
  typeID?: ModelIDInput | null,
  messageCode?: ModelStringInput | null,
  message?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHistoryFilterInput | null > | null,
  or?: Array< ModelHistoryFilterInput | null > | null,
  not?: ModelHistoryFilterInput | null,
};

export type ModelHistoryConnection = {
  __typename: "ModelHistoryConnection",
  items:  Array<History | null >,
  nextToken?: string | null,
};

export type ModelPatientFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  patientName?: ModelStringInput | null,
  birth?: ModelStringInput | null,
  residentRegistrationNumber?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  phone?: ModelStringInput | null,
  recentActivity?: ModelStringInput | null,
  recentActivityMessage?: ModelStringInput | null,
  recentActivityCode?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  prescriptionStatus?: ModelIntInput | null,
  isWaiting?: ModelBooleanInput | null,
  isVisited?: ModelBooleanInput | null,
  symptomID?: ModelIDInput | null,
  mainSymptom?: ModelStringInput | null,
  prescriptionID?: ModelIDInput | null,
  surveyID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  treatmentID?: ModelIDInput | null,
  patientNameForSorting?: ModelStringInput | null,
  surveyedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  treatmentStatus?: ModelIntInput | null,
  treatmentStatusDateForSorting?: ModelStringInput | null,
  address?: ModelStringInput | null,
  registrationPath?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  and?: Array< ModelPatientFilterInput | null > | null,
  or?: Array< ModelPatientFilterInput | null > | null,
  not?: ModelPatientFilterInput | null,
};

export type ModelPatientConnection = {
  __typename: "ModelPatientConnection",
  items:  Array<Patient | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelPatientDeletedFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  patientName?: ModelStringInput | null,
  birth?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  phone?: ModelStringInput | null,
  residentRegistrationNumber?: ModelStringInput | null,
  recentActivity?: ModelStringInput | null,
  recentActivityMessage?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  prescriptionStatus?: ModelIntInput | null,
  isWaiting?: ModelBooleanInput | null,
  isVisited?: ModelBooleanInput | null,
  symptomID?: ModelIDInput | null,
  mainSymptom?: ModelStringInput | null,
  prescriptionID?: ModelIDInput | null,
  surveyID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  treatmentID?: ModelIDInput | null,
  patientNameForSorting?: ModelStringInput | null,
  surveyedAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  treatmentStatus?: ModelIntInput | null,
  treatmentStatusDateForSorting?: ModelStringInput | null,
  address?: ModelStringInput | null,
  type?: ModelStringInput | null,
  privacyTerms?: ModelStringInput | null,
  and?: Array< ModelPatientDeletedFilterInput | null > | null,
  or?: Array< ModelPatientDeletedFilterInput | null > | null,
  not?: ModelPatientDeletedFilterInput | null,
};

export type ModelPatientDeletedConnection = {
  __typename: "ModelPatientDeletedConnection",
  items:  Array<PatientDeleted | null >,
  nextToken?: string | null,
};

export type ModelUserRoleFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  config?: ModelStringInput | null,
  clinicName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  roleName?: ModelStringInput | null,
  userName?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  testFlag?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserRoleFilterInput | null > | null,
  or?: Array< ModelUserRoleFilterInput | null > | null,
  not?: ModelUserRoleFilterInput | null,
};

export type ModelUserRoleConnection = {
  __typename: "ModelUserRoleConnection",
  items:  Array<UserRole | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  username?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  terms?: ModelBooleanInput | null,
  termsVersion?: ModelStringInput | null,
  termsAgreedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  roleName?: ModelStringInput | null,
  config?: ModelStringInput | null,
  licenseNumber?: ModelStringInput | null,
  licensePendingReviewFlag?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelUserPoolFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserPoolFilterInput | null > | null,
  or?: Array< ModelUserPoolFilterInput | null > | null,
  not?: ModelUserPoolFilterInput | null,
};

export type ModelUserPoolConnection = {
  __typename: "ModelUserPoolConnection",
  items:  Array<UserPool | null >,
  nextToken?: string | null,
};

export type ModelRoleFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  roleName?: ModelStringInput | null,
  permisstions?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRoleFilterInput | null > | null,
  or?: Array< ModelRoleFilterInput | null > | null,
  not?: ModelRoleFilterInput | null,
};

export type ModelRoleConnection = {
  __typename: "ModelRoleConnection",
  items:  Array<Role | null >,
  nextToken?: string | null,
};

export type ModelClinicsForPromotionFilterInput = {
  id?: ModelIDInput | null,
  promotionType?: ModelStringInput | null,
  clinicID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClinicsForPromotionFilterInput | null > | null,
  or?: Array< ModelClinicsForPromotionFilterInput | null > | null,
  not?: ModelClinicsForPromotionFilterInput | null,
};

export type ModelClinicsForPromotionConnection = {
  __typename: "ModelClinicsForPromotionConnection",
  items:  Array<ClinicsForPromotion | null >,
  nextToken?: string | null,
};

export type ModelClinicFilterInput = {
  id?: ModelIDInput | null,
  clinicName?: ModelStringInput | null,
  doctorName?: ModelStringInput | null,
  doctorNumber?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  fax?: ModelStringInput | null,
  medicalInstitutionNumber?: ModelStringInput | null,
  prescriptionValidityPeriod?: ModelIntInput | null,
  address?: ModelStringInput | null,
  feedbackDate?: ModelIntInput | null,
  isDeleted?: ModelBooleanInput | null,
  stepPayID?: ModelStringInput | null,
  numberOfPatients?: ModelIntInput | null,
  numberOfDoctors?: ModelIntInput | null,
  numberOfSteps?: ModelIntInput | null,
  clinicEmail?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  planInfo?: ModelStringInput | null,
  planCheckPassFlag?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  typename?: ModelStringInput | null,
  config?: ModelStringInput | null,
  businessType?: ModelBusinessTypeInput | null,
  promotion?: ModelStringInput | null,
  and?: Array< ModelClinicFilterInput | null > | null,
  or?: Array< ModelClinicFilterInput | null > | null,
  not?: ModelClinicFilterInput | null,
};

export type ModelClinicConnection = {
  __typename: "ModelClinicConnection",
  items:  Array<Clinic | null >,
  nextToken?: string | null,
};

export type UsageStatistics = {
  __typename: "UsageStatistics",
  clinicID: string,
  recordDate: string,
  numberOfTreatmentCompletion?: number | null,
  numberOfTreatmentCompletionsWithPrescription?: number | null,
  numberOfKakaoSent?: number | null,
  numberOfSurveySentBySelf?: number | null,
  numberOfPlanChange?: number | null,
  numberOfTreatment?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUsageStatisticsFilterInput = {
  clinicID?: ModelIDInput | null,
  recordDate?: ModelStringInput | null,
  numberOfTreatmentCompletion?: ModelIntInput | null,
  numberOfTreatmentCompletionsWithPrescription?: ModelIntInput | null,
  numberOfKakaoSent?: ModelIntInput | null,
  numberOfSurveySentBySelf?: ModelIntInput | null,
  numberOfPlanChange?: ModelIntInput | null,
  numberOfTreatment?: ModelIntInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUsageStatisticsFilterInput | null > | null,
  or?: Array< ModelUsageStatisticsFilterInput | null > | null,
  not?: ModelUsageStatisticsFilterInput | null,
};

export type ModelUsageStatisticsConnection = {
  __typename: "ModelUsageStatisticsConnection",
  items:  Array<UsageStatistics | null >,
  nextToken?: string | null,
};

export type DailyUsageStatistics = {
  __typename: "DailyUsageStatistics",
  date: string,
  treatmentStartCount?: number | null,
  treatmentCompletionWithoutPrescriptionCount?: number | null,
  treatmentCompletionWithPrescriptionCount?: number | null,
  surveyCount?: number | null,
  surveySentBySelfCount?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelDailyUsageStatisticsFilterInput = {
  date?: ModelStringInput | null,
  treatmentStartCount?: ModelIntInput | null,
  treatmentCompletionWithoutPrescriptionCount?: ModelIntInput | null,
  treatmentCompletionWithPrescriptionCount?: ModelIntInput | null,
  surveyCount?: ModelIntInput | null,
  surveySentBySelfCount?: ModelIntInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDailyUsageStatisticsFilterInput | null > | null,
  or?: Array< ModelDailyUsageStatisticsFilterInput | null > | null,
  not?: ModelDailyUsageStatisticsFilterInput | null,
};

export type ModelDailyUsageStatisticsConnection = {
  __typename: "ModelDailyUsageStatisticsConnection",
  items:  Array<DailyUsageStatistics | null >,
  nextToken?: string | null,
};

export type ModelTreatmentFilterInput = {
  id?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  surveyID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  save?: ModelIntInput | null,
  feedbackID?: ModelIDInput | null,
  mainSymptom?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  cure?: ModelStringInput | null,
  memo?: ModelStringInput | null,
  lastVisitedAt?: ModelStringInput | null,
  symptomCheck?: ModelStringInput | null,
  name?: ModelStringInput | null,
  birth?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  prescriptionStatus?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  isVisited?: ModelBooleanInput | null,
  isDeleted?: ModelBooleanInput | null,
  surveyCheck?: ModelStringInput | null,
  herbsForRecommanded?: ModelStringInput | null,
  treatmentedAt?: ModelStringInput | null,
  treatmentStatus?: ModelTreatmentStatusInput | null,
  patientNameForSearching?: ModelStringInput | null,
  selectedBCode?: ModelStringInput | null,
  prescriptionMode?: ModelStringInput | null,
  prescriptionId?: ModelIDInput | null,
  customHerbalFormulaId?: ModelIDInput | null,
  prescriptionDocument?: ModelStringInput | null,
  footprintsLog?: ModelStringInput | null,
  and?: Array< ModelTreatmentFilterInput | null > | null,
  or?: Array< ModelTreatmentFilterInput | null > | null,
  not?: ModelTreatmentFilterInput | null,
};

export type ModelTreatmentConnection = {
  __typename: "ModelTreatmentConnection",
  items:  Array<Treatment | null >,
  nextToken?: string | null,
};

export type ModelFormulaFilterInput = {
  id?: ModelIDInput | null,
  formulaName?: ModelStringInput | null,
  chineseName?: ModelStringInput | null,
  herbs?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  primaryCare?: ModelStringInput | null,
  tag?: ModelStringInput | null,
  category?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  gun?: ModelStringInput | null,
  sin?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFormulaFilterInput | null > | null,
  or?: Array< ModelFormulaFilterInput | null > | null,
  not?: ModelFormulaFilterInput | null,
};

export type ModelFormulaConnection = {
  __typename: "ModelFormulaConnection",
  items:  Array<Formula | null >,
  nextToken?: string | null,
};

export type ModelBiztalkHistoryFilterInput = {
  msgIdx?: ModelIDInput | null,
  type?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  and?: Array< ModelBiztalkHistoryFilterInput | null > | null,
  or?: Array< ModelBiztalkHistoryFilterInput | null > | null,
  not?: ModelBiztalkHistoryFilterInput | null,
};

export type ModelBiztalkHistoryConnection = {
  __typename: "ModelBiztalkHistoryConnection",
  items:  Array<BiztalkHistory | null >,
  nextToken?: string | null,
};

export type ModelYejinSignupRequestFilterInput = {
  id?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  clinicName?: ModelStringInput | null,
  clinicAddress?: ModelStringInput | null,
  clinicPhone?: ModelStringInput | null,
  terms?: ModelBooleanInput | null,
  termsVersion?: ModelStringInput | null,
  termsAgreedAt?: ModelStringInput | null,
  permission?: ModelStringInput | null,
  licenseNumber?: ModelStringInput | null,
  clinicEmail?: ModelStringInput | null,
  businessType?: ModelBusinessTypeInput | null,
  createdAt?: ModelStringInput | null,
  approvedAt?: ModelStringInput | null,
  requestStatus?: ModelSignupRequestStatusInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelYejinSignupRequestFilterInput | null > | null,
  or?: Array< ModelYejinSignupRequestFilterInput | null > | null,
  not?: ModelYejinSignupRequestFilterInput | null,
};

export type ModelYejinSignupRequestConnection = {
  __typename: "ModelYejinSignupRequestConnection",
  items:  Array<YejinSignupRequest | null >,
  nextToken?: string | null,
};

export type ModelReportFilterInput = {
  treatmentID?: ModelIDInput | null,
  sendCount?: ModelIntInput | null,
  byeonjeung?: ModelStringInput | null,
  startedAt?: ModelStringInput | null,
  birth?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
};

export type ModelSelfRegistrationQRFilterInput = {
  QRID?: ModelIDInput | null,
  clinicID?: ModelIDInput | null,
  expiredDate?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSelfRegistrationQRFilterInput | null > | null,
  or?: Array< ModelSelfRegistrationQRFilterInput | null > | null,
  not?: ModelSelfRegistrationQRFilterInput | null,
};

export type ModelSelfRegistrationQRConnection = {
  __typename: "ModelSelfRegistrationQRConnection",
  items:  Array<SelfRegistrationQR | null >,
  nextToken?: string | null,
};

export type ModelDailySignupCountFilterInput = {
  date?: ModelStringInput | null,
  count?: ModelIntInput | null,
  memo?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDailySignupCountFilterInput | null > | null,
  or?: Array< ModelDailySignupCountFilterInput | null > | null,
  not?: ModelDailySignupCountFilterInput | null,
};

export type ModelDailySignupCountConnection = {
  __typename: "ModelDailySignupCountConnection",
  items:  Array<DailySignupCount | null >,
  nextToken?: string | null,
};

export type ModelDailyPatientCountFilterInput = {
  date?: ModelStringInput | null,
  count?: ModelIntInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDailyPatientCountFilterInput | null > | null,
  or?: Array< ModelDailyPatientCountFilterInput | null > | null,
  not?: ModelDailyPatientCountFilterInput | null,
};

export type ModelDailyPatientCountConnection = {
  __typename: "ModelDailyPatientCountConnection",
  items:  Array<DailyPatientCount | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionHerbFilterInput = {
  code?: ModelSubscriptionIDInput | null,
  herbName?: ModelSubscriptionStringInput | null,
  nameForSearching?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionHerbFilterInput | null > | null,
  or?: Array< ModelSubscriptionHerbFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionCustomHerbalFormulaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ownerUserID?: ModelSubscriptionIDInput | null,
  originalHerbalFormulaID?: ModelSubscriptionIDInput | null,
  insrCCode?: ModelSubscriptionStringInput | null,
  insrDsCode?: ModelSubscriptionStringInput | null,
  InsrPrscrType?: ModelSubscriptionIntInput | null,
  kn?: ModelSubscriptionStringInput | null,
  herbCodes?: ModelSubscriptionStringInput | null,
  processingCodes?: ModelSubscriptionStringInput | null,
  scoringType?: ModelSubscriptionIntInput | null,
  memo?: ModelSubscriptionStringInput | null,
  isDeleted?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomHerbalFormulaFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomHerbalFormulaFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionHerbalFormulaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  category?: ModelSubscriptionStringInput | null,
  insrCCode?: ModelSubscriptionStringInput | null,
  insrDsCode?: ModelSubscriptionStringInput | null,
  InsrPrscrType?: ModelSubscriptionIntInput | null,
  kn?: ModelSubscriptionStringInput | null,
  cn?: ModelSubscriptionStringInput | null,
  herbCodes?: ModelSubscriptionStringInput | null,
  processingCodes?: ModelSubscriptionStringInput | null,
  gun?: ModelSubscriptionStringInput | null,
  sin?: ModelSubscriptionStringInput | null,
  scoringType?: ModelSubscriptionIntInput | null,
  source?: ModelSubscriptionStringInput | null,
  postfix?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHerbalFormulaFilterInput | null > | null,
  or?: Array< ModelSubscriptionHerbalFormulaFilterInput | null > | null,
};

export type ModelSubscriptionSurveyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  otp?: ModelSubscriptionStringInput | null,
  patientID?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  link?: ModelSubscriptionStringInput | null,
  surveyResult?: ModelSubscriptionStringInput | null,
  mainSymptom?: ModelSubscriptionStringInput | null,
  symptoms?: ModelSubscriptionStringInput | null,
  visitCount?: ModelSubscriptionIntInput | null,
  firstLinkClickDt?: ModelSubscriptionIntInput | null,
  surveyedAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  sendCount?: ModelSubscriptionIntInput | null,
  isSend?: ModelSubscriptionBooleanInput | null,
  treatmentID?: ModelSubscriptionIDInput | null,
  feedbacks?: ModelSubscriptionStringInput | null,
  feedbackResult?: ModelSubscriptionStringInput | null,
  feedbackAt?: ModelSubscriptionStringInput | null,
  feedbackLink?: ModelSubscriptionStringInput | null,
  feedbackDone?: ModelSubscriptionBooleanInput | null,
  isFeedback?: ModelSubscriptionBooleanInput | null,
  additionalSymptoms?: ModelSubscriptionStringInput | null,
  feedbackSendTime?: ModelSubscriptionStringInput | null,
  feedbackSchedule_GSI_PK?: ModelSubscriptionStringInput | null,
  feedbackScheduleStatus?: ModelSubscriptionIntInput | null,
  surveyDone?: ModelSubscriptionBooleanInput | null,
  surveyGrade?: ModelSubscriptionIntInput | null,
  surveySatisfaction?: ModelSubscriptionStringInput | null,
  feedbackGrade?: ModelSubscriptionIntInput | null,
  feedbackSatisfaction?: ModelSubscriptionStringInput | null,
  surveyStatus?: ModelSubscriptionStringInput | null,
  expiredAt?: ModelSubscriptionStringInput | null,
  mainSymptomCategory?: ModelSubscriptionStringInput | null,
  sender?: ModelSubscriptionStringInput | null,
  surveyType?: ModelSubscriptionStringInput | null,
  insuranceType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSurveyFilterInput | null > | null,
  or?: Array< ModelSubscriptionSurveyFilterInput | null > | null,
  surveySurveyBizTalkHistoryId?: ModelSubscriptionIDInput | null,
  surveyFeedbackBizTalkHistoryId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionHistoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  patientID?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  typeID?: ModelSubscriptionIDInput | null,
  messageCode?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionHistoryFilterInput | null > | null,
};

export type ModelSubscriptionPatientFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  patientName?: ModelSubscriptionStringInput | null,
  birth?: ModelSubscriptionStringInput | null,
  residentRegistrationNumber?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  recentActivity?: ModelSubscriptionStringInput | null,
  recentActivityMessage?: ModelSubscriptionStringInput | null,
  recentActivityCode?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  prescriptionStatus?: ModelSubscriptionIntInput | null,
  isWaiting?: ModelSubscriptionBooleanInput | null,
  isVisited?: ModelSubscriptionBooleanInput | null,
  symptomID?: ModelSubscriptionIDInput | null,
  mainSymptom?: ModelSubscriptionStringInput | null,
  prescriptionID?: ModelSubscriptionIDInput | null,
  surveyID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  treatmentID?: ModelSubscriptionIDInput | null,
  patientNameForSorting?: ModelSubscriptionStringInput | null,
  surveyedAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  treatmentStatus?: ModelSubscriptionIntInput | null,
  treatmentStatusDateForSorting?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  registrationPath?: ModelSubscriptionStringInput | null,
  isDeleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPatientFilterInput | null > | null,
  or?: Array< ModelSubscriptionPatientFilterInput | null > | null,
};

export type ModelSubscriptionPatientDeletedFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  patientName?: ModelSubscriptionStringInput | null,
  birth?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  residentRegistrationNumber?: ModelSubscriptionStringInput | null,
  recentActivity?: ModelSubscriptionStringInput | null,
  recentActivityMessage?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  prescriptionStatus?: ModelSubscriptionIntInput | null,
  isWaiting?: ModelSubscriptionBooleanInput | null,
  isVisited?: ModelSubscriptionBooleanInput | null,
  symptomID?: ModelSubscriptionIDInput | null,
  mainSymptom?: ModelSubscriptionStringInput | null,
  prescriptionID?: ModelSubscriptionIDInput | null,
  surveyID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  treatmentID?: ModelSubscriptionIDInput | null,
  patientNameForSorting?: ModelSubscriptionStringInput | null,
  surveyedAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  treatmentStatus?: ModelSubscriptionIntInput | null,
  treatmentStatusDateForSorting?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  privacyTerms?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPatientDeletedFilterInput | null > | null,
  or?: Array< ModelSubscriptionPatientDeletedFilterInput | null > | null,
};

export type ModelSubscriptionUserRoleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  config?: ModelSubscriptionStringInput | null,
  clinicName?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  roleName?: ModelSubscriptionStringInput | null,
  userName?: ModelSubscriptionStringInput | null,
  isDeleted?: ModelSubscriptionBooleanInput | null,
  testFlag?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserRoleFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserRoleFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  terms?: ModelSubscriptionBooleanInput | null,
  termsVersion?: ModelSubscriptionStringInput | null,
  termsAgreedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  isDeleted?: ModelSubscriptionBooleanInput | null,
  roleName?: ModelSubscriptionStringInput | null,
  config?: ModelSubscriptionStringInput | null,
  licenseNumber?: ModelSubscriptionStringInput | null,
  licensePendingReviewFlag?: ModelSubscriptionBooleanInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionUserPoolFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserPoolFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserPoolFilterInput | null > | null,
};

export type ModelSubscriptionRoleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  roleName?: ModelSubscriptionStringInput | null,
  permisstions?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRoleFilterInput | null > | null,
  or?: Array< ModelSubscriptionRoleFilterInput | null > | null,
};

export type ModelSubscriptionClinicsForPromotionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  promotionType?: ModelSubscriptionStringInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClinicsForPromotionFilterInput | null > | null,
  or?: Array< ModelSubscriptionClinicsForPromotionFilterInput | null > | null,
};

export type ModelSubscriptionClinicFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicName?: ModelSubscriptionStringInput | null,
  doctorName?: ModelSubscriptionStringInput | null,
  doctorNumber?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  fax?: ModelSubscriptionStringInput | null,
  medicalInstitutionNumber?: ModelSubscriptionStringInput | null,
  prescriptionValidityPeriod?: ModelSubscriptionIntInput | null,
  address?: ModelSubscriptionStringInput | null,
  feedbackDate?: ModelSubscriptionIntInput | null,
  isDeleted?: ModelSubscriptionBooleanInput | null,
  stepPayID?: ModelSubscriptionStringInput | null,
  numberOfPatients?: ModelSubscriptionIntInput | null,
  numberOfDoctors?: ModelSubscriptionIntInput | null,
  numberOfSteps?: ModelSubscriptionIntInput | null,
  clinicEmail?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  planInfo?: ModelSubscriptionStringInput | null,
  planCheckPassFlag?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  typename?: ModelSubscriptionStringInput | null,
  config?: ModelSubscriptionStringInput | null,
  businessType?: ModelSubscriptionStringInput | null,
  promotion?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClinicFilterInput | null > | null,
  or?: Array< ModelSubscriptionClinicFilterInput | null > | null,
};

export type ModelSubscriptionTreatmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  surveyID?: ModelSubscriptionIDInput | null,
  patientID?: ModelSubscriptionIDInput | null,
  save?: ModelSubscriptionIntInput | null,
  feedbackID?: ModelSubscriptionIDInput | null,
  mainSymptom?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  cure?: ModelSubscriptionStringInput | null,
  memo?: ModelSubscriptionStringInput | null,
  lastVisitedAt?: ModelSubscriptionStringInput | null,
  symptomCheck?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  birth?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  prescriptionStatus?: ModelSubscriptionIntInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionIntInput | null,
  isVisited?: ModelSubscriptionBooleanInput | null,
  isDeleted?: ModelSubscriptionBooleanInput | null,
  surveyCheck?: ModelSubscriptionStringInput | null,
  herbsForRecommanded?: ModelSubscriptionStringInput | null,
  treatmentedAt?: ModelSubscriptionStringInput | null,
  treatmentStatus?: ModelSubscriptionStringInput | null,
  patientNameForSearching?: ModelSubscriptionStringInput | null,
  selectedBCode?: ModelSubscriptionStringInput | null,
  prescriptionMode?: ModelSubscriptionStringInput | null,
  prescriptionId?: ModelSubscriptionIDInput | null,
  customHerbalFormulaId?: ModelSubscriptionIDInput | null,
  prescriptionDocument?: ModelSubscriptionStringInput | null,
  footprintsLog?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTreatmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionTreatmentFilterInput | null > | null,
};

export type ModelSubscriptionFormulaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  formulaName?: ModelSubscriptionStringInput | null,
  chineseName?: ModelSubscriptionStringInput | null,
  herbs?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionIntInput | null,
  primaryCare?: ModelSubscriptionStringInput | null,
  tag?: ModelSubscriptionStringInput | null,
  category?: ModelSubscriptionStringInput | null,
  priority?: ModelSubscriptionIntInput | null,
  gun?: ModelSubscriptionStringInput | null,
  sin?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFormulaFilterInput | null > | null,
  or?: Array< ModelSubscriptionFormulaFilterInput | null > | null,
};

export type ModelSubscriptionBiztalkHistoryFilterInput = {
  msgIdx?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionBiztalkHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionBiztalkHistoryFilterInput | null > | null,
};

export type ModelSubscriptionYejinSignupRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  clinicName?: ModelSubscriptionStringInput | null,
  clinicAddress?: ModelSubscriptionStringInput | null,
  clinicPhone?: ModelSubscriptionStringInput | null,
  terms?: ModelSubscriptionBooleanInput | null,
  termsVersion?: ModelSubscriptionStringInput | null,
  termsAgreedAt?: ModelSubscriptionStringInput | null,
  permission?: ModelSubscriptionStringInput | null,
  licenseNumber?: ModelSubscriptionStringInput | null,
  clinicEmail?: ModelSubscriptionStringInput | null,
  businessType?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  approvedAt?: ModelSubscriptionStringInput | null,
  requestStatus?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionYejinSignupRequestFilterInput | null > | null,
  or?: Array< ModelSubscriptionYejinSignupRequestFilterInput | null > | null,
};

export type ModelSubscriptionReportFilterInput = {
  treatmentID?: ModelSubscriptionIDInput | null,
  sendCount?: ModelSubscriptionIntInput | null,
  byeonjeung?: ModelSubscriptionStringInput | null,
  startedAt?: ModelSubscriptionStringInput | null,
  birth?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReportFilterInput | null > | null,
  or?: Array< ModelSubscriptionReportFilterInput | null > | null,
};

export type ModelSubscriptionSelfRegistrationQRFilterInput = {
  QRID?: ModelSubscriptionIDInput | null,
  clinicID?: ModelSubscriptionIDInput | null,
  expiredDate?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSelfRegistrationQRFilterInput | null > | null,
  or?: Array< ModelSubscriptionSelfRegistrationQRFilterInput | null > | null,
};

export type ModelSubscriptionDailySignupCountFilterInput = {
  date?: ModelSubscriptionStringInput | null,
  count?: ModelSubscriptionIntInput | null,
  memo?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDailySignupCountFilterInput | null > | null,
  or?: Array< ModelSubscriptionDailySignupCountFilterInput | null > | null,
};

export type ModelSubscriptionDailyPatientCountFilterInput = {
  date?: ModelSubscriptionStringInput | null,
  count?: ModelSubscriptionIntInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDailyPatientCountFilterInput | null > | null,
  or?: Array< ModelSubscriptionDailyPatientCountFilterInput | null > | null,
};

export type GetReportCustomQueryVariables = {
  treatmentID: string,
};

export type GetReportCustomQuery = {
  getReport?:  {
    __typename: "Report",
    treatmentID: string,
    byeonjeung?: string | null,
  } | null,
};

export type CreateHerbMutationVariables = {
  input: CreateHerbInput,
  condition?: ModelHerbConditionInput | null,
};

export type CreateHerbMutation = {
  createHerb?:  {
    __typename: "Herb",
    code: string,
    herbName: string,
    nameForSearching?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateHerbMutationVariables = {
  input: UpdateHerbInput,
  condition?: ModelHerbConditionInput | null,
};

export type UpdateHerbMutation = {
  updateHerb?:  {
    __typename: "Herb",
    code: string,
    herbName: string,
    nameForSearching?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteHerbMutationVariables = {
  input: DeleteHerbInput,
  condition?: ModelHerbConditionInput | null,
};

export type DeleteHerbMutation = {
  deleteHerb?:  {
    __typename: "Herb",
    code: string,
    herbName: string,
    nameForSearching?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateCustomHerbalFormulaMutationVariables = {
  input: CreateCustomHerbalFormulaInput,
  condition?: ModelCustomHerbalFormulaConditionInput | null,
};

export type CreateCustomHerbalFormulaMutation = {
  createCustomHerbalFormula?:  {
    __typename: "CustomHerbalFormula",
    id: string,
    ownerUserID: string,
    originalHerbalFormulaID?: string | null,
    originalHerbalFormulaData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    scoringType?: number | null,
    memo?: string | null,
    isDeleted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateCustomHerbalFormulaMutationVariables = {
  input: UpdateCustomHerbalFormulaInput,
  condition?: ModelCustomHerbalFormulaConditionInput | null,
};

export type UpdateCustomHerbalFormulaMutation = {
  updateCustomHerbalFormula?:  {
    __typename: "CustomHerbalFormula",
    id: string,
    ownerUserID: string,
    originalHerbalFormulaID?: string | null,
    originalHerbalFormulaData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    scoringType?: number | null,
    memo?: string | null,
    isDeleted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteCustomHerbalFormulaMutationVariables = {
  input: DeleteCustomHerbalFormulaInput,
  condition?: ModelCustomHerbalFormulaConditionInput | null,
};

export type DeleteCustomHerbalFormulaMutation = {
  deleteCustomHerbalFormula?:  {
    __typename: "CustomHerbalFormula",
    id: string,
    ownerUserID: string,
    originalHerbalFormulaID?: string | null,
    originalHerbalFormulaData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    scoringType?: number | null,
    memo?: string | null,
    isDeleted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateHerbalFormulaMutationVariables = {
  input: CreateHerbalFormulaInput,
  condition?: ModelHerbalFormulaConditionInput | null,
};

export type CreateHerbalFormulaMutation = {
  createHerbalFormula?:  {
    __typename: "HerbalFormula",
    id: string,
    category?: string | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    cn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    scoringType?: number | null,
    source?: string | null,
    postfix?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHerbalFormulaMutationVariables = {
  input: UpdateHerbalFormulaInput,
  condition?: ModelHerbalFormulaConditionInput | null,
};

export type UpdateHerbalFormulaMutation = {
  updateHerbalFormula?:  {
    __typename: "HerbalFormula",
    id: string,
    category?: string | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    cn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    scoringType?: number | null,
    source?: string | null,
    postfix?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHerbalFormulaMutationVariables = {
  input: DeleteHerbalFormulaInput,
  condition?: ModelHerbalFormulaConditionInput | null,
};

export type DeleteHerbalFormulaMutation = {
  deleteHerbalFormula?:  {
    __typename: "HerbalFormula",
    id: string,
    category?: string | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    cn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    scoringType?: number | null,
    source?: string | null,
    postfix?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSurveyMutationVariables = {
  input: CreateSurveyInput,
  condition?: ModelSurveyConditionInput | null,
};

export type CreateSurveyMutation = {
  createSurvey?:  {
    __typename: "Survey",
    id: string,
    otp: string,
    patientID: string,
    clinicID: string,
    link?: string | null,
    surveyResult?: string | null,
    mainSymptom?: string | null,
    symptoms?: string | null,
    visitCount?: number | null,
    firstLinkClickDt?: number | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    sendCount?: number | null,
    isSend?: boolean | null,
    treatmentID?: string | null,
    feedbacks?: string | null,
    feedbackResult?: string | null,
    feedbackAt?: string | null,
    feedbackLink?: string | null,
    feedbackDone?: boolean | null,
    isFeedback?: boolean | null,
    additionalSymptoms?: string | null,
    feedbackSendTime?: string | null,
    feedbackSchedule_GSI_PK?: string | null,
    feedbackScheduleStatus?: number | null,
    surveyDone?: boolean | null,
    surveyGrade?: number | null,
    surveySatisfaction?: string | null,
    feedbackGrade?: number | null,
    feedbackSatisfaction?: string | null,
    surveyStatus?: SurveyStatus | null,
    expiredAt?: string | null,
    surveyBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    feedbackBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    mainSymptomCategory?: string | null,
    sender?: string | null,
    surveyType?: SurveyType | null,
    insuranceType?: string | null,
    surveySurveyBizTalkHistoryId?: string | null,
    surveyFeedbackBizTalkHistoryId?: string | null,
  } | null,
};

export type UpdateSurveyMutationVariables = {
  input: UpdateSurveyInput,
  condition?: ModelSurveyConditionInput | null,
};

export type UpdateSurveyMutation = {
  updateSurvey?:  {
    __typename: "Survey",
    id: string,
    otp: string,
    patientID: string,
    clinicID: string,
    link?: string | null,
    surveyResult?: string | null,
    mainSymptom?: string | null,
    symptoms?: string | null,
    visitCount?: number | null,
    firstLinkClickDt?: number | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    sendCount?: number | null,
    isSend?: boolean | null,
    treatmentID?: string | null,
    feedbacks?: string | null,
    feedbackResult?: string | null,
    feedbackAt?: string | null,
    feedbackLink?: string | null,
    feedbackDone?: boolean | null,
    isFeedback?: boolean | null,
    additionalSymptoms?: string | null,
    feedbackSendTime?: string | null,
    feedbackSchedule_GSI_PK?: string | null,
    feedbackScheduleStatus?: number | null,
    surveyDone?: boolean | null,
    surveyGrade?: number | null,
    surveySatisfaction?: string | null,
    feedbackGrade?: number | null,
    feedbackSatisfaction?: string | null,
    surveyStatus?: SurveyStatus | null,
    expiredAt?: string | null,
    surveyBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    feedbackBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    mainSymptomCategory?: string | null,
    sender?: string | null,
    surveyType?: SurveyType | null,
    insuranceType?: string | null,
    surveySurveyBizTalkHistoryId?: string | null,
    surveyFeedbackBizTalkHistoryId?: string | null,
  } | null,
};

export type DeleteSurveyMutationVariables = {
  input: DeleteSurveyInput,
  condition?: ModelSurveyConditionInput | null,
};

export type DeleteSurveyMutation = {
  deleteSurvey?:  {
    __typename: "Survey",
    id: string,
    otp: string,
    patientID: string,
    clinicID: string,
    link?: string | null,
    surveyResult?: string | null,
    mainSymptom?: string | null,
    symptoms?: string | null,
    visitCount?: number | null,
    firstLinkClickDt?: number | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    sendCount?: number | null,
    isSend?: boolean | null,
    treatmentID?: string | null,
    feedbacks?: string | null,
    feedbackResult?: string | null,
    feedbackAt?: string | null,
    feedbackLink?: string | null,
    feedbackDone?: boolean | null,
    isFeedback?: boolean | null,
    additionalSymptoms?: string | null,
    feedbackSendTime?: string | null,
    feedbackSchedule_GSI_PK?: string | null,
    feedbackScheduleStatus?: number | null,
    surveyDone?: boolean | null,
    surveyGrade?: number | null,
    surveySatisfaction?: string | null,
    feedbackGrade?: number | null,
    feedbackSatisfaction?: string | null,
    surveyStatus?: SurveyStatus | null,
    expiredAt?: string | null,
    surveyBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    feedbackBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    mainSymptomCategory?: string | null,
    sender?: string | null,
    surveyType?: SurveyType | null,
    insuranceType?: string | null,
    surveySurveyBizTalkHistoryId?: string | null,
    surveyFeedbackBizTalkHistoryId?: string | null,
  } | null,
};

export type CreateHistoryMutationVariables = {
  input: CreateHistoryInput,
  condition?: ModelHistoryConditionInput | null,
};

export type CreateHistoryMutation = {
  createHistory?:  {
    __typename: "History",
    id: string,
    clinicID: string,
    patientID: string,
    type?: string | null,
    typeID?: string | null,
    messageCode?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateHistoryMutationVariables = {
  input: UpdateHistoryInput,
  condition?: ModelHistoryConditionInput | null,
};

export type UpdateHistoryMutation = {
  updateHistory?:  {
    __typename: "History",
    id: string,
    clinicID: string,
    patientID: string,
    type?: string | null,
    typeID?: string | null,
    messageCode?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteHistoryMutationVariables = {
  input: DeleteHistoryInput,
  condition?: ModelHistoryConditionInput | null,
};

export type DeleteHistoryMutation = {
  deleteHistory?:  {
    __typename: "History",
    id: string,
    clinicID: string,
    patientID: string,
    type?: string | null,
    typeID?: string | null,
    messageCode?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type CreatePatientMutationVariables = {
  input: CreatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type CreatePatientMutation = {
  createPatient?:  {
    __typename: "Patient",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    residentRegistrationNumber?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    recentActivityCode?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    registrationPath?: string | null,
    isDeleted?: boolean | null,
  } | null,
};

export type UpdatePatientMutationVariables = {
  input: UpdatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type UpdatePatientMutation = {
  updatePatient?:  {
    __typename: "Patient",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    residentRegistrationNumber?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    recentActivityCode?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    registrationPath?: string | null,
    isDeleted?: boolean | null,
  } | null,
};

export type DeletePatientMutationVariables = {
  input: DeletePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type DeletePatientMutation = {
  deletePatient?:  {
    __typename: "Patient",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    residentRegistrationNumber?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    recentActivityCode?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    registrationPath?: string | null,
    isDeleted?: boolean | null,
  } | null,
};

export type CreatePatientDeletedMutationVariables = {
  input: CreatePatientDeletedInput,
  condition?: ModelPatientDeletedConditionInput | null,
};

export type CreatePatientDeletedMutation = {
  createPatientDeleted?:  {
    __typename: "PatientDeleted",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    residentRegistrationNumber?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    type: string,
    privacyTerms?: string | null,
  } | null,
};

export type UpdatePatientDeletedMutationVariables = {
  input: UpdatePatientDeletedInput,
  condition?: ModelPatientDeletedConditionInput | null,
};

export type UpdatePatientDeletedMutation = {
  updatePatientDeleted?:  {
    __typename: "PatientDeleted",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    residentRegistrationNumber?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    type: string,
    privacyTerms?: string | null,
  } | null,
};

export type DeletePatientDeletedMutationVariables = {
  input: DeletePatientDeletedInput,
  condition?: ModelPatientDeletedConditionInput | null,
};

export type DeletePatientDeletedMutation = {
  deletePatientDeleted?:  {
    __typename: "PatientDeleted",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    residentRegistrationNumber?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    type: string,
    privacyTerms?: string | null,
  } | null,
};

export type CreateUserRoleMutationVariables = {
  input: CreateUserRoleInput,
  condition?: ModelUserRoleConditionInput | null,
};

export type CreateUserRoleMutation = {
  createUserRole?:  {
    __typename: "UserRole",
    id: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    config?: string | null,
    clinicName?: string | null,
    status?: string | null,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      username?: string | null,
      phone?: string | null,
      terms?: Array< boolean | null > | null,
      termsVersion?: string | null,
      termsAgreedAt?: string | null,
      createdAt?: string | null,
      isDeleted?: boolean | null,
      roleName?: string | null,
      config?: string | null,
      licenseNumber?: string | null,
      licensePendingReviewFlag?: boolean | null,
      updatedAt: string,
    } | null,
    roleName: string,
    userName: string,
    isDeleted?: boolean | null,
    testFlag?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserRoleMutationVariables = {
  input: UpdateUserRoleInput,
  condition?: ModelUserRoleConditionInput | null,
};

export type UpdateUserRoleMutation = {
  updateUserRole?:  {
    __typename: "UserRole",
    id: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    config?: string | null,
    clinicName?: string | null,
    status?: string | null,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      username?: string | null,
      phone?: string | null,
      terms?: Array< boolean | null > | null,
      termsVersion?: string | null,
      termsAgreedAt?: string | null,
      createdAt?: string | null,
      isDeleted?: boolean | null,
      roleName?: string | null,
      config?: string | null,
      licenseNumber?: string | null,
      licensePendingReviewFlag?: boolean | null,
      updatedAt: string,
    } | null,
    roleName: string,
    userName: string,
    isDeleted?: boolean | null,
    testFlag?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserRoleMutationVariables = {
  input: DeleteUserRoleInput,
  condition?: ModelUserRoleConditionInput | null,
};

export type DeleteUserRoleMutation = {
  deleteUserRole?:  {
    __typename: "UserRole",
    id: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    config?: string | null,
    clinicName?: string | null,
    status?: string | null,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      username?: string | null,
      phone?: string | null,
      terms?: Array< boolean | null > | null,
      termsVersion?: string | null,
      termsAgreedAt?: string | null,
      createdAt?: string | null,
      isDeleted?: boolean | null,
      roleName?: string | null,
      config?: string | null,
      licenseNumber?: string | null,
      licensePendingReviewFlag?: boolean | null,
      updatedAt: string,
    } | null,
    roleName: string,
    userName: string,
    isDeleted?: boolean | null,
    testFlag?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    username?: string | null,
    phone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    createdAt?: string | null,
    isDeleted?: boolean | null,
    roleName?: string | null,
    config?: string | null,
    licenseNumber?: string | null,
    licensePendingReviewFlag?: boolean | null,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    username?: string | null,
    phone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    createdAt?: string | null,
    isDeleted?: boolean | null,
    roleName?: string | null,
    config?: string | null,
    licenseNumber?: string | null,
    licensePendingReviewFlag?: boolean | null,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    username?: string | null,
    phone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    createdAt?: string | null,
    isDeleted?: boolean | null,
    roleName?: string | null,
    config?: string | null,
    licenseNumber?: string | null,
    licensePendingReviewFlag?: boolean | null,
    updatedAt: string,
  } | null,
};

export type CreateUserPoolMutationVariables = {
  input: CreateUserPoolInput,
  condition?: ModelUserPoolConditionInput | null,
};

export type CreateUserPoolMutation = {
  createUserPool?:  {
    __typename: "UserPool",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserPoolMutationVariables = {
  input: UpdateUserPoolInput,
  condition?: ModelUserPoolConditionInput | null,
};

export type UpdateUserPoolMutation = {
  updateUserPool?:  {
    __typename: "UserPool",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserPoolMutationVariables = {
  input: DeleteUserPoolInput,
  condition?: ModelUserPoolConditionInput | null,
};

export type DeleteUserPoolMutation = {
  deleteUserPool?:  {
    __typename: "UserPool",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRoleMutationVariables = {
  input: CreateRoleInput,
  condition?: ModelRoleConditionInput | null,
};

export type CreateRoleMutation = {
  createRole?:  {
    __typename: "Role",
    id: string,
    clinicID: string,
    roleName?: string | null,
    permisstions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRoleMutationVariables = {
  input: UpdateRoleInput,
  condition?: ModelRoleConditionInput | null,
};

export type UpdateRoleMutation = {
  updateRole?:  {
    __typename: "Role",
    id: string,
    clinicID: string,
    roleName?: string | null,
    permisstions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRoleMutationVariables = {
  input: DeleteRoleInput,
  condition?: ModelRoleConditionInput | null,
};

export type DeleteRoleMutation = {
  deleteRole?:  {
    __typename: "Role",
    id: string,
    clinicID: string,
    roleName?: string | null,
    permisstions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClinicsForPromotionMutationVariables = {
  input: CreateClinicsForPromotionInput,
  condition?: ModelClinicsForPromotionConditionInput | null,
};

export type CreateClinicsForPromotionMutation = {
  createClinicsForPromotion?:  {
    __typename: "ClinicsForPromotion",
    id: string,
    promotionType?: string | null,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClinicsForPromotionMutationVariables = {
  input: UpdateClinicsForPromotionInput,
  condition?: ModelClinicsForPromotionConditionInput | null,
};

export type UpdateClinicsForPromotionMutation = {
  updateClinicsForPromotion?:  {
    __typename: "ClinicsForPromotion",
    id: string,
    promotionType?: string | null,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClinicsForPromotionMutationVariables = {
  input: DeleteClinicsForPromotionInput,
  condition?: ModelClinicsForPromotionConditionInput | null,
};

export type DeleteClinicsForPromotionMutation = {
  deleteClinicsForPromotion?:  {
    __typename: "ClinicsForPromotion",
    id: string,
    promotionType?: string | null,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClinicMutationVariables = {
  input: CreateClinicInput,
  condition?: ModelClinicConditionInput | null,
};

export type CreateClinicMutation = {
  createClinic?:  {
    __typename: "Clinic",
    id: string,
    clinicName?: string | null,
    doctorName?: string | null,
    doctorNumber?: string | null,
    phone?: string | null,
    fax?: string | null,
    medicalInstitutionNumber?: string | null,
    prescriptionValidityPeriod?: number | null,
    address?: string | null,
    feedbackDate?: number | null,
    isDeleted?: boolean | null,
    pricingInfo?:  {
      __typename: "PricingInfo",
      currentPricingId?: string | null,
      nextPricingId?: string | null,
    } | null,
    stepPayID?: string | null,
    numberOfPatients?: number | null,
    numberOfDoctors?: number | null,
    numberOfSteps?: number | null,
    clinicEmail?: string | null,
    updatedAt?: string | null,
    planInfo?: string | null,
    planCheckPassFlag?: boolean | null,
    createdAt?: string | null,
    typename: string,
    config?: string | null,
    businessType?: BusinessType | null,
    promotion?: string | null,
  } | null,
};

export type UpdateClinicMutationVariables = {
  input: UpdateClinicInput,
  condition?: ModelClinicConditionInput | null,
};

export type UpdateClinicMutation = {
  updateClinic?:  {
    __typename: "Clinic",
    id: string,
    clinicName?: string | null,
    doctorName?: string | null,
    doctorNumber?: string | null,
    phone?: string | null,
    fax?: string | null,
    medicalInstitutionNumber?: string | null,
    prescriptionValidityPeriod?: number | null,
    address?: string | null,
    feedbackDate?: number | null,
    isDeleted?: boolean | null,
    pricingInfo?:  {
      __typename: "PricingInfo",
      currentPricingId?: string | null,
      nextPricingId?: string | null,
    } | null,
    stepPayID?: string | null,
    numberOfPatients?: number | null,
    numberOfDoctors?: number | null,
    numberOfSteps?: number | null,
    clinicEmail?: string | null,
    updatedAt?: string | null,
    planInfo?: string | null,
    planCheckPassFlag?: boolean | null,
    createdAt?: string | null,
    typename: string,
    config?: string | null,
    businessType?: BusinessType | null,
    promotion?: string | null,
  } | null,
};

export type DeleteClinicMutationVariables = {
  input: DeleteClinicInput,
  condition?: ModelClinicConditionInput | null,
};

export type DeleteClinicMutation = {
  deleteClinic?:  {
    __typename: "Clinic",
    id: string,
    clinicName?: string | null,
    doctorName?: string | null,
    doctorNumber?: string | null,
    phone?: string | null,
    fax?: string | null,
    medicalInstitutionNumber?: string | null,
    prescriptionValidityPeriod?: number | null,
    address?: string | null,
    feedbackDate?: number | null,
    isDeleted?: boolean | null,
    pricingInfo?:  {
      __typename: "PricingInfo",
      currentPricingId?: string | null,
      nextPricingId?: string | null,
    } | null,
    stepPayID?: string | null,
    numberOfPatients?: number | null,
    numberOfDoctors?: number | null,
    numberOfSteps?: number | null,
    clinicEmail?: string | null,
    updatedAt?: string | null,
    planInfo?: string | null,
    planCheckPassFlag?: boolean | null,
    createdAt?: string | null,
    typename: string,
    config?: string | null,
    businessType?: BusinessType | null,
    promotion?: string | null,
  } | null,
};

export type CreateTreatmentMutationVariables = {
  input: CreateTreatmentInput,
  condition?: ModelTreatmentConditionInput | null,
};

export type CreateTreatmentMutation = {
  createTreatment?:  {
    __typename: "Treatment",
    id: string,
    clinicID: string,
    surveyID: string,
    patientID: string,
    patientData?:  {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null,
    save?: number | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    feedbackID?: string | null,
    mainSymptom?: string | null,
    userID: string,
    prescribedHerb?:  Array< {
      __typename: "PrescribedHerb",
      code?: string | null,
      amount?: number | null,
      name?: string | null,
    } | null > | null,
    status?: string | null,
    cure?: Array< string | null > | null,
    memo?: string | null,
    lastVisitedAt?: string | null,
    selectSymptom?:  Array< {
      __typename: "SelectSymptom",
      code?: string | null,
      check?: boolean | null,
    } | null > | null,
    symptomCheck?: Array< string | null > | null,
    name?: string | null,
    birth?: string | null,
    gender?: string | null,
    prescriptionStatus?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    amount?: number | null,
    isVisited?: boolean | null,
    isDeleted?: boolean | null,
    surveyCheck?: Array< string | null > | null,
    herbsForRecommanded?: Array< string | null > | null,
    treatmentedAt?: string | null,
    treatmentStatus?: TreatmentStatus | null,
    patientNameForSearching?: string | null,
    selectedBCode?: Array< string | null > | null,
    prescriptionMode?: string | null,
    prescribedRecipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    prescriptionId?: string | null,
    prescriptionData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customHerbalFormulaId?: string | null,
    customHerbalFormulaData?:  {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    prescriptionDocument?: string | null,
    footprintsLog?: Array< string | null > | null,
  } | null,
};

export type UpdateTreatmentMutationVariables = {
  input: UpdateTreatmentInput,
  condition?: ModelTreatmentConditionInput | null,
};

export type UpdateTreatmentMutation = {
  updateTreatment?:  {
    __typename: "Treatment",
    id: string,
    clinicID: string,
    surveyID: string,
    patientID: string,
    patientData?:  {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null,
    save?: number | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    feedbackID?: string | null,
    mainSymptom?: string | null,
    userID: string,
    prescribedHerb?:  Array< {
      __typename: "PrescribedHerb",
      code?: string | null,
      amount?: number | null,
      name?: string | null,
    } | null > | null,
    status?: string | null,
    cure?: Array< string | null > | null,
    memo?: string | null,
    lastVisitedAt?: string | null,
    selectSymptom?:  Array< {
      __typename: "SelectSymptom",
      code?: string | null,
      check?: boolean | null,
    } | null > | null,
    symptomCheck?: Array< string | null > | null,
    name?: string | null,
    birth?: string | null,
    gender?: string | null,
    prescriptionStatus?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    amount?: number | null,
    isVisited?: boolean | null,
    isDeleted?: boolean | null,
    surveyCheck?: Array< string | null > | null,
    herbsForRecommanded?: Array< string | null > | null,
    treatmentedAt?: string | null,
    treatmentStatus?: TreatmentStatus | null,
    patientNameForSearching?: string | null,
    selectedBCode?: Array< string | null > | null,
    prescriptionMode?: string | null,
    prescribedRecipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    prescriptionId?: string | null,
    prescriptionData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customHerbalFormulaId?: string | null,
    customHerbalFormulaData?:  {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    prescriptionDocument?: string | null,
    footprintsLog?: Array< string | null > | null,
  } | null,
};

export type DeleteTreatmentMutationVariables = {
  input: DeleteTreatmentInput,
  condition?: ModelTreatmentConditionInput | null,
};

export type DeleteTreatmentMutation = {
  deleteTreatment?:  {
    __typename: "Treatment",
    id: string,
    clinicID: string,
    surveyID: string,
    patientID: string,
    patientData?:  {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null,
    save?: number | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    feedbackID?: string | null,
    mainSymptom?: string | null,
    userID: string,
    prescribedHerb?:  Array< {
      __typename: "PrescribedHerb",
      code?: string | null,
      amount?: number | null,
      name?: string | null,
    } | null > | null,
    status?: string | null,
    cure?: Array< string | null > | null,
    memo?: string | null,
    lastVisitedAt?: string | null,
    selectSymptom?:  Array< {
      __typename: "SelectSymptom",
      code?: string | null,
      check?: boolean | null,
    } | null > | null,
    symptomCheck?: Array< string | null > | null,
    name?: string | null,
    birth?: string | null,
    gender?: string | null,
    prescriptionStatus?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    amount?: number | null,
    isVisited?: boolean | null,
    isDeleted?: boolean | null,
    surveyCheck?: Array< string | null > | null,
    herbsForRecommanded?: Array< string | null > | null,
    treatmentedAt?: string | null,
    treatmentStatus?: TreatmentStatus | null,
    patientNameForSearching?: string | null,
    selectedBCode?: Array< string | null > | null,
    prescriptionMode?: string | null,
    prescribedRecipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    prescriptionId?: string | null,
    prescriptionData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customHerbalFormulaId?: string | null,
    customHerbalFormulaData?:  {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    prescriptionDocument?: string | null,
    footprintsLog?: Array< string | null > | null,
  } | null,
};

export type CreateFormulaMutationVariables = {
  input: CreateFormulaInput,
  condition?: ModelFormulaConditionInput | null,
};

export type CreateFormulaMutation = {
  createFormula?:  {
    __typename: "Formula",
    id: string,
    formulaName?: string | null,
    chineseName?: string | null,
    herbs?: Array< string | null > | null,
    amount?: Array< number | null > | null,
    primaryCare?: Array< string | null > | null,
    tag?: Array< string | null > | null,
    category?: string | null,
    priority?: number | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFormulaMutationVariables = {
  input: UpdateFormulaInput,
  condition?: ModelFormulaConditionInput | null,
};

export type UpdateFormulaMutation = {
  updateFormula?:  {
    __typename: "Formula",
    id: string,
    formulaName?: string | null,
    chineseName?: string | null,
    herbs?: Array< string | null > | null,
    amount?: Array< number | null > | null,
    primaryCare?: Array< string | null > | null,
    tag?: Array< string | null > | null,
    category?: string | null,
    priority?: number | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFormulaMutationVariables = {
  input: DeleteFormulaInput,
  condition?: ModelFormulaConditionInput | null,
};

export type DeleteFormulaMutation = {
  deleteFormula?:  {
    __typename: "Formula",
    id: string,
    formulaName?: string | null,
    chineseName?: string | null,
    herbs?: Array< string | null > | null,
    amount?: Array< number | null > | null,
    primaryCare?: Array< string | null > | null,
    tag?: Array< string | null > | null,
    category?: string | null,
    priority?: number | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBiztalkHistoryMutationVariables = {
  input: CreateBiztalkHistoryInput,
  condition?: ModelBiztalkHistoryConditionInput | null,
};

export type CreateBiztalkHistoryMutation = {
  createBiztalkHistory?:  {
    __typename: "BiztalkHistory",
    msgIdx: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type UpdateBiztalkHistoryMutationVariables = {
  input: UpdateBiztalkHistoryInput,
  condition?: ModelBiztalkHistoryConditionInput | null,
};

export type UpdateBiztalkHistoryMutation = {
  updateBiztalkHistory?:  {
    __typename: "BiztalkHistory",
    msgIdx: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type DeleteBiztalkHistoryMutationVariables = {
  input: DeleteBiztalkHistoryInput,
  condition?: ModelBiztalkHistoryConditionInput | null,
};

export type DeleteBiztalkHistoryMutation = {
  deleteBiztalkHistory?:  {
    __typename: "BiztalkHistory",
    msgIdx: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type CreateYejinSignupRequestMutationVariables = {
  input: CreateYejinSignupRequestInput,
  condition?: ModelYejinSignupRequestConditionInput | null,
};

export type CreateYejinSignupRequestMutation = {
  createYejinSignupRequest?:  {
    __typename: "YejinSignupRequest",
    id?: string | null,
    userName?: string | null,
    email?: string | null,
    clinicName?: string | null,
    clinicAddress?: string | null,
    clinicPhone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    permission?: string | null,
    licenseNumber?: string | null,
    clinicEmail?: string | null,
    businessType?: BusinessType | null,
    createdAt?: string | null,
    approvedAt?: string | null,
    requestStatus?: SignupRequestStatus | null,
    updatedAt: string,
  } | null,
};

export type UpdateYejinSignupRequestMutationVariables = {
  input: UpdateYejinSignupRequestInput,
  condition?: ModelYejinSignupRequestConditionInput | null,
};

export type UpdateYejinSignupRequestMutation = {
  updateYejinSignupRequest?:  {
    __typename: "YejinSignupRequest",
    id?: string | null,
    userName?: string | null,
    email?: string | null,
    clinicName?: string | null,
    clinicAddress?: string | null,
    clinicPhone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    permission?: string | null,
    licenseNumber?: string | null,
    clinicEmail?: string | null,
    businessType?: BusinessType | null,
    createdAt?: string | null,
    approvedAt?: string | null,
    requestStatus?: SignupRequestStatus | null,
    updatedAt: string,
  } | null,
};

export type DeleteYejinSignupRequestMutationVariables = {
  input: DeleteYejinSignupRequestInput,
  condition?: ModelYejinSignupRequestConditionInput | null,
};

export type DeleteYejinSignupRequestMutation = {
  deleteYejinSignupRequest?:  {
    __typename: "YejinSignupRequest",
    id?: string | null,
    userName?: string | null,
    email?: string | null,
    clinicName?: string | null,
    clinicAddress?: string | null,
    clinicPhone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    permission?: string | null,
    licenseNumber?: string | null,
    clinicEmail?: string | null,
    businessType?: BusinessType | null,
    createdAt?: string | null,
    approvedAt?: string | null,
    requestStatus?: SignupRequestStatus | null,
    updatedAt: string,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    treatmentID: string,
    sendCount?: number | null,
    byeonjeung?: string | null,
    startedAt?: string | null,
    birth?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    treatmentID: string,
    sendCount?: number | null,
    byeonjeung?: string | null,
    startedAt?: string | null,
    birth?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    treatmentID: string,
    sendCount?: number | null,
    byeonjeung?: string | null,
    startedAt?: string | null,
    birth?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSelfRegistrationQRMutationVariables = {
  input: CreateSelfRegistrationQRInput,
  condition?: ModelSelfRegistrationQRConditionInput | null,
};

export type CreateSelfRegistrationQRMutation = {
  createSelfRegistrationQR?:  {
    __typename: "SelfRegistrationQR",
    QRID: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    expiredDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSelfRegistrationQRMutationVariables = {
  input: UpdateSelfRegistrationQRInput,
  condition?: ModelSelfRegistrationQRConditionInput | null,
};

export type UpdateSelfRegistrationQRMutation = {
  updateSelfRegistrationQR?:  {
    __typename: "SelfRegistrationQR",
    QRID: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    expiredDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSelfRegistrationQRMutationVariables = {
  input: DeleteSelfRegistrationQRInput,
  condition?: ModelSelfRegistrationQRConditionInput | null,
};

export type DeleteSelfRegistrationQRMutation = {
  deleteSelfRegistrationQR?:  {
    __typename: "SelfRegistrationQR",
    QRID: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    expiredDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDailySignupCountMutationVariables = {
  input: CreateDailySignupCountInput,
  condition?: ModelDailySignupCountConditionInput | null,
};

export type CreateDailySignupCountMutation = {
  createDailySignupCount?:  {
    __typename: "DailySignupCount",
    date: string,
    count?: number | null,
    memo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDailySignupCountMutationVariables = {
  input: UpdateDailySignupCountInput,
  condition?: ModelDailySignupCountConditionInput | null,
};

export type UpdateDailySignupCountMutation = {
  updateDailySignupCount?:  {
    __typename: "DailySignupCount",
    date: string,
    count?: number | null,
    memo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDailySignupCountMutationVariables = {
  input: DeleteDailySignupCountInput,
  condition?: ModelDailySignupCountConditionInput | null,
};

export type DeleteDailySignupCountMutation = {
  deleteDailySignupCount?:  {
    __typename: "DailySignupCount",
    date: string,
    count?: number | null,
    memo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDailyPatientCountMutationVariables = {
  input: CreateDailyPatientCountInput,
  condition?: ModelDailyPatientCountConditionInput | null,
};

export type CreateDailyPatientCountMutation = {
  createDailyPatientCount?:  {
    __typename: "DailyPatientCount",
    date: string,
    count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDailyPatientCountMutationVariables = {
  input: UpdateDailyPatientCountInput,
  condition?: ModelDailyPatientCountConditionInput | null,
};

export type UpdateDailyPatientCountMutation = {
  updateDailyPatientCount?:  {
    __typename: "DailyPatientCount",
    date: string,
    count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDailyPatientCountMutationVariables = {
  input: DeleteDailyPatientCountInput,
  condition?: ModelDailyPatientCountConditionInput | null,
};

export type DeleteDailyPatientCountMutation = {
  deleteDailyPatientCount?:  {
    __typename: "DailyPatientCount",
    date: string,
    count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetHerbQueryVariables = {
  code: string,
};

export type GetHerbQuery = {
  getHerb?:  {
    __typename: "Herb",
    code: string,
    herbName: string,
    nameForSearching?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListHerbsQueryVariables = {
  code?: string | null,
  filter?: ModelHerbFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHerbsQuery = {
  listHerbs?:  {
    __typename: "ModelHerbConnection",
    items:  Array< {
      __typename: "Herb",
      code: string,
      herbName: string,
      nameForSearching?: Array< string | null > | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomHerbalFormulaQueryVariables = {
  id: string,
};

export type GetCustomHerbalFormulaQuery = {
  getCustomHerbalFormula?:  {
    __typename: "CustomHerbalFormula",
    id: string,
    ownerUserID: string,
    originalHerbalFormulaID?: string | null,
    originalHerbalFormulaData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    scoringType?: number | null,
    memo?: string | null,
    isDeleted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListCustomHerbalFormulasQueryVariables = {
  filter?: ModelCustomHerbalFormulaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomHerbalFormulasQuery = {
  listCustomHerbalFormulas?:  {
    __typename: "ModelCustomHerbalFormulaConnection",
    items:  Array< {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomHerbalFormulaByOwnerUserQueryVariables = {
  ownerUserID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomHerbalFormulaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomHerbalFormulaByOwnerUserQuery = {
  customHerbalFormulaByOwnerUser?:  {
    __typename: "ModelCustomHerbalFormulaConnection",
    items:  Array< {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHerbalFormulaQueryVariables = {
  id: string,
};

export type GetHerbalFormulaQuery = {
  getHerbalFormula?:  {
    __typename: "HerbalFormula",
    id: string,
    category?: string | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    cn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    scoringType?: number | null,
    source?: string | null,
    postfix?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHerbalFormulasQueryVariables = {
  filter?: ModelHerbalFormulaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHerbalFormulasQuery = {
  listHerbalFormulas?:  {
    __typename: "ModelHerbalFormulaConnection",
    items:  Array< {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListHerbalFormulaByscoringTypeQueryVariables = {
  scoringType: number,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHerbalFormulaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHerbalFormulaByscoringTypeQuery = {
  listHerbalFormulaByscoringType?:  {
    __typename: "ModelHerbalFormulaConnection",
    items:  Array< {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSurveyQueryVariables = {
  id: string,
};

export type GetSurveyQuery = {
  getSurvey?:  {
    __typename: "Survey",
    id: string,
    otp: string,
    patientID: string,
    clinicID: string,
    link?: string | null,
    surveyResult?: string | null,
    mainSymptom?: string | null,
    symptoms?: string | null,
    visitCount?: number | null,
    firstLinkClickDt?: number | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    sendCount?: number | null,
    isSend?: boolean | null,
    treatmentID?: string | null,
    feedbacks?: string | null,
    feedbackResult?: string | null,
    feedbackAt?: string | null,
    feedbackLink?: string | null,
    feedbackDone?: boolean | null,
    isFeedback?: boolean | null,
    additionalSymptoms?: string | null,
    feedbackSendTime?: string | null,
    feedbackSchedule_GSI_PK?: string | null,
    feedbackScheduleStatus?: number | null,
    surveyDone?: boolean | null,
    surveyGrade?: number | null,
    surveySatisfaction?: string | null,
    feedbackGrade?: number | null,
    feedbackSatisfaction?: string | null,
    surveyStatus?: SurveyStatus | null,
    expiredAt?: string | null,
    surveyBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    feedbackBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    mainSymptomCategory?: string | null,
    sender?: string | null,
    surveyType?: SurveyType | null,
    insuranceType?: string | null,
    surveySurveyBizTalkHistoryId?: string | null,
    surveyFeedbackBizTalkHistoryId?: string | null,
  } | null,
};

export type ListSurveysQueryVariables = {
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSurveysQuery = {
  listSurveys?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SurveyByDateQueryVariables = {
  patientID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SurveyByDateQuery = {
  surveyByDate?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SurveyByFeedbackScheduleQueryVariables = {
  feedbackSchedule_GSI_PK: string,
  feedbackScheduleStatus?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSurveyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SurveyByFeedbackScheduleQuery = {
  surveyByFeedbackSchedule?:  {
    __typename: "ModelSurveyConnection",
    items:  Array< {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHistoryQueryVariables = {
  id: string,
};

export type GetHistoryQuery = {
  getHistory?:  {
    __typename: "History",
    id: string,
    clinicID: string,
    patientID: string,
    type?: string | null,
    typeID?: string | null,
    messageCode?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type ListHistoriesQueryVariables = {
  filter?: ModelHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHistoriesQuery = {
  listHistories?:  {
    __typename: "ModelHistoryConnection",
    items:  Array< {
      __typename: "History",
      id: string,
      clinicID: string,
      patientID: string,
      type?: string | null,
      typeID?: string | null,
      messageCode?: string | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type HistoriesByDateQueryVariables = {
  patientID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HistoriesByDateQuery = {
  historiesByDate?:  {
    __typename: "ModelHistoryConnection",
    items:  Array< {
      __typename: "History",
      id: string,
      clinicID: string,
      patientID: string,
      type?: string | null,
      typeID?: string | null,
      messageCode?: string | null,
      message?: string | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPatientQueryVariables = {
  id: string,
};

export type GetPatientQuery = {
  getPatient?:  {
    __typename: "Patient",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    residentRegistrationNumber?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    recentActivityCode?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    registrationPath?: string | null,
    isDeleted?: boolean | null,
  } | null,
};

export type ListPatientsQueryVariables = {
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsQuery = {
  listPatients?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PatientsByClinicQueryVariables = {
  clinicID: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientsByClinicQuery = {
  patientsByClinic?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PatientsByNameQueryVariables = {
  clinicID: string,
  patientName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientsByNameQuery = {
  patientsByName?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PatientsByRecentActivityQueryVariables = {
  clinicID: string,
  recentActivity?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientsByRecentActivityQuery = {
  patientsByRecentActivity?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PatientsByPrescriptionStatusQueryVariables = {
  clinicID: string,
  prescriptionStatus?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientsByPrescriptionStatusQuery = {
  patientsByPrescriptionStatus?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PatientsByTreatmentStatusQueryVariables = {
  clinicID: string,
  treatmentStatusDateForSorting?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientsByTreatmentStatusQuery = {
  patientsByTreatmentStatus?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPatientDeletedQueryVariables = {
  id: string,
};

export type GetPatientDeletedQuery = {
  getPatientDeleted?:  {
    __typename: "PatientDeleted",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    residentRegistrationNumber?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    type: string,
    privacyTerms?: string | null,
  } | null,
};

export type ListPatientDeletedsQueryVariables = {
  filter?: ModelPatientDeletedFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientDeletedsQuery = {
  listPatientDeleteds?:  {
    __typename: "ModelPatientDeletedConnection",
    items:  Array< {
      __typename: "PatientDeleted",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      residentRegistrationNumber?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      type: string,
      privacyTerms?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserRoleQueryVariables = {
  id: string,
};

export type GetUserRoleQuery = {
  getUserRole?:  {
    __typename: "UserRole",
    id: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    config?: string | null,
    clinicName?: string | null,
    status?: string | null,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      username?: string | null,
      phone?: string | null,
      terms?: Array< boolean | null > | null,
      termsVersion?: string | null,
      termsAgreedAt?: string | null,
      createdAt?: string | null,
      isDeleted?: boolean | null,
      roleName?: string | null,
      config?: string | null,
      licenseNumber?: string | null,
      licensePendingReviewFlag?: boolean | null,
      updatedAt: string,
    } | null,
    roleName: string,
    userName: string,
    isDeleted?: boolean | null,
    testFlag?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserRolesQueryVariables = {
  filter?: ModelUserRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserRolesQuery = {
  listUserRoles?:  {
    __typename: "ModelUserRoleConnection",
    items:  Array< {
      __typename: "UserRole",
      id: string,
      clinicID: string,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        clinicName?: string | null,
        doctorName?: string | null,
        doctorNumber?: string | null,
        phone?: string | null,
        fax?: string | null,
        medicalInstitutionNumber?: string | null,
        prescriptionValidityPeriod?: number | null,
        address?: string | null,
        feedbackDate?: number | null,
        isDeleted?: boolean | null,
        pricingInfo?:  {
          __typename: "PricingInfo",
          currentPricingId?: string | null,
          nextPricingId?: string | null,
        } | null,
        stepPayID?: string | null,
        numberOfPatients?: number | null,
        numberOfDoctors?: number | null,
        numberOfSteps?: number | null,
        clinicEmail?: string | null,
        updatedAt?: string | null,
        planInfo?: string | null,
        planCheckPassFlag?: boolean | null,
        createdAt?: string | null,
        typename: string,
        config?: string | null,
        businessType?: BusinessType | null,
        promotion?: string | null,
      } | null,
      config?: string | null,
      clinicName?: string | null,
      status?: string | null,
      email?: string | null,
      phone?: string | null,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        username?: string | null,
        phone?: string | null,
        terms?: Array< boolean | null > | null,
        termsVersion?: string | null,
        termsAgreedAt?: string | null,
        createdAt?: string | null,
        isDeleted?: boolean | null,
        roleName?: string | null,
        config?: string | null,
        licenseNumber?: string | null,
        licensePendingReviewFlag?: boolean | null,
        updatedAt: string,
      } | null,
      roleName: string,
      userName: string,
      isDeleted?: boolean | null,
      testFlag?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RoleByClinicQueryVariables = {
  clinicID: string,
  roleName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RoleByClinicQuery = {
  roleByClinic?:  {
    __typename: "ModelUserRoleConnection",
    items:  Array< {
      __typename: "UserRole",
      id: string,
      clinicID: string,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        clinicName?: string | null,
        doctorName?: string | null,
        doctorNumber?: string | null,
        phone?: string | null,
        fax?: string | null,
        medicalInstitutionNumber?: string | null,
        prescriptionValidityPeriod?: number | null,
        address?: string | null,
        feedbackDate?: number | null,
        isDeleted?: boolean | null,
        pricingInfo?:  {
          __typename: "PricingInfo",
          currentPricingId?: string | null,
          nextPricingId?: string | null,
        } | null,
        stepPayID?: string | null,
        numberOfPatients?: number | null,
        numberOfDoctors?: number | null,
        numberOfSteps?: number | null,
        clinicEmail?: string | null,
        updatedAt?: string | null,
        planInfo?: string | null,
        planCheckPassFlag?: boolean | null,
        createdAt?: string | null,
        typename: string,
        config?: string | null,
        businessType?: BusinessType | null,
        promotion?: string | null,
      } | null,
      config?: string | null,
      clinicName?: string | null,
      status?: string | null,
      email?: string | null,
      phone?: string | null,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        username?: string | null,
        phone?: string | null,
        terms?: Array< boolean | null > | null,
        termsVersion?: string | null,
        termsAgreedAt?: string | null,
        createdAt?: string | null,
        isDeleted?: boolean | null,
        roleName?: string | null,
        config?: string | null,
        licenseNumber?: string | null,
        licensePendingReviewFlag?: boolean | null,
        updatedAt: string,
      } | null,
      roleName: string,
      userName: string,
      isDeleted?: boolean | null,
      testFlag?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RoleByUserQueryVariables = {
  userID: string,
  roleName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RoleByUserQuery = {
  roleByUser?:  {
    __typename: "ModelUserRoleConnection",
    items:  Array< {
      __typename: "UserRole",
      id: string,
      clinicID: string,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        clinicName?: string | null,
        doctorName?: string | null,
        doctorNumber?: string | null,
        phone?: string | null,
        fax?: string | null,
        medicalInstitutionNumber?: string | null,
        prescriptionValidityPeriod?: number | null,
        address?: string | null,
        feedbackDate?: number | null,
        isDeleted?: boolean | null,
        pricingInfo?:  {
          __typename: "PricingInfo",
          currentPricingId?: string | null,
          nextPricingId?: string | null,
        } | null,
        stepPayID?: string | null,
        numberOfPatients?: number | null,
        numberOfDoctors?: number | null,
        numberOfSteps?: number | null,
        clinicEmail?: string | null,
        updatedAt?: string | null,
        planInfo?: string | null,
        planCheckPassFlag?: boolean | null,
        createdAt?: string | null,
        typename: string,
        config?: string | null,
        businessType?: BusinessType | null,
        promotion?: string | null,
      } | null,
      config?: string | null,
      clinicName?: string | null,
      status?: string | null,
      email?: string | null,
      phone?: string | null,
      userID: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        username?: string | null,
        phone?: string | null,
        terms?: Array< boolean | null > | null,
        termsVersion?: string | null,
        termsAgreedAt?: string | null,
        createdAt?: string | null,
        isDeleted?: boolean | null,
        roleName?: string | null,
        config?: string | null,
        licenseNumber?: string | null,
        licensePendingReviewFlag?: boolean | null,
        updatedAt: string,
      } | null,
      roleName: string,
      userName: string,
      isDeleted?: boolean | null,
      testFlag?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    username?: string | null,
    phone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    createdAt?: string | null,
    isDeleted?: boolean | null,
    roleName?: string | null,
    config?: string | null,
    licenseNumber?: string | null,
    licensePendingReviewFlag?: boolean | null,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email?: string | null,
      username?: string | null,
      phone?: string | null,
      terms?: Array< boolean | null > | null,
      termsVersion?: string | null,
      termsAgreedAt?: string | null,
      createdAt?: string | null,
      isDeleted?: boolean | null,
      roleName?: string | null,
      config?: string | null,
      licenseNumber?: string | null,
      licensePendingReviewFlag?: boolean | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserPoolQueryVariables = {
  id: string,
};

export type GetUserPoolQuery = {
  getUserPool?:  {
    __typename: "UserPool",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserPoolsQueryVariables = {
  filter?: ModelUserPoolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserPoolsQuery = {
  listUserPools?:  {
    __typename: "ModelUserPoolConnection",
    items:  Array< {
      __typename: "UserPool",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRoleQueryVariables = {
  id: string,
};

export type GetRoleQuery = {
  getRole?:  {
    __typename: "Role",
    id: string,
    clinicID: string,
    roleName?: string | null,
    permisstions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRolesQueryVariables = {
  filter?: ModelRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRolesQuery = {
  listRoles?:  {
    __typename: "ModelRoleConnection",
    items:  Array< {
      __typename: "Role",
      id: string,
      clinicID: string,
      roleName?: string | null,
      permisstions?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClinicsForPromotionQueryVariables = {
  id: string,
};

export type GetClinicsForPromotionQuery = {
  getClinicsForPromotion?:  {
    __typename: "ClinicsForPromotion",
    id: string,
    promotionType?: string | null,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClinicsForPromotionsQueryVariables = {
  filter?: ModelClinicsForPromotionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicsForPromotionsQuery = {
  listClinicsForPromotions?:  {
    __typename: "ModelClinicsForPromotionConnection",
    items:  Array< {
      __typename: "ClinicsForPromotion",
      id: string,
      promotionType?: string | null,
      clinicID: string,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        clinicName?: string | null,
        doctorName?: string | null,
        doctorNumber?: string | null,
        phone?: string | null,
        fax?: string | null,
        medicalInstitutionNumber?: string | null,
        prescriptionValidityPeriod?: number | null,
        address?: string | null,
        feedbackDate?: number | null,
        isDeleted?: boolean | null,
        pricingInfo?:  {
          __typename: "PricingInfo",
          currentPricingId?: string | null,
          nextPricingId?: string | null,
        } | null,
        stepPayID?: string | null,
        numberOfPatients?: number | null,
        numberOfDoctors?: number | null,
        numberOfSteps?: number | null,
        clinicEmail?: string | null,
        updatedAt?: string | null,
        planInfo?: string | null,
        planCheckPassFlag?: boolean | null,
        createdAt?: string | null,
        typename: string,
        config?: string | null,
        businessType?: BusinessType | null,
        promotion?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListClinicsForPromotionBypromotionTypeQueryVariables = {
  promotionType: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicsForPromotionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicsForPromotionBypromotionTypeQuery = {
  listClinicsForPromotionBypromotionType?:  {
    __typename: "ModelClinicsForPromotionConnection",
    items:  Array< {
      __typename: "ClinicsForPromotion",
      id: string,
      promotionType?: string | null,
      clinicID: string,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        clinicName?: string | null,
        doctorName?: string | null,
        doctorNumber?: string | null,
        phone?: string | null,
        fax?: string | null,
        medicalInstitutionNumber?: string | null,
        prescriptionValidityPeriod?: number | null,
        address?: string | null,
        feedbackDate?: number | null,
        isDeleted?: boolean | null,
        pricingInfo?:  {
          __typename: "PricingInfo",
          currentPricingId?: string | null,
          nextPricingId?: string | null,
        } | null,
        stepPayID?: string | null,
        numberOfPatients?: number | null,
        numberOfDoctors?: number | null,
        numberOfSteps?: number | null,
        clinicEmail?: string | null,
        updatedAt?: string | null,
        planInfo?: string | null,
        planCheckPassFlag?: boolean | null,
        createdAt?: string | null,
        typename: string,
        config?: string | null,
        businessType?: BusinessType | null,
        promotion?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClinicQueryVariables = {
  id: string,
};

export type GetClinicQuery = {
  getClinic?:  {
    __typename: "Clinic",
    id: string,
    clinicName?: string | null,
    doctorName?: string | null,
    doctorNumber?: string | null,
    phone?: string | null,
    fax?: string | null,
    medicalInstitutionNumber?: string | null,
    prescriptionValidityPeriod?: number | null,
    address?: string | null,
    feedbackDate?: number | null,
    isDeleted?: boolean | null,
    pricingInfo?:  {
      __typename: "PricingInfo",
      currentPricingId?: string | null,
      nextPricingId?: string | null,
    } | null,
    stepPayID?: string | null,
    numberOfPatients?: number | null,
    numberOfDoctors?: number | null,
    numberOfSteps?: number | null,
    clinicEmail?: string | null,
    updatedAt?: string | null,
    planInfo?: string | null,
    planCheckPassFlag?: boolean | null,
    createdAt?: string | null,
    typename: string,
    config?: string | null,
    businessType?: BusinessType | null,
    promotion?: string | null,
  } | null,
};

export type ListClinicsQueryVariables = {
  filter?: ModelClinicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClinicsQuery = {
  listClinics?:  {
    __typename: "ModelClinicConnection",
    items:  Array< {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ClinicByDateQueryVariables = {
  typename: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClinicFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClinicByDateQuery = {
  clinicByDate?:  {
    __typename: "ModelClinicConnection",
    items:  Array< {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsageStatisticsQueryVariables = {
  clinicID: string,
  recordDate: string,
};

export type GetUsageStatisticsQuery = {
  getUsageStatistics?:  {
    __typename: "UsageStatistics",
    clinicID: string,
    recordDate: string,
    numberOfTreatmentCompletion?: number | null,
    numberOfTreatmentCompletionsWithPrescription?: number | null,
    numberOfKakaoSent?: number | null,
    numberOfSurveySentBySelf?: number | null,
    numberOfPlanChange?: number | null,
    numberOfTreatment?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsageStatisticsQueryVariables = {
  clinicID?: string | null,
  recordDate?: ModelStringKeyConditionInput | null,
  filter?: ModelUsageStatisticsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsageStatisticsQuery = {
  listUsageStatistics?:  {
    __typename: "ModelUsageStatisticsConnection",
    items:  Array< {
      __typename: "UsageStatistics",
      clinicID: string,
      recordDate: string,
      numberOfTreatmentCompletion?: number | null,
      numberOfTreatmentCompletionsWithPrescription?: number | null,
      numberOfKakaoSent?: number | null,
      numberOfSurveySentBySelf?: number | null,
      numberOfPlanChange?: number | null,
      numberOfTreatment?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDailyUsageStatisticsQueryVariables = {
  date: string,
};

export type GetDailyUsageStatisticsQuery = {
  getDailyUsageStatistics?:  {
    __typename: "DailyUsageStatistics",
    date: string,
    treatmentStartCount?: number | null,
    treatmentCompletionWithoutPrescriptionCount?: number | null,
    treatmentCompletionWithPrescriptionCount?: number | null,
    surveyCount?: number | null,
    surveySentBySelfCount?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDailyUsageStatisticsQueryVariables = {
  date?: string | null,
  filter?: ModelDailyUsageStatisticsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDailyUsageStatisticsQuery = {
  listDailyUsageStatistics?:  {
    __typename: "ModelDailyUsageStatisticsConnection",
    items:  Array< {
      __typename: "DailyUsageStatistics",
      date: string,
      treatmentStartCount?: number | null,
      treatmentCompletionWithoutPrescriptionCount?: number | null,
      treatmentCompletionWithPrescriptionCount?: number | null,
      surveyCount?: number | null,
      surveySentBySelfCount?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTreatmentQueryVariables = {
  id: string,
};

export type GetTreatmentQuery = {
  getTreatment?:  {
    __typename: "Treatment",
    id: string,
    clinicID: string,
    surveyID: string,
    patientID: string,
    patientData?:  {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null,
    save?: number | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    feedbackID?: string | null,
    mainSymptom?: string | null,
    userID: string,
    prescribedHerb?:  Array< {
      __typename: "PrescribedHerb",
      code?: string | null,
      amount?: number | null,
      name?: string | null,
    } | null > | null,
    status?: string | null,
    cure?: Array< string | null > | null,
    memo?: string | null,
    lastVisitedAt?: string | null,
    selectSymptom?:  Array< {
      __typename: "SelectSymptom",
      code?: string | null,
      check?: boolean | null,
    } | null > | null,
    symptomCheck?: Array< string | null > | null,
    name?: string | null,
    birth?: string | null,
    gender?: string | null,
    prescriptionStatus?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    amount?: number | null,
    isVisited?: boolean | null,
    isDeleted?: boolean | null,
    surveyCheck?: Array< string | null > | null,
    herbsForRecommanded?: Array< string | null > | null,
    treatmentedAt?: string | null,
    treatmentStatus?: TreatmentStatus | null,
    patientNameForSearching?: string | null,
    selectedBCode?: Array< string | null > | null,
    prescriptionMode?: string | null,
    prescribedRecipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    prescriptionId?: string | null,
    prescriptionData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customHerbalFormulaId?: string | null,
    customHerbalFormulaData?:  {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    prescriptionDocument?: string | null,
    footprintsLog?: Array< string | null > | null,
  } | null,
};

export type ListTreatmentsQueryVariables = {
  filter?: ModelTreatmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTreatmentsQuery = {
  listTreatments?:  {
    __typename: "ModelTreatmentConnection",
    items:  Array< {
      __typename: "Treatment",
      id: string,
      clinicID: string,
      surveyID: string,
      patientID: string,
      patientData?:  {
        __typename: "Patient",
        id: string,
        clinicID: string,
        patientName?: string | null,
        birth?: string | null,
        residentRegistrationNumber?: string | null,
        gender?: Gender | null,
        phone?: string | null,
        recentActivity?: string | null,
        recentActivityMessage?: string | null,
        recentActivityCode?: string | null,
        createdAt?: string | null,
        prescriptionStatus?: number | null,
        isWaiting?: boolean | null,
        isVisited?: boolean | null,
        symptomID?: string | null,
        mainSymptom?: string | null,
        prescriptionID?: string | null,
        surveyID?: string | null,
        survey?:  {
          __typename: "Survey",
          id: string,
          otp: string,
          patientID: string,
          clinicID: string,
          link?: string | null,
          surveyResult?: string | null,
          mainSymptom?: string | null,
          symptoms?: string | null,
          visitCount?: number | null,
          firstLinkClickDt?: number | null,
          surveyedAt?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          sendCount?: number | null,
          isSend?: boolean | null,
          treatmentID?: string | null,
          feedbacks?: string | null,
          feedbackResult?: string | null,
          feedbackAt?: string | null,
          feedbackLink?: string | null,
          feedbackDone?: boolean | null,
          isFeedback?: boolean | null,
          additionalSymptoms?: string | null,
          feedbackSendTime?: string | null,
          feedbackSchedule_GSI_PK?: string | null,
          feedbackScheduleStatus?: number | null,
          surveyDone?: boolean | null,
          surveyGrade?: number | null,
          surveySatisfaction?: string | null,
          feedbackGrade?: number | null,
          feedbackSatisfaction?: string | null,
          surveyStatus?: SurveyStatus | null,
          expiredAt?: string | null,
          mainSymptomCategory?: string | null,
          sender?: string | null,
          surveyType?: SurveyType | null,
          insuranceType?: string | null,
          surveySurveyBizTalkHistoryId?: string | null,
          surveyFeedbackBizTalkHistoryId?: string | null,
        } | null,
        userID?: string | null,
        treatmentID?: string | null,
        patientNameForSorting?: string | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        treatmentStatus?: number | null,
        treatmentStatusDateForSorting?: string | null,
        address?: string | null,
        registrationPath?: string | null,
        isDeleted?: boolean | null,
      } | null,
      save?: number | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      feedbackID?: string | null,
      mainSymptom?: string | null,
      userID: string,
      prescribedHerb?:  Array< {
        __typename: "PrescribedHerb",
        code?: string | null,
        amount?: number | null,
        name?: string | null,
      } | null > | null,
      status?: string | null,
      cure?: Array< string | null > | null,
      memo?: string | null,
      lastVisitedAt?: string | null,
      selectSymptom?:  Array< {
        __typename: "SelectSymptom",
        code?: string | null,
        check?: boolean | null,
      } | null > | null,
      symptomCheck?: Array< string | null > | null,
      name?: string | null,
      birth?: string | null,
      gender?: string | null,
      prescriptionStatus?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      amount?: number | null,
      isVisited?: boolean | null,
      isDeleted?: boolean | null,
      surveyCheck?: Array< string | null > | null,
      herbsForRecommanded?: Array< string | null > | null,
      treatmentedAt?: string | null,
      treatmentStatus?: TreatmentStatus | null,
      patientNameForSearching?: string | null,
      selectedBCode?: Array< string | null > | null,
      prescriptionMode?: string | null,
      prescribedRecipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      prescriptionId?: string | null,
      prescriptionData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      customHerbalFormulaId?: string | null,
      customHerbalFormulaData?:  {
        __typename: "CustomHerbalFormula",
        id: string,
        ownerUserID: string,
        originalHerbalFormulaID?: string | null,
        originalHerbalFormulaData?:  {
          __typename: "HerbalFormula",
          id: string,
          category?: string | null,
          insrCCode?: string | null,
          insrDsCode?: Array< string | null > | null,
          InsrPrscrType?: number | null,
          kn?: string | null,
          cn?: string | null,
          herbCodes?: Array< string | null > | null,
          processingCodes?: Array< string | null > | null,
          gun?: Array< string | null > | null,
          sin?: Array< string | null > | null,
          scoringType?: number | null,
          source?: string | null,
          postfix?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        scoringType?: number | null,
        memo?: string | null,
        isDeleted?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
      prescriptionDocument?: string | null,
      footprintsLog?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TreatmentsByPrescriptionStatusQueryVariables = {
  clinicID: string,
  treatmentedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTreatmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TreatmentsByPrescriptionStatusQuery = {
  treatmentsByPrescriptionStatus?:  {
    __typename: "ModelTreatmentConnection",
    items:  Array< {
      __typename: "Treatment",
      id: string,
      clinicID: string,
      surveyID: string,
      patientID: string,
      patientData?:  {
        __typename: "Patient",
        id: string,
        clinicID: string,
        patientName?: string | null,
        birth?: string | null,
        residentRegistrationNumber?: string | null,
        gender?: Gender | null,
        phone?: string | null,
        recentActivity?: string | null,
        recentActivityMessage?: string | null,
        recentActivityCode?: string | null,
        createdAt?: string | null,
        prescriptionStatus?: number | null,
        isWaiting?: boolean | null,
        isVisited?: boolean | null,
        symptomID?: string | null,
        mainSymptom?: string | null,
        prescriptionID?: string | null,
        surveyID?: string | null,
        survey?:  {
          __typename: "Survey",
          id: string,
          otp: string,
          patientID: string,
          clinicID: string,
          link?: string | null,
          surveyResult?: string | null,
          mainSymptom?: string | null,
          symptoms?: string | null,
          visitCount?: number | null,
          firstLinkClickDt?: number | null,
          surveyedAt?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          sendCount?: number | null,
          isSend?: boolean | null,
          treatmentID?: string | null,
          feedbacks?: string | null,
          feedbackResult?: string | null,
          feedbackAt?: string | null,
          feedbackLink?: string | null,
          feedbackDone?: boolean | null,
          isFeedback?: boolean | null,
          additionalSymptoms?: string | null,
          feedbackSendTime?: string | null,
          feedbackSchedule_GSI_PK?: string | null,
          feedbackScheduleStatus?: number | null,
          surveyDone?: boolean | null,
          surveyGrade?: number | null,
          surveySatisfaction?: string | null,
          feedbackGrade?: number | null,
          feedbackSatisfaction?: string | null,
          surveyStatus?: SurveyStatus | null,
          expiredAt?: string | null,
          mainSymptomCategory?: string | null,
          sender?: string | null,
          surveyType?: SurveyType | null,
          insuranceType?: string | null,
          surveySurveyBizTalkHistoryId?: string | null,
          surveyFeedbackBizTalkHistoryId?: string | null,
        } | null,
        userID?: string | null,
        treatmentID?: string | null,
        patientNameForSorting?: string | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        treatmentStatus?: number | null,
        treatmentStatusDateForSorting?: string | null,
        address?: string | null,
        registrationPath?: string | null,
        isDeleted?: boolean | null,
      } | null,
      save?: number | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      feedbackID?: string | null,
      mainSymptom?: string | null,
      userID: string,
      prescribedHerb?:  Array< {
        __typename: "PrescribedHerb",
        code?: string | null,
        amount?: number | null,
        name?: string | null,
      } | null > | null,
      status?: string | null,
      cure?: Array< string | null > | null,
      memo?: string | null,
      lastVisitedAt?: string | null,
      selectSymptom?:  Array< {
        __typename: "SelectSymptom",
        code?: string | null,
        check?: boolean | null,
      } | null > | null,
      symptomCheck?: Array< string | null > | null,
      name?: string | null,
      birth?: string | null,
      gender?: string | null,
      prescriptionStatus?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      amount?: number | null,
      isVisited?: boolean | null,
      isDeleted?: boolean | null,
      surveyCheck?: Array< string | null > | null,
      herbsForRecommanded?: Array< string | null > | null,
      treatmentedAt?: string | null,
      treatmentStatus?: TreatmentStatus | null,
      patientNameForSearching?: string | null,
      selectedBCode?: Array< string | null > | null,
      prescriptionMode?: string | null,
      prescribedRecipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      prescriptionId?: string | null,
      prescriptionData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      customHerbalFormulaId?: string | null,
      customHerbalFormulaData?:  {
        __typename: "CustomHerbalFormula",
        id: string,
        ownerUserID: string,
        originalHerbalFormulaID?: string | null,
        originalHerbalFormulaData?:  {
          __typename: "HerbalFormula",
          id: string,
          category?: string | null,
          insrCCode?: string | null,
          insrDsCode?: Array< string | null > | null,
          InsrPrscrType?: number | null,
          kn?: string | null,
          cn?: string | null,
          herbCodes?: Array< string | null > | null,
          processingCodes?: Array< string | null > | null,
          gun?: Array< string | null > | null,
          sin?: Array< string | null > | null,
          scoringType?: number | null,
          source?: string | null,
          postfix?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        scoringType?: number | null,
        memo?: string | null,
        isDeleted?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
      prescriptionDocument?: string | null,
      footprintsLog?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TreatmentPatientByDateQueryVariables = {
  patientID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTreatmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TreatmentPatientByDateQuery = {
  treatmentPatientByDate?:  {
    __typename: "ModelTreatmentConnection",
    items:  Array< {
      __typename: "Treatment",
      id: string,
      clinicID: string,
      surveyID: string,
      patientID: string,
      patientData?:  {
        __typename: "Patient",
        id: string,
        clinicID: string,
        patientName?: string | null,
        birth?: string | null,
        residentRegistrationNumber?: string | null,
        gender?: Gender | null,
        phone?: string | null,
        recentActivity?: string | null,
        recentActivityMessage?: string | null,
        recentActivityCode?: string | null,
        createdAt?: string | null,
        prescriptionStatus?: number | null,
        isWaiting?: boolean | null,
        isVisited?: boolean | null,
        symptomID?: string | null,
        mainSymptom?: string | null,
        prescriptionID?: string | null,
        surveyID?: string | null,
        survey?:  {
          __typename: "Survey",
          id: string,
          otp: string,
          patientID: string,
          clinicID: string,
          link?: string | null,
          surveyResult?: string | null,
          mainSymptom?: string | null,
          symptoms?: string | null,
          visitCount?: number | null,
          firstLinkClickDt?: number | null,
          surveyedAt?: string | null,
          updatedAt?: string | null,
          createdAt?: string | null,
          sendCount?: number | null,
          isSend?: boolean | null,
          treatmentID?: string | null,
          feedbacks?: string | null,
          feedbackResult?: string | null,
          feedbackAt?: string | null,
          feedbackLink?: string | null,
          feedbackDone?: boolean | null,
          isFeedback?: boolean | null,
          additionalSymptoms?: string | null,
          feedbackSendTime?: string | null,
          feedbackSchedule_GSI_PK?: string | null,
          feedbackScheduleStatus?: number | null,
          surveyDone?: boolean | null,
          surveyGrade?: number | null,
          surveySatisfaction?: string | null,
          feedbackGrade?: number | null,
          feedbackSatisfaction?: string | null,
          surveyStatus?: SurveyStatus | null,
          expiredAt?: string | null,
          mainSymptomCategory?: string | null,
          sender?: string | null,
          surveyType?: SurveyType | null,
          insuranceType?: string | null,
          surveySurveyBizTalkHistoryId?: string | null,
          surveyFeedbackBizTalkHistoryId?: string | null,
        } | null,
        userID?: string | null,
        treatmentID?: string | null,
        patientNameForSorting?: string | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        treatmentStatus?: number | null,
        treatmentStatusDateForSorting?: string | null,
        address?: string | null,
        registrationPath?: string | null,
        isDeleted?: boolean | null,
      } | null,
      save?: number | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      feedbackID?: string | null,
      mainSymptom?: string | null,
      userID: string,
      prescribedHerb?:  Array< {
        __typename: "PrescribedHerb",
        code?: string | null,
        amount?: number | null,
        name?: string | null,
      } | null > | null,
      status?: string | null,
      cure?: Array< string | null > | null,
      memo?: string | null,
      lastVisitedAt?: string | null,
      selectSymptom?:  Array< {
        __typename: "SelectSymptom",
        code?: string | null,
        check?: boolean | null,
      } | null > | null,
      symptomCheck?: Array< string | null > | null,
      name?: string | null,
      birth?: string | null,
      gender?: string | null,
      prescriptionStatus?: number | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      amount?: number | null,
      isVisited?: boolean | null,
      isDeleted?: boolean | null,
      surveyCheck?: Array< string | null > | null,
      herbsForRecommanded?: Array< string | null > | null,
      treatmentedAt?: string | null,
      treatmentStatus?: TreatmentStatus | null,
      patientNameForSearching?: string | null,
      selectedBCode?: Array< string | null > | null,
      prescriptionMode?: string | null,
      prescribedRecipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      prescriptionId?: string | null,
      prescriptionData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      customHerbalFormulaId?: string | null,
      customHerbalFormulaData?:  {
        __typename: "CustomHerbalFormula",
        id: string,
        ownerUserID: string,
        originalHerbalFormulaID?: string | null,
        originalHerbalFormulaData?:  {
          __typename: "HerbalFormula",
          id: string,
          category?: string | null,
          insrCCode?: string | null,
          insrDsCode?: Array< string | null > | null,
          InsrPrscrType?: number | null,
          kn?: string | null,
          cn?: string | null,
          herbCodes?: Array< string | null > | null,
          processingCodes?: Array< string | null > | null,
          gun?: Array< string | null > | null,
          sin?: Array< string | null > | null,
          scoringType?: number | null,
          source?: string | null,
          postfix?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        scoringType?: number | null,
        memo?: string | null,
        isDeleted?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
      prescriptionDocument?: string | null,
      footprintsLog?: Array< string | null > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFormulaQueryVariables = {
  id: string,
};

export type GetFormulaQuery = {
  getFormula?:  {
    __typename: "Formula",
    id: string,
    formulaName?: string | null,
    chineseName?: string | null,
    herbs?: Array< string | null > | null,
    amount?: Array< number | null > | null,
    primaryCare?: Array< string | null > | null,
    tag?: Array< string | null > | null,
    category?: string | null,
    priority?: number | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFormulasQueryVariables = {
  filter?: ModelFormulaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFormulasQuery = {
  listFormulas?:  {
    __typename: "ModelFormulaConnection",
    items:  Array< {
      __typename: "Formula",
      id: string,
      formulaName?: string | null,
      chineseName?: string | null,
      herbs?: Array< string | null > | null,
      amount?: Array< number | null > | null,
      primaryCare?: Array< string | null > | null,
      tag?: Array< string | null > | null,
      category?: string | null,
      priority?: number | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBiztalkHistoryQueryVariables = {
  msgIdx: string,
};

export type GetBiztalkHistoryQuery = {
  getBiztalkHistory?:  {
    __typename: "BiztalkHistory",
    msgIdx: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListBiztalkHistoriesQueryVariables = {
  msgIdx?: string | null,
  filter?: ModelBiztalkHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBiztalkHistoriesQuery = {
  listBiztalkHistories?:  {
    __typename: "ModelBiztalkHistoryConnection",
    items:  Array< {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetYejinSignupRequestQueryVariables = {
  id: string,
};

export type GetYejinSignupRequestQuery = {
  getYejinSignupRequest?:  {
    __typename: "YejinSignupRequest",
    id?: string | null,
    userName?: string | null,
    email?: string | null,
    clinicName?: string | null,
    clinicAddress?: string | null,
    clinicPhone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    permission?: string | null,
    licenseNumber?: string | null,
    clinicEmail?: string | null,
    businessType?: BusinessType | null,
    createdAt?: string | null,
    approvedAt?: string | null,
    requestStatus?: SignupRequestStatus | null,
    updatedAt: string,
  } | null,
};

export type ListYejinSignupRequestsQueryVariables = {
  filter?: ModelYejinSignupRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListYejinSignupRequestsQuery = {
  listYejinSignupRequests?:  {
    __typename: "ModelYejinSignupRequestConnection",
    items:  Array< {
      __typename: "YejinSignupRequest",
      id?: string | null,
      userName?: string | null,
      email?: string | null,
      clinicName?: string | null,
      clinicAddress?: string | null,
      clinicPhone?: string | null,
      terms?: Array< boolean | null > | null,
      termsVersion?: string | null,
      termsAgreedAt?: string | null,
      permission?: string | null,
      licenseNumber?: string | null,
      clinicEmail?: string | null,
      businessType?: BusinessType | null,
      createdAt?: string | null,
      approvedAt?: string | null,
      requestStatus?: SignupRequestStatus | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportQueryVariables = {
  treatmentID: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    treatmentID: string,
    sendCount?: number | null,
    byeonjeung?: string | null,
    startedAt?: string | null,
    birth?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReportsQueryVariables = {
  treatmentID?: string | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      treatmentID: string,
      sendCount?: number | null,
      byeonjeung?: string | null,
      startedAt?: string | null,
      birth?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSelfRegistrationQRQueryVariables = {
  QRID: string,
};

export type GetSelfRegistrationQRQuery = {
  getSelfRegistrationQR?:  {
    __typename: "SelfRegistrationQR",
    QRID: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    expiredDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSelfRegistrationQRSQueryVariables = {
  QRID?: string | null,
  filter?: ModelSelfRegistrationQRFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSelfRegistrationQRSQuery = {
  listSelfRegistrationQRS?:  {
    __typename: "ModelSelfRegistrationQRConnection",
    items:  Array< {
      __typename: "SelfRegistrationQR",
      QRID: string,
      clinicID: string,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        clinicName?: string | null,
        doctorName?: string | null,
        doctorNumber?: string | null,
        phone?: string | null,
        fax?: string | null,
        medicalInstitutionNumber?: string | null,
        prescriptionValidityPeriod?: number | null,
        address?: string | null,
        feedbackDate?: number | null,
        isDeleted?: boolean | null,
        pricingInfo?:  {
          __typename: "PricingInfo",
          currentPricingId?: string | null,
          nextPricingId?: string | null,
        } | null,
        stepPayID?: string | null,
        numberOfPatients?: number | null,
        numberOfDoctors?: number | null,
        numberOfSteps?: number | null,
        clinicEmail?: string | null,
        updatedAt?: string | null,
        planInfo?: string | null,
        planCheckPassFlag?: boolean | null,
        createdAt?: string | null,
        typename: string,
        config?: string | null,
        businessType?: BusinessType | null,
        promotion?: string | null,
      } | null,
      expiredDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QRByClinicQueryVariables = {
  clinicID: string,
  QRID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelfRegistrationQRFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QRByClinicQuery = {
  QRByClinic?:  {
    __typename: "ModelSelfRegistrationQRConnection",
    items:  Array< {
      __typename: "SelfRegistrationQR",
      QRID: string,
      clinicID: string,
      clinic?:  {
        __typename: "Clinic",
        id: string,
        clinicName?: string | null,
        doctorName?: string | null,
        doctorNumber?: string | null,
        phone?: string | null,
        fax?: string | null,
        medicalInstitutionNumber?: string | null,
        prescriptionValidityPeriod?: number | null,
        address?: string | null,
        feedbackDate?: number | null,
        isDeleted?: boolean | null,
        pricingInfo?:  {
          __typename: "PricingInfo",
          currentPricingId?: string | null,
          nextPricingId?: string | null,
        } | null,
        stepPayID?: string | null,
        numberOfPatients?: number | null,
        numberOfDoctors?: number | null,
        numberOfSteps?: number | null,
        clinicEmail?: string | null,
        updatedAt?: string | null,
        planInfo?: string | null,
        planCheckPassFlag?: boolean | null,
        createdAt?: string | null,
        typename: string,
        config?: string | null,
        businessType?: BusinessType | null,
        promotion?: string | null,
      } | null,
      expiredDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDailySignupCountQueryVariables = {
  date: string,
};

export type GetDailySignupCountQuery = {
  getDailySignupCount?:  {
    __typename: "DailySignupCount",
    date: string,
    count?: number | null,
    memo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDailySignupCountsQueryVariables = {
  date?: string | null,
  filter?: ModelDailySignupCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDailySignupCountsQuery = {
  listDailySignupCounts?:  {
    __typename: "ModelDailySignupCountConnection",
    items:  Array< {
      __typename: "DailySignupCount",
      date: string,
      count?: number | null,
      memo?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDailyPatientCountQueryVariables = {
  date: string,
};

export type GetDailyPatientCountQuery = {
  getDailyPatientCount?:  {
    __typename: "DailyPatientCount",
    date: string,
    count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDailyPatientCountsQueryVariables = {
  date?: string | null,
  filter?: ModelDailyPatientCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDailyPatientCountsQuery = {
  listDailyPatientCounts?:  {
    __typename: "ModelDailyPatientCountConnection",
    items:  Array< {
      __typename: "DailyPatientCount",
      date: string,
      count?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnPatientByClinicIDSubscriptionVariables = {
  clinicID: string,
};

export type OnPatientByClinicIDSubscription = {
  onPatientByClinicID?:  {
    __typename: "Patient",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    residentRegistrationNumber?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    recentActivityCode?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    registrationPath?: string | null,
    isDeleted?: boolean | null,
  } | null,
};

export type OnHistoryByPatientIDSubscriptionVariables = {
  patientID: string,
};

export type OnHistoryByPatientIDSubscription = {
  onHistoryByPatientID?:  {
    __typename: "History",
    id: string,
    clinicID: string,
    patientID: string,
    type?: string | null,
    typeID?: string | null,
    messageCode?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnTreatmentByClinicIDSubscriptionVariables = {
  clinicID: string,
};

export type OnTreatmentByClinicIDSubscription = {
  onTreatmentByClinicID?:  {
    __typename: "Treatment",
    id: string,
    clinicID: string,
    surveyID: string,
    patientID: string,
    patientData?:  {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null,
    save?: number | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    feedbackID?: string | null,
    mainSymptom?: string | null,
    userID: string,
    prescribedHerb?:  Array< {
      __typename: "PrescribedHerb",
      code?: string | null,
      amount?: number | null,
      name?: string | null,
    } | null > | null,
    status?: string | null,
    cure?: Array< string | null > | null,
    memo?: string | null,
    lastVisitedAt?: string | null,
    selectSymptom?:  Array< {
      __typename: "SelectSymptom",
      code?: string | null,
      check?: boolean | null,
    } | null > | null,
    symptomCheck?: Array< string | null > | null,
    name?: string | null,
    birth?: string | null,
    gender?: string | null,
    prescriptionStatus?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    amount?: number | null,
    isVisited?: boolean | null,
    isDeleted?: boolean | null,
    surveyCheck?: Array< string | null > | null,
    herbsForRecommanded?: Array< string | null > | null,
    treatmentedAt?: string | null,
    treatmentStatus?: TreatmentStatus | null,
    patientNameForSearching?: string | null,
    selectedBCode?: Array< string | null > | null,
    prescriptionMode?: string | null,
    prescribedRecipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    prescriptionId?: string | null,
    prescriptionData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customHerbalFormulaId?: string | null,
    customHerbalFormulaData?:  {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    prescriptionDocument?: string | null,
    footprintsLog?: Array< string | null > | null,
  } | null,
};

export type OnClinicByIDSubscriptionVariables = {
  id: string,
};

export type OnClinicByIDSubscription = {
  onClinicByID?:  {
    __typename: "Clinic",
    id: string,
    clinicName?: string | null,
    doctorName?: string | null,
    doctorNumber?: string | null,
    phone?: string | null,
    fax?: string | null,
    medicalInstitutionNumber?: string | null,
    prescriptionValidityPeriod?: number | null,
    address?: string | null,
    feedbackDate?: number | null,
    isDeleted?: boolean | null,
    pricingInfo?:  {
      __typename: "PricingInfo",
      currentPricingId?: string | null,
      nextPricingId?: string | null,
    } | null,
    stepPayID?: string | null,
    numberOfPatients?: number | null,
    numberOfDoctors?: number | null,
    numberOfSteps?: number | null,
    clinicEmail?: string | null,
    updatedAt?: string | null,
    planInfo?: string | null,
    planCheckPassFlag?: boolean | null,
    createdAt?: string | null,
    typename: string,
    config?: string | null,
    businessType?: BusinessType | null,
    promotion?: string | null,
  } | null,
};

export type OnCreateHerbSubscriptionVariables = {
  filter?: ModelSubscriptionHerbFilterInput | null,
};

export type OnCreateHerbSubscription = {
  onCreateHerb?:  {
    __typename: "Herb",
    code: string,
    herbName: string,
    nameForSearching?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateHerbSubscriptionVariables = {
  filter?: ModelSubscriptionHerbFilterInput | null,
};

export type OnUpdateHerbSubscription = {
  onUpdateHerb?:  {
    __typename: "Herb",
    code: string,
    herbName: string,
    nameForSearching?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteHerbSubscriptionVariables = {
  filter?: ModelSubscriptionHerbFilterInput | null,
};

export type OnDeleteHerbSubscription = {
  onDeleteHerb?:  {
    __typename: "Herb",
    code: string,
    herbName: string,
    nameForSearching?: Array< string | null > | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateCustomHerbalFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionCustomHerbalFormulaFilterInput | null,
};

export type OnCreateCustomHerbalFormulaSubscription = {
  onCreateCustomHerbalFormula?:  {
    __typename: "CustomHerbalFormula",
    id: string,
    ownerUserID: string,
    originalHerbalFormulaID?: string | null,
    originalHerbalFormulaData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    scoringType?: number | null,
    memo?: string | null,
    isDeleted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomHerbalFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionCustomHerbalFormulaFilterInput | null,
};

export type OnUpdateCustomHerbalFormulaSubscription = {
  onUpdateCustomHerbalFormula?:  {
    __typename: "CustomHerbalFormula",
    id: string,
    ownerUserID: string,
    originalHerbalFormulaID?: string | null,
    originalHerbalFormulaData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    scoringType?: number | null,
    memo?: string | null,
    isDeleted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomHerbalFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionCustomHerbalFormulaFilterInput | null,
};

export type OnDeleteCustomHerbalFormulaSubscription = {
  onDeleteCustomHerbalFormula?:  {
    __typename: "CustomHerbalFormula",
    id: string,
    ownerUserID: string,
    originalHerbalFormulaID?: string | null,
    originalHerbalFormulaData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    scoringType?: number | null,
    memo?: string | null,
    isDeleted?: boolean | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateHerbalFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionHerbalFormulaFilterInput | null,
};

export type OnCreateHerbalFormulaSubscription = {
  onCreateHerbalFormula?:  {
    __typename: "HerbalFormula",
    id: string,
    category?: string | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    cn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    scoringType?: number | null,
    source?: string | null,
    postfix?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateHerbalFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionHerbalFormulaFilterInput | null,
};

export type OnUpdateHerbalFormulaSubscription = {
  onUpdateHerbalFormula?:  {
    __typename: "HerbalFormula",
    id: string,
    category?: string | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    cn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    scoringType?: number | null,
    source?: string | null,
    postfix?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteHerbalFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionHerbalFormulaFilterInput | null,
};

export type OnDeleteHerbalFormulaSubscription = {
  onDeleteHerbalFormula?:  {
    __typename: "HerbalFormula",
    id: string,
    category?: string | null,
    insrCCode?: string | null,
    insrDsCode?: Array< string | null > | null,
    InsrPrscrType?: number | null,
    kn?: string | null,
    cn?: string | null,
    herbCodes?: Array< string | null > | null,
    processingCodes?: Array< string | null > | null,
    recipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    scoringType?: number | null,
    source?: string | null,
    postfix?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSurveySubscriptionVariables = {
  filter?: ModelSubscriptionSurveyFilterInput | null,
};

export type OnCreateSurveySubscription = {
  onCreateSurvey?:  {
    __typename: "Survey",
    id: string,
    otp: string,
    patientID: string,
    clinicID: string,
    link?: string | null,
    surveyResult?: string | null,
    mainSymptom?: string | null,
    symptoms?: string | null,
    visitCount?: number | null,
    firstLinkClickDt?: number | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    sendCount?: number | null,
    isSend?: boolean | null,
    treatmentID?: string | null,
    feedbacks?: string | null,
    feedbackResult?: string | null,
    feedbackAt?: string | null,
    feedbackLink?: string | null,
    feedbackDone?: boolean | null,
    isFeedback?: boolean | null,
    additionalSymptoms?: string | null,
    feedbackSendTime?: string | null,
    feedbackSchedule_GSI_PK?: string | null,
    feedbackScheduleStatus?: number | null,
    surveyDone?: boolean | null,
    surveyGrade?: number | null,
    surveySatisfaction?: string | null,
    feedbackGrade?: number | null,
    feedbackSatisfaction?: string | null,
    surveyStatus?: SurveyStatus | null,
    expiredAt?: string | null,
    surveyBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    feedbackBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    mainSymptomCategory?: string | null,
    sender?: string | null,
    surveyType?: SurveyType | null,
    insuranceType?: string | null,
    surveySurveyBizTalkHistoryId?: string | null,
    surveyFeedbackBizTalkHistoryId?: string | null,
  } | null,
};

export type OnUpdateSurveySubscriptionVariables = {
  filter?: ModelSubscriptionSurveyFilterInput | null,
};

export type OnUpdateSurveySubscription = {
  onUpdateSurvey?:  {
    __typename: "Survey",
    id: string,
    otp: string,
    patientID: string,
    clinicID: string,
    link?: string | null,
    surveyResult?: string | null,
    mainSymptom?: string | null,
    symptoms?: string | null,
    visitCount?: number | null,
    firstLinkClickDt?: number | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    sendCount?: number | null,
    isSend?: boolean | null,
    treatmentID?: string | null,
    feedbacks?: string | null,
    feedbackResult?: string | null,
    feedbackAt?: string | null,
    feedbackLink?: string | null,
    feedbackDone?: boolean | null,
    isFeedback?: boolean | null,
    additionalSymptoms?: string | null,
    feedbackSendTime?: string | null,
    feedbackSchedule_GSI_PK?: string | null,
    feedbackScheduleStatus?: number | null,
    surveyDone?: boolean | null,
    surveyGrade?: number | null,
    surveySatisfaction?: string | null,
    feedbackGrade?: number | null,
    feedbackSatisfaction?: string | null,
    surveyStatus?: SurveyStatus | null,
    expiredAt?: string | null,
    surveyBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    feedbackBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    mainSymptomCategory?: string | null,
    sender?: string | null,
    surveyType?: SurveyType | null,
    insuranceType?: string | null,
    surveySurveyBizTalkHistoryId?: string | null,
    surveyFeedbackBizTalkHistoryId?: string | null,
  } | null,
};

export type OnDeleteSurveySubscriptionVariables = {
  filter?: ModelSubscriptionSurveyFilterInput | null,
};

export type OnDeleteSurveySubscription = {
  onDeleteSurvey?:  {
    __typename: "Survey",
    id: string,
    otp: string,
    patientID: string,
    clinicID: string,
    link?: string | null,
    surveyResult?: string | null,
    mainSymptom?: string | null,
    symptoms?: string | null,
    visitCount?: number | null,
    firstLinkClickDt?: number | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    sendCount?: number | null,
    isSend?: boolean | null,
    treatmentID?: string | null,
    feedbacks?: string | null,
    feedbackResult?: string | null,
    feedbackAt?: string | null,
    feedbackLink?: string | null,
    feedbackDone?: boolean | null,
    isFeedback?: boolean | null,
    additionalSymptoms?: string | null,
    feedbackSendTime?: string | null,
    feedbackSchedule_GSI_PK?: string | null,
    feedbackScheduleStatus?: number | null,
    surveyDone?: boolean | null,
    surveyGrade?: number | null,
    surveySatisfaction?: string | null,
    feedbackGrade?: number | null,
    feedbackSatisfaction?: string | null,
    surveyStatus?: SurveyStatus | null,
    expiredAt?: string | null,
    surveyBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    feedbackBizTalkHistory?:  {
      __typename: "BiztalkHistory",
      msgIdx: string,
      type?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null,
    mainSymptomCategory?: string | null,
    sender?: string | null,
    surveyType?: SurveyType | null,
    insuranceType?: string | null,
    surveySurveyBizTalkHistoryId?: string | null,
    surveyFeedbackBizTalkHistoryId?: string | null,
  } | null,
};

export type OnCreateHistorySubscriptionVariables = {
  filter?: ModelSubscriptionHistoryFilterInput | null,
};

export type OnCreateHistorySubscription = {
  onCreateHistory?:  {
    __typename: "History",
    id: string,
    clinicID: string,
    patientID: string,
    type?: string | null,
    typeID?: string | null,
    messageCode?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateHistorySubscriptionVariables = {
  filter?: ModelSubscriptionHistoryFilterInput | null,
};

export type OnUpdateHistorySubscription = {
  onUpdateHistory?:  {
    __typename: "History",
    id: string,
    clinicID: string,
    patientID: string,
    type?: string | null,
    typeID?: string | null,
    messageCode?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteHistorySubscriptionVariables = {
  filter?: ModelSubscriptionHistoryFilterInput | null,
};

export type OnDeleteHistorySubscription = {
  onDeleteHistory?:  {
    __typename: "History",
    id: string,
    clinicID: string,
    patientID: string,
    type?: string | null,
    typeID?: string | null,
    messageCode?: string | null,
    message?: string | null,
    createdAt?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreatePatientSubscriptionVariables = {
  filter?: ModelSubscriptionPatientFilterInput | null,
};

export type OnCreatePatientSubscription = {
  onCreatePatient?:  {
    __typename: "Patient",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    residentRegistrationNumber?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    recentActivityCode?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    registrationPath?: string | null,
    isDeleted?: boolean | null,
  } | null,
};

export type OnUpdatePatientSubscriptionVariables = {
  filter?: ModelSubscriptionPatientFilterInput | null,
};

export type OnUpdatePatientSubscription = {
  onUpdatePatient?:  {
    __typename: "Patient",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    residentRegistrationNumber?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    recentActivityCode?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    registrationPath?: string | null,
    isDeleted?: boolean | null,
  } | null,
};

export type OnDeletePatientSubscriptionVariables = {
  filter?: ModelSubscriptionPatientFilterInput | null,
};

export type OnDeletePatientSubscription = {
  onDeletePatient?:  {
    __typename: "Patient",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    residentRegistrationNumber?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    recentActivityCode?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    registrationPath?: string | null,
    isDeleted?: boolean | null,
  } | null,
};

export type OnCreatePatientDeletedSubscriptionVariables = {
  filter?: ModelSubscriptionPatientDeletedFilterInput | null,
};

export type OnCreatePatientDeletedSubscription = {
  onCreatePatientDeleted?:  {
    __typename: "PatientDeleted",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    residentRegistrationNumber?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    type: string,
    privacyTerms?: string | null,
  } | null,
};

export type OnUpdatePatientDeletedSubscriptionVariables = {
  filter?: ModelSubscriptionPatientDeletedFilterInput | null,
};

export type OnUpdatePatientDeletedSubscription = {
  onUpdatePatientDeleted?:  {
    __typename: "PatientDeleted",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    residentRegistrationNumber?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    type: string,
    privacyTerms?: string | null,
  } | null,
};

export type OnDeletePatientDeletedSubscriptionVariables = {
  filter?: ModelSubscriptionPatientDeletedFilterInput | null,
};

export type OnDeletePatientDeletedSubscription = {
  onDeletePatientDeleted?:  {
    __typename: "PatientDeleted",
    id: string,
    clinicID: string,
    patientName?: string | null,
    birth?: string | null,
    gender?: Gender | null,
    phone?: string | null,
    residentRegistrationNumber?: string | null,
    recentActivity?: string | null,
    recentActivityMessage?: string | null,
    createdAt?: string | null,
    prescriptionStatus?: number | null,
    isWaiting?: boolean | null,
    isVisited?: boolean | null,
    symptomID?: string | null,
    mainSymptom?: string | null,
    prescriptionID?: string | null,
    surveyID?: string | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    userID?: string | null,
    treatmentID?: string | null,
    patientNameForSorting?: string | null,
    surveyedAt?: string | null,
    updatedAt?: string | null,
    treatmentStatus?: number | null,
    treatmentStatusDateForSorting?: string | null,
    address?: string | null,
    type: string,
    privacyTerms?: string | null,
  } | null,
};

export type OnCreateUserRoleSubscriptionVariables = {
  filter?: ModelSubscriptionUserRoleFilterInput | null,
};

export type OnCreateUserRoleSubscription = {
  onCreateUserRole?:  {
    __typename: "UserRole",
    id: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    config?: string | null,
    clinicName?: string | null,
    status?: string | null,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      username?: string | null,
      phone?: string | null,
      terms?: Array< boolean | null > | null,
      termsVersion?: string | null,
      termsAgreedAt?: string | null,
      createdAt?: string | null,
      isDeleted?: boolean | null,
      roleName?: string | null,
      config?: string | null,
      licenseNumber?: string | null,
      licensePendingReviewFlag?: boolean | null,
      updatedAt: string,
    } | null,
    roleName: string,
    userName: string,
    isDeleted?: boolean | null,
    testFlag?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserRoleSubscriptionVariables = {
  filter?: ModelSubscriptionUserRoleFilterInput | null,
};

export type OnUpdateUserRoleSubscription = {
  onUpdateUserRole?:  {
    __typename: "UserRole",
    id: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    config?: string | null,
    clinicName?: string | null,
    status?: string | null,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      username?: string | null,
      phone?: string | null,
      terms?: Array< boolean | null > | null,
      termsVersion?: string | null,
      termsAgreedAt?: string | null,
      createdAt?: string | null,
      isDeleted?: boolean | null,
      roleName?: string | null,
      config?: string | null,
      licenseNumber?: string | null,
      licensePendingReviewFlag?: boolean | null,
      updatedAt: string,
    } | null,
    roleName: string,
    userName: string,
    isDeleted?: boolean | null,
    testFlag?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserRoleSubscriptionVariables = {
  filter?: ModelSubscriptionUserRoleFilterInput | null,
};

export type OnDeleteUserRoleSubscription = {
  onDeleteUserRole?:  {
    __typename: "UserRole",
    id: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    config?: string | null,
    clinicName?: string | null,
    status?: string | null,
    email?: string | null,
    phone?: string | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      username?: string | null,
      phone?: string | null,
      terms?: Array< boolean | null > | null,
      termsVersion?: string | null,
      termsAgreedAt?: string | null,
      createdAt?: string | null,
      isDeleted?: boolean | null,
      roleName?: string | null,
      config?: string | null,
      licenseNumber?: string | null,
      licensePendingReviewFlag?: boolean | null,
      updatedAt: string,
    } | null,
    roleName: string,
    userName: string,
    isDeleted?: boolean | null,
    testFlag?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    username?: string | null,
    phone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    createdAt?: string | null,
    isDeleted?: boolean | null,
    roleName?: string | null,
    config?: string | null,
    licenseNumber?: string | null,
    licensePendingReviewFlag?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    username?: string | null,
    phone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    createdAt?: string | null,
    isDeleted?: boolean | null,
    roleName?: string | null,
    config?: string | null,
    licenseNumber?: string | null,
    licensePendingReviewFlag?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    username?: string | null,
    phone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    createdAt?: string | null,
    isDeleted?: boolean | null,
    roleName?: string | null,
    config?: string | null,
    licenseNumber?: string | null,
    licensePendingReviewFlag?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnCreateUserPoolSubscriptionVariables = {
  filter?: ModelSubscriptionUserPoolFilterInput | null,
};

export type OnCreateUserPoolSubscription = {
  onCreateUserPool?:  {
    __typename: "UserPool",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserPoolSubscriptionVariables = {
  filter?: ModelSubscriptionUserPoolFilterInput | null,
};

export type OnUpdateUserPoolSubscription = {
  onUpdateUserPool?:  {
    __typename: "UserPool",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserPoolSubscriptionVariables = {
  filter?: ModelSubscriptionUserPoolFilterInput | null,
};

export type OnDeleteUserPoolSubscription = {
  onDeleteUserPool?:  {
    __typename: "UserPool",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRoleSubscriptionVariables = {
  filter?: ModelSubscriptionRoleFilterInput | null,
};

export type OnCreateRoleSubscription = {
  onCreateRole?:  {
    __typename: "Role",
    id: string,
    clinicID: string,
    roleName?: string | null,
    permisstions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRoleSubscriptionVariables = {
  filter?: ModelSubscriptionRoleFilterInput | null,
};

export type OnUpdateRoleSubscription = {
  onUpdateRole?:  {
    __typename: "Role",
    id: string,
    clinicID: string,
    roleName?: string | null,
    permisstions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRoleSubscriptionVariables = {
  filter?: ModelSubscriptionRoleFilterInput | null,
};

export type OnDeleteRoleSubscription = {
  onDeleteRole?:  {
    __typename: "Role",
    id: string,
    clinicID: string,
    roleName?: string | null,
    permisstions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClinicsForPromotionSubscriptionVariables = {
  filter?: ModelSubscriptionClinicsForPromotionFilterInput | null,
};

export type OnCreateClinicsForPromotionSubscription = {
  onCreateClinicsForPromotion?:  {
    __typename: "ClinicsForPromotion",
    id: string,
    promotionType?: string | null,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClinicsForPromotionSubscriptionVariables = {
  filter?: ModelSubscriptionClinicsForPromotionFilterInput | null,
};

export type OnUpdateClinicsForPromotionSubscription = {
  onUpdateClinicsForPromotion?:  {
    __typename: "ClinicsForPromotion",
    id: string,
    promotionType?: string | null,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClinicsForPromotionSubscriptionVariables = {
  filter?: ModelSubscriptionClinicsForPromotionFilterInput | null,
};

export type OnDeleteClinicsForPromotionSubscription = {
  onDeleteClinicsForPromotion?:  {
    __typename: "ClinicsForPromotion",
    id: string,
    promotionType?: string | null,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClinicSubscriptionVariables = {
  filter?: ModelSubscriptionClinicFilterInput | null,
};

export type OnCreateClinicSubscription = {
  onCreateClinic?:  {
    __typename: "Clinic",
    id: string,
    clinicName?: string | null,
    doctorName?: string | null,
    doctorNumber?: string | null,
    phone?: string | null,
    fax?: string | null,
    medicalInstitutionNumber?: string | null,
    prescriptionValidityPeriod?: number | null,
    address?: string | null,
    feedbackDate?: number | null,
    isDeleted?: boolean | null,
    pricingInfo?:  {
      __typename: "PricingInfo",
      currentPricingId?: string | null,
      nextPricingId?: string | null,
    } | null,
    stepPayID?: string | null,
    numberOfPatients?: number | null,
    numberOfDoctors?: number | null,
    numberOfSteps?: number | null,
    clinicEmail?: string | null,
    updatedAt?: string | null,
    planInfo?: string | null,
    planCheckPassFlag?: boolean | null,
    createdAt?: string | null,
    typename: string,
    config?: string | null,
    businessType?: BusinessType | null,
    promotion?: string | null,
  } | null,
};

export type OnUpdateClinicSubscriptionVariables = {
  filter?: ModelSubscriptionClinicFilterInput | null,
};

export type OnUpdateClinicSubscription = {
  onUpdateClinic?:  {
    __typename: "Clinic",
    id: string,
    clinicName?: string | null,
    doctorName?: string | null,
    doctorNumber?: string | null,
    phone?: string | null,
    fax?: string | null,
    medicalInstitutionNumber?: string | null,
    prescriptionValidityPeriod?: number | null,
    address?: string | null,
    feedbackDate?: number | null,
    isDeleted?: boolean | null,
    pricingInfo?:  {
      __typename: "PricingInfo",
      currentPricingId?: string | null,
      nextPricingId?: string | null,
    } | null,
    stepPayID?: string | null,
    numberOfPatients?: number | null,
    numberOfDoctors?: number | null,
    numberOfSteps?: number | null,
    clinicEmail?: string | null,
    updatedAt?: string | null,
    planInfo?: string | null,
    planCheckPassFlag?: boolean | null,
    createdAt?: string | null,
    typename: string,
    config?: string | null,
    businessType?: BusinessType | null,
    promotion?: string | null,
  } | null,
};

export type OnDeleteClinicSubscriptionVariables = {
  filter?: ModelSubscriptionClinicFilterInput | null,
};

export type OnDeleteClinicSubscription = {
  onDeleteClinic?:  {
    __typename: "Clinic",
    id: string,
    clinicName?: string | null,
    doctorName?: string | null,
    doctorNumber?: string | null,
    phone?: string | null,
    fax?: string | null,
    medicalInstitutionNumber?: string | null,
    prescriptionValidityPeriod?: number | null,
    address?: string | null,
    feedbackDate?: number | null,
    isDeleted?: boolean | null,
    pricingInfo?:  {
      __typename: "PricingInfo",
      currentPricingId?: string | null,
      nextPricingId?: string | null,
    } | null,
    stepPayID?: string | null,
    numberOfPatients?: number | null,
    numberOfDoctors?: number | null,
    numberOfSteps?: number | null,
    clinicEmail?: string | null,
    updatedAt?: string | null,
    planInfo?: string | null,
    planCheckPassFlag?: boolean | null,
    createdAt?: string | null,
    typename: string,
    config?: string | null,
    businessType?: BusinessType | null,
    promotion?: string | null,
  } | null,
};

export type OnCreateTreatmentSubscriptionVariables = {
  filter?: ModelSubscriptionTreatmentFilterInput | null,
};

export type OnCreateTreatmentSubscription = {
  onCreateTreatment?:  {
    __typename: "Treatment",
    id: string,
    clinicID: string,
    surveyID: string,
    patientID: string,
    patientData?:  {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null,
    save?: number | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    feedbackID?: string | null,
    mainSymptom?: string | null,
    userID: string,
    prescribedHerb?:  Array< {
      __typename: "PrescribedHerb",
      code?: string | null,
      amount?: number | null,
      name?: string | null,
    } | null > | null,
    status?: string | null,
    cure?: Array< string | null > | null,
    memo?: string | null,
    lastVisitedAt?: string | null,
    selectSymptom?:  Array< {
      __typename: "SelectSymptom",
      code?: string | null,
      check?: boolean | null,
    } | null > | null,
    symptomCheck?: Array< string | null > | null,
    name?: string | null,
    birth?: string | null,
    gender?: string | null,
    prescriptionStatus?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    amount?: number | null,
    isVisited?: boolean | null,
    isDeleted?: boolean | null,
    surveyCheck?: Array< string | null > | null,
    herbsForRecommanded?: Array< string | null > | null,
    treatmentedAt?: string | null,
    treatmentStatus?: TreatmentStatus | null,
    patientNameForSearching?: string | null,
    selectedBCode?: Array< string | null > | null,
    prescriptionMode?: string | null,
    prescribedRecipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    prescriptionId?: string | null,
    prescriptionData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customHerbalFormulaId?: string | null,
    customHerbalFormulaData?:  {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    prescriptionDocument?: string | null,
    footprintsLog?: Array< string | null > | null,
  } | null,
};

export type OnUpdateTreatmentSubscriptionVariables = {
  filter?: ModelSubscriptionTreatmentFilterInput | null,
};

export type OnUpdateTreatmentSubscription = {
  onUpdateTreatment?:  {
    __typename: "Treatment",
    id: string,
    clinicID: string,
    surveyID: string,
    patientID: string,
    patientData?:  {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null,
    save?: number | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    feedbackID?: string | null,
    mainSymptom?: string | null,
    userID: string,
    prescribedHerb?:  Array< {
      __typename: "PrescribedHerb",
      code?: string | null,
      amount?: number | null,
      name?: string | null,
    } | null > | null,
    status?: string | null,
    cure?: Array< string | null > | null,
    memo?: string | null,
    lastVisitedAt?: string | null,
    selectSymptom?:  Array< {
      __typename: "SelectSymptom",
      code?: string | null,
      check?: boolean | null,
    } | null > | null,
    symptomCheck?: Array< string | null > | null,
    name?: string | null,
    birth?: string | null,
    gender?: string | null,
    prescriptionStatus?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    amount?: number | null,
    isVisited?: boolean | null,
    isDeleted?: boolean | null,
    surveyCheck?: Array< string | null > | null,
    herbsForRecommanded?: Array< string | null > | null,
    treatmentedAt?: string | null,
    treatmentStatus?: TreatmentStatus | null,
    patientNameForSearching?: string | null,
    selectedBCode?: Array< string | null > | null,
    prescriptionMode?: string | null,
    prescribedRecipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    prescriptionId?: string | null,
    prescriptionData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customHerbalFormulaId?: string | null,
    customHerbalFormulaData?:  {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    prescriptionDocument?: string | null,
    footprintsLog?: Array< string | null > | null,
  } | null,
};

export type OnDeleteTreatmentSubscriptionVariables = {
  filter?: ModelSubscriptionTreatmentFilterInput | null,
};

export type OnDeleteTreatmentSubscription = {
  onDeleteTreatment?:  {
    __typename: "Treatment",
    id: string,
    clinicID: string,
    surveyID: string,
    patientID: string,
    patientData?:  {
      __typename: "Patient",
      id: string,
      clinicID: string,
      patientName?: string | null,
      birth?: string | null,
      residentRegistrationNumber?: string | null,
      gender?: Gender | null,
      phone?: string | null,
      recentActivity?: string | null,
      recentActivityMessage?: string | null,
      recentActivityCode?: string | null,
      createdAt?: string | null,
      prescriptionStatus?: number | null,
      isWaiting?: boolean | null,
      isVisited?: boolean | null,
      symptomID?: string | null,
      mainSymptom?: string | null,
      prescriptionID?: string | null,
      surveyID?: string | null,
      survey?:  {
        __typename: "Survey",
        id: string,
        otp: string,
        patientID: string,
        clinicID: string,
        link?: string | null,
        surveyResult?: string | null,
        mainSymptom?: string | null,
        symptoms?: string | null,
        visitCount?: number | null,
        firstLinkClickDt?: number | null,
        surveyedAt?: string | null,
        updatedAt?: string | null,
        createdAt?: string | null,
        sendCount?: number | null,
        isSend?: boolean | null,
        treatmentID?: string | null,
        feedbacks?: string | null,
        feedbackResult?: string | null,
        feedbackAt?: string | null,
        feedbackLink?: string | null,
        feedbackDone?: boolean | null,
        isFeedback?: boolean | null,
        additionalSymptoms?: string | null,
        feedbackSendTime?: string | null,
        feedbackSchedule_GSI_PK?: string | null,
        feedbackScheduleStatus?: number | null,
        surveyDone?: boolean | null,
        surveyGrade?: number | null,
        surveySatisfaction?: string | null,
        feedbackGrade?: number | null,
        feedbackSatisfaction?: string | null,
        surveyStatus?: SurveyStatus | null,
        expiredAt?: string | null,
        surveyBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        feedbackBizTalkHistory?:  {
          __typename: "BiztalkHistory",
          msgIdx: string,
          type?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        mainSymptomCategory?: string | null,
        sender?: string | null,
        surveyType?: SurveyType | null,
        insuranceType?: string | null,
        surveySurveyBizTalkHistoryId?: string | null,
        surveyFeedbackBizTalkHistoryId?: string | null,
      } | null,
      userID?: string | null,
      treatmentID?: string | null,
      patientNameForSorting?: string | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      treatmentStatus?: number | null,
      treatmentStatusDateForSorting?: string | null,
      address?: string | null,
      registrationPath?: string | null,
      isDeleted?: boolean | null,
    } | null,
    save?: number | null,
    survey?:  {
      __typename: "Survey",
      id: string,
      otp: string,
      patientID: string,
      clinicID: string,
      link?: string | null,
      surveyResult?: string | null,
      mainSymptom?: string | null,
      symptoms?: string | null,
      visitCount?: number | null,
      firstLinkClickDt?: number | null,
      surveyedAt?: string | null,
      updatedAt?: string | null,
      createdAt?: string | null,
      sendCount?: number | null,
      isSend?: boolean | null,
      treatmentID?: string | null,
      feedbacks?: string | null,
      feedbackResult?: string | null,
      feedbackAt?: string | null,
      feedbackLink?: string | null,
      feedbackDone?: boolean | null,
      isFeedback?: boolean | null,
      additionalSymptoms?: string | null,
      feedbackSendTime?: string | null,
      feedbackSchedule_GSI_PK?: string | null,
      feedbackScheduleStatus?: number | null,
      surveyDone?: boolean | null,
      surveyGrade?: number | null,
      surveySatisfaction?: string | null,
      feedbackGrade?: number | null,
      feedbackSatisfaction?: string | null,
      surveyStatus?: SurveyStatus | null,
      expiredAt?: string | null,
      surveyBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      feedbackBizTalkHistory?:  {
        __typename: "BiztalkHistory",
        msgIdx: string,
        type?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | null,
      mainSymptomCategory?: string | null,
      sender?: string | null,
      surveyType?: SurveyType | null,
      insuranceType?: string | null,
      surveySurveyBizTalkHistoryId?: string | null,
      surveyFeedbackBizTalkHistoryId?: string | null,
    } | null,
    feedbackID?: string | null,
    mainSymptom?: string | null,
    userID: string,
    prescribedHerb?:  Array< {
      __typename: "PrescribedHerb",
      code?: string | null,
      amount?: number | null,
      name?: string | null,
    } | null > | null,
    status?: string | null,
    cure?: Array< string | null > | null,
    memo?: string | null,
    lastVisitedAt?: string | null,
    selectSymptom?:  Array< {
      __typename: "SelectSymptom",
      code?: string | null,
      check?: boolean | null,
    } | null > | null,
    symptomCheck?: Array< string | null > | null,
    name?: string | null,
    birth?: string | null,
    gender?: string | null,
    prescriptionStatus?: number | null,
    updatedAt?: string | null,
    createdAt?: string | null,
    amount?: number | null,
    isVisited?: boolean | null,
    isDeleted?: boolean | null,
    surveyCheck?: Array< string | null > | null,
    herbsForRecommanded?: Array< string | null > | null,
    treatmentedAt?: string | null,
    treatmentStatus?: TreatmentStatus | null,
    patientNameForSearching?: string | null,
    selectedBCode?: Array< string | null > | null,
    prescriptionMode?: string | null,
    prescribedRecipe?:  Array< {
      __typename: "PR",
      pc?: string | null,
      amt?: number | null,
    } | null > | null,
    prescriptionId?: string | null,
    prescriptionData?:  {
      __typename: "HerbalFormula",
      id: string,
      category?: string | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      cn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      gun?: Array< string | null > | null,
      sin?: Array< string | null > | null,
      scoringType?: number | null,
      source?: string | null,
      postfix?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    customHerbalFormulaId?: string | null,
    customHerbalFormulaData?:  {
      __typename: "CustomHerbalFormula",
      id: string,
      ownerUserID: string,
      originalHerbalFormulaID?: string | null,
      originalHerbalFormulaData?:  {
        __typename: "HerbalFormula",
        id: string,
        category?: string | null,
        insrCCode?: string | null,
        insrDsCode?: Array< string | null > | null,
        InsrPrscrType?: number | null,
        kn?: string | null,
        cn?: string | null,
        herbCodes?: Array< string | null > | null,
        processingCodes?: Array< string | null > | null,
        recipe?:  Array< {
          __typename: "PR",
          pc?: string | null,
          amt?: number | null,
        } | null > | null,
        gun?: Array< string | null > | null,
        sin?: Array< string | null > | null,
        scoringType?: number | null,
        source?: string | null,
        postfix?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      insrCCode?: string | null,
      insrDsCode?: Array< string | null > | null,
      InsrPrscrType?: number | null,
      kn?: string | null,
      herbCodes?: Array< string | null > | null,
      processingCodes?: Array< string | null > | null,
      recipe?:  Array< {
        __typename: "PR",
        pc?: string | null,
        amt?: number | null,
      } | null > | null,
      scoringType?: number | null,
      memo?: string | null,
      isDeleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
    } | null,
    prescriptionDocument?: string | null,
    footprintsLog?: Array< string | null > | null,
  } | null,
};

export type OnCreateFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionFormulaFilterInput | null,
};

export type OnCreateFormulaSubscription = {
  onCreateFormula?:  {
    __typename: "Formula",
    id: string,
    formulaName?: string | null,
    chineseName?: string | null,
    herbs?: Array< string | null > | null,
    amount?: Array< number | null > | null,
    primaryCare?: Array< string | null > | null,
    tag?: Array< string | null > | null,
    category?: string | null,
    priority?: number | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionFormulaFilterInput | null,
};

export type OnUpdateFormulaSubscription = {
  onUpdateFormula?:  {
    __typename: "Formula",
    id: string,
    formulaName?: string | null,
    chineseName?: string | null,
    herbs?: Array< string | null > | null,
    amount?: Array< number | null > | null,
    primaryCare?: Array< string | null > | null,
    tag?: Array< string | null > | null,
    category?: string | null,
    priority?: number | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFormulaSubscriptionVariables = {
  filter?: ModelSubscriptionFormulaFilterInput | null,
};

export type OnDeleteFormulaSubscription = {
  onDeleteFormula?:  {
    __typename: "Formula",
    id: string,
    formulaName?: string | null,
    chineseName?: string | null,
    herbs?: Array< string | null > | null,
    amount?: Array< number | null > | null,
    primaryCare?: Array< string | null > | null,
    tag?: Array< string | null > | null,
    category?: string | null,
    priority?: number | null,
    gun?: Array< string | null > | null,
    sin?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBiztalkHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBiztalkHistoryFilterInput | null,
};

export type OnCreateBiztalkHistorySubscription = {
  onCreateBiztalkHistory?:  {
    __typename: "BiztalkHistory",
    msgIdx: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnUpdateBiztalkHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBiztalkHistoryFilterInput | null,
};

export type OnUpdateBiztalkHistorySubscription = {
  onUpdateBiztalkHistory?:  {
    __typename: "BiztalkHistory",
    msgIdx: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnDeleteBiztalkHistorySubscriptionVariables = {
  filter?: ModelSubscriptionBiztalkHistoryFilterInput | null,
};

export type OnDeleteBiztalkHistorySubscription = {
  onDeleteBiztalkHistory?:  {
    __typename: "BiztalkHistory",
    msgIdx: string,
    type?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type OnCreateYejinSignupRequestSubscriptionVariables = {
  filter?: ModelSubscriptionYejinSignupRequestFilterInput | null,
};

export type OnCreateYejinSignupRequestSubscription = {
  onCreateYejinSignupRequest?:  {
    __typename: "YejinSignupRequest",
    id?: string | null,
    userName?: string | null,
    email?: string | null,
    clinicName?: string | null,
    clinicAddress?: string | null,
    clinicPhone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    permission?: string | null,
    licenseNumber?: string | null,
    clinicEmail?: string | null,
    businessType?: BusinessType | null,
    createdAt?: string | null,
    approvedAt?: string | null,
    requestStatus?: SignupRequestStatus | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateYejinSignupRequestSubscriptionVariables = {
  filter?: ModelSubscriptionYejinSignupRequestFilterInput | null,
};

export type OnUpdateYejinSignupRequestSubscription = {
  onUpdateYejinSignupRequest?:  {
    __typename: "YejinSignupRequest",
    id?: string | null,
    userName?: string | null,
    email?: string | null,
    clinicName?: string | null,
    clinicAddress?: string | null,
    clinicPhone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    permission?: string | null,
    licenseNumber?: string | null,
    clinicEmail?: string | null,
    businessType?: BusinessType | null,
    createdAt?: string | null,
    approvedAt?: string | null,
    requestStatus?: SignupRequestStatus | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteYejinSignupRequestSubscriptionVariables = {
  filter?: ModelSubscriptionYejinSignupRequestFilterInput | null,
};

export type OnDeleteYejinSignupRequestSubscription = {
  onDeleteYejinSignupRequest?:  {
    __typename: "YejinSignupRequest",
    id?: string | null,
    userName?: string | null,
    email?: string | null,
    clinicName?: string | null,
    clinicAddress?: string | null,
    clinicPhone?: string | null,
    terms?: Array< boolean | null > | null,
    termsVersion?: string | null,
    termsAgreedAt?: string | null,
    permission?: string | null,
    licenseNumber?: string | null,
    clinicEmail?: string | null,
    businessType?: BusinessType | null,
    createdAt?: string | null,
    approvedAt?: string | null,
    requestStatus?: SignupRequestStatus | null,
    updatedAt: string,
  } | null,
};

export type OnCreateReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnCreateReportSubscription = {
  onCreateReport?:  {
    __typename: "Report",
    treatmentID: string,
    sendCount?: number | null,
    byeonjeung?: string | null,
    startedAt?: string | null,
    birth?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnUpdateReportSubscription = {
  onUpdateReport?:  {
    __typename: "Report",
    treatmentID: string,
    sendCount?: number | null,
    byeonjeung?: string | null,
    startedAt?: string | null,
    birth?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReportSubscriptionVariables = {
  filter?: ModelSubscriptionReportFilterInput | null,
};

export type OnDeleteReportSubscription = {
  onDeleteReport?:  {
    __typename: "Report",
    treatmentID: string,
    sendCount?: number | null,
    byeonjeung?: string | null,
    startedAt?: string | null,
    birth?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSelfRegistrationQRSubscriptionVariables = {
  filter?: ModelSubscriptionSelfRegistrationQRFilterInput | null,
};

export type OnCreateSelfRegistrationQRSubscription = {
  onCreateSelfRegistrationQR?:  {
    __typename: "SelfRegistrationQR",
    QRID: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    expiredDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSelfRegistrationQRSubscriptionVariables = {
  filter?: ModelSubscriptionSelfRegistrationQRFilterInput | null,
};

export type OnUpdateSelfRegistrationQRSubscription = {
  onUpdateSelfRegistrationQR?:  {
    __typename: "SelfRegistrationQR",
    QRID: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    expiredDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSelfRegistrationQRSubscriptionVariables = {
  filter?: ModelSubscriptionSelfRegistrationQRFilterInput | null,
};

export type OnDeleteSelfRegistrationQRSubscription = {
  onDeleteSelfRegistrationQR?:  {
    __typename: "SelfRegistrationQR",
    QRID: string,
    clinicID: string,
    clinic?:  {
      __typename: "Clinic",
      id: string,
      clinicName?: string | null,
      doctorName?: string | null,
      doctorNumber?: string | null,
      phone?: string | null,
      fax?: string | null,
      medicalInstitutionNumber?: string | null,
      prescriptionValidityPeriod?: number | null,
      address?: string | null,
      feedbackDate?: number | null,
      isDeleted?: boolean | null,
      pricingInfo?:  {
        __typename: "PricingInfo",
        currentPricingId?: string | null,
        nextPricingId?: string | null,
      } | null,
      stepPayID?: string | null,
      numberOfPatients?: number | null,
      numberOfDoctors?: number | null,
      numberOfSteps?: number | null,
      clinicEmail?: string | null,
      updatedAt?: string | null,
      planInfo?: string | null,
      planCheckPassFlag?: boolean | null,
      createdAt?: string | null,
      typename: string,
      config?: string | null,
      businessType?: BusinessType | null,
      promotion?: string | null,
    } | null,
    expiredDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDailySignupCountSubscriptionVariables = {
  filter?: ModelSubscriptionDailySignupCountFilterInput | null,
};

export type OnCreateDailySignupCountSubscription = {
  onCreateDailySignupCount?:  {
    __typename: "DailySignupCount",
    date: string,
    count?: number | null,
    memo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDailySignupCountSubscriptionVariables = {
  filter?: ModelSubscriptionDailySignupCountFilterInput | null,
};

export type OnUpdateDailySignupCountSubscription = {
  onUpdateDailySignupCount?:  {
    __typename: "DailySignupCount",
    date: string,
    count?: number | null,
    memo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDailySignupCountSubscriptionVariables = {
  filter?: ModelSubscriptionDailySignupCountFilterInput | null,
};

export type OnDeleteDailySignupCountSubscription = {
  onDeleteDailySignupCount?:  {
    __typename: "DailySignupCount",
    date: string,
    count?: number | null,
    memo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDailyPatientCountSubscriptionVariables = {
  filter?: ModelSubscriptionDailyPatientCountFilterInput | null,
};

export type OnCreateDailyPatientCountSubscription = {
  onCreateDailyPatientCount?:  {
    __typename: "DailyPatientCount",
    date: string,
    count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDailyPatientCountSubscriptionVariables = {
  filter?: ModelSubscriptionDailyPatientCountFilterInput | null,
};

export type OnUpdateDailyPatientCountSubscription = {
  onUpdateDailyPatientCount?:  {
    __typename: "DailyPatientCount",
    date: string,
    count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDailyPatientCountSubscriptionVariables = {
  filter?: ModelSubscriptionDailyPatientCountFilterInput | null,
};

export type OnDeleteDailyPatientCountSubscription = {
  onDeleteDailyPatientCount?:  {
    __typename: "DailyPatientCount",
    date: string,
    count?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
