/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { TreatmentStatus } from 'constant';
import { TypographyText, Row, Modal } from 'components/UI/atoms';
import SymptomTable from 'components/UI/molecules/table/commonSymptomTable';

import { selectSurvey } from 'utils/util';

const TreatmentHistoryDetail = ({
  surveyData: s = {},
  patientData: p = {},
  copySymptom,
}: {
  surveyData: any;
  patientData: any;
  copySymptom: (survey: any) => Promise<void>;
}) => {
  const [isOpenCopySymptomModal, setIsOpenCopySymptomModal] = useState(false);

  const patientDeletedFlag = useMemo(() => (p?.isDeleted ? true : false), [p]);
  const treatmentProgressingFlag = useMemo(() => p?.treatmentStatus === TreatmentStatus.InProgress, [p]);
  const surveyIdForTreatment = useMemo(() => s.id === p?.surveyID, [s]);
  const treatmentStatusBgColor = useMemo(() => {
    switch (p?.treatmentStatus) {
      case TreatmentStatus.SurveySubmit:
      case TreatmentStatus.InProgress:
      case TreatmentStatus.None:
      case TreatmentStatus.SurveyWaiting:
      default:
        return '#DAE8FA';
    }
  }, [p?.treatmentStatus]);

  const treatmentStatusText = useMemo(() => {
    switch (p?.treatmentStatus) {
      case TreatmentStatus.SurveySubmit:
        return '진료대기';
      case TreatmentStatus.InProgress:
        return '진료중';
      case TreatmentStatus.None:
      case TreatmentStatus.SurveyWaiting:
      default:
        return '노출되면 안됨(bug)';
    }
  }, [p?.treatmentStatus]);

  return (
    <>
      {!_.isEmpty(s) && (
        <>
          <Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '2rem 2rem 1.25rem 2rem',
              boxShadow: '0px 4px 4px 0px #E5E5E580',
              border: '1px solid #E5E5E5',
              borderRadius: '3px',
              background: '#ffffff',
              rowGap: '2rem',
            }}
          >
            {surveyIdForTreatment && (
              <Row
                style={{
                  background: treatmentStatusBgColor,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '1.8rem',
                  width: '5rem',
                }}
              >
                <TypographyText fontSize="0.8rem" lineHeight="1.2em" fontWeight={700} color="#346AFF">
                  {treatmentStatusText}
                </TypographyText>
              </Row>
            )}
            <Row
              style={{ marginTop: '-0.75rem', display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}
            >
              <Row
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Row style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                  <TypographyText
                    fontSize={'1.2rem'}
                    lineHeight="1.2em"
                    fontWeight={700}
                    whiteSpace="pre-wrap"
                    wordBreak="keep-all"
                  >
                    {`${moment(s.surveyedAt).format('YY/MM/DD')} 설문내역`}
                  </TypographyText>
                  <TypographyText fontSize={'0.8rem'} lineHeight="1.2em" color={'#AFAFAF'} whiteSpace="pre">
                    {`${moment(s.surveyedAt).format('YYYY/MM/DD HH:mm')} 설문입력`}
                  </TypographyText>
                </Row>
                {!patientDeletedFlag && (
                  <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <button
                      style={{
                        padding: '0.45rem 0.75rem',
                        minWidth: '6rem',
                        height: '2rem',
                        background: '#ffffff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #afafaf',
                        borderRadius: '3px',
                      }}
                      className={treatmentProgressingFlag ? 'disabledButton' : ''}
                      disabled={treatmentProgressingFlag}
                      onClick={() => {
                        setIsOpenCopySymptomModal(true);
                      }}
                    >
                      <TypographyText
                        color={treatmentProgressingFlag ? '#afafaf' : '#1d1c1d'}
                        fontWeight={700}
                        fontSize={'0.8rem'}
                        lineHeight="1.2em"
                        whiteSpace="pre"
                      >
                        {'해당 증상으로 진료실 등록'}
                      </TypographyText>
                    </button>
                    {treatmentProgressingFlag && (
                      <Row style={{ marginTop: '-0.1rem', marginBottom: '-0.6rem' }}>
                        <TypographyText
                          color={'#DF635C'}
                          fontWeight={700}
                          fontSize={'0.8rem'}
                          lineHeight="1em"
                          whiteSpace="pre"
                        >
                          {'진료 중에는 증상 복사가 불가합니다. 진료를 완료해 주세요.'}
                        </TypographyText>
                      </Row>
                    )}
                  </Row>
                )}
              </Row>
              <SymptomTable survey={s} symptoms={selectSurvey(s.symptoms)} mode="patientSymptom" />
            </Row>
          </Row>
          <Modal
            isOpen={isOpenCopySymptomModal}
            contentMsg={`해당 증상으로 진료실에 등록하시겠습니까?`}
            cancelMsg="취소"
            okMsg="네, 등록하겠습니다."
            okFunction={async () => {
              await copySymptom(s);
              setIsOpenCopySymptomModal(false);
            }}
            cancelFunction={() => {
              setIsOpenCopySymptomModal(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default TreatmentHistoryDetail;
