import { API, Auth, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import {
  GetHerbalFormulaQuery,
  CreateCustomHerbalFormulaInput,
  UpdateCustomHerbalFormulaInput,
  CreateCustomHerbalFormulaMutation,
  UpdateCustomHerbalFormulaMutation,
  ModelSortDirection,
  GetCustomHerbalFormulaQuery,
} from '../API';

import * as queries from '../graphql/queries';
import * as customs from '../graphql/custom';
import { listHerbalFormulaByscoringType, customHerbalFormulaByOwnerUser } from '../graphql/queries';
import { herbCountMap as yejinHerbCountMap } from 'yejin-herb-list';

import { repeatCallingListApiToLimit } from 'utils/util';
import { prescriptionItemLimit } from 'constant';

class PrescriptionRepository {
  // Basic Query
  getHerbalFormula = async (prescriptionId: string) => {
    const prescription = (await API.graphql(
      graphqlOperation(
        queries.getHerbalFormula,
        { id: prescriptionId },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: GetHerbalFormulaQuery };

    return prescription.data;
  };

  getCustomHerbalFormula = async (prescriptionId: string) => {
    const prescription = (await API.graphql(
      graphqlOperation(
        queries.getCustomHerbalFormula,
        { id: prescriptionId },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: GetCustomHerbalFormulaQuery };

    return prescription.data;
  };

  updateCustomHerbalFormula = async (input: UpdateCustomHerbalFormulaInput) => {
    const updatedCustomHerbalFormula = (await API.graphql(
      graphqlOperation(
        customs.updateCustomHerbalFormula,
        { input },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: UpdateCustomHerbalFormulaMutation };
    return updatedCustomHerbalFormula;
  };

  // mutationCustomHerbalFormula
  mutationCustomHerbalFormula = async (input: CreateCustomHerbalFormulaInput) => {
    const createdCustomHerbalFormula = (await API.graphql(
      graphqlOperation(
        customs.createCustomHerbalFormula,
        { input },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: CreateCustomHerbalFormulaMutation };
    return createdCustomHerbalFormula;
  };

  deleteCustomHerbalFormula = async (id: string) => {
    const res = await API.graphql(
      graphqlOperation(
        customs.deleteCustomHerbalFormula,
        { input: { id } },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    );
    return res;
  };

  listCustomHerbalFormulas = async (userId: string) => {
    const param = {
      ownerUserID: userId,
      sortDirection: ModelSortDirection.DESC,
      filter: { isDeleted: { ne: true } },
    };
    const res = await repeatCallingListApiToLimit({
      limit: 5000,
      queryLimitBeforeFilter: 5000,
      targetGraphqlListQuery: customHerbalFormulaByOwnerUser,
      targetGraphqlListFunctionDataFieldName: 'customHerbalFormulaByOwnerUser',
      targetGraphqlListQueryParam: param,
    });
    return res;
  };

  listHerbalFormulaByscoringType = async ({
    prescriptionSearchKeyword,
    selectedProcessingCodes,
    selectedHerbCodes,
    scoringType,
  }: {
    prescriptionSearchKeyword: string;
    selectedProcessingCodes?: string[];
    selectedHerbCodes?: string[];
    scoringType: number;
  }) => {
    const param = {
      scoringType,
      filter: {},
    };
    if (prescriptionSearchKeyword) {
      param.filter['and'] = [...(param.filter['and'] ?? []), { kn: { contains: prescriptionSearchKeyword } }];
    }
    if (selectedProcessingCodes) {
      const sortedSelectedProcessingCodes = _.sortBy(
        selectedProcessingCodes,
        (e) => -(yejinHerbCountMap.get(e) ?? 0),
      ); // 처방에 포함된 횟수가 적은 약재부터 필터링을 진행함으로써 퍼포먼스 향상
      param.filter['and'] = [
        ...(param.filter['and'] ?? []),
        ...sortedSelectedProcessingCodes.map((e) => ({ processingCodes: { contains: e } })),
      ];
    }
    if (selectedHerbCodes) {
      param.filter['and'] = [
        ...(param.filter['and'] ?? []),
        ...selectedHerbCodes.map((e) => ({ herbCodes: { contains: e } })),
      ];
    }
    const res = await repeatCallingListApiToLimit({
      limit: prescriptionItemLimit + 1,
      queryLimitBeforeFilter: prescriptionItemLimit * 5,
      targetGraphqlListQuery: listHerbalFormulaByscoringType,
      targetGraphqlListFunctionDataFieldName: 'listHerbalFormulaByscoringType',
      targetGraphqlListQueryParam: param,
    });
    return res;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PrescriptionRepository();
