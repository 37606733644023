import { API, Auth, graphqlOperation } from 'aws-amplify';
import { GetUserRoleQuery, RoleByUserQuery } from '../API';
import * as queries from '../graphql/queries';
import * as customs from '../graphql/custom';

class RoleRepository {
  roleByUser = async (userID: string) => {
    const response = (await API.graphql(
      graphqlOperation(
        queries.roleByUser,
        { userID: userID },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: RoleByUserQuery };

    return response.data;
  };

  getRole = async (roleID: string) => {
    const response = (await API.graphql(
      graphqlOperation(
        queries.getUserRole,
        { id: roleID },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: GetUserRoleQuery };

    return response.data;
  };

  upateUserRoleName = async (roleID: string, userName: string) => {
    API.graphql(
      graphqlOperation(
        customs.updateUserRole,
        {
          input: { id: roleID, userName: userName },
        },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RoleRepository();
