import TreatmentHistoryList from 'components/UI/organisms/treatment/list/TreatmentHistoryList';
import AdminLayout from 'components/templates/AdminLayout';

const TreatmentHistoryPage = () => {
  return (
    <AdminLayout
      title={'진료내역'}
      content={<TreatmentHistoryList mode="treatmentHistoryList" />}
      sideType={3}
      youtubeLinkParam={{
        title: 'Ye-jin Treatment',
        src: 'https://www.youtube.com/embed/gfVThuggBGs?autoplay=1&start=65',
      }}
    />
  );
};

export default TreatmentHistoryPage;
