import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
  CreateSurveyInput,
  GetSurveyQuery,
  ListSurveysQuery,
  ModelSortDirection,
  Survey,
  UpdateSurveyInput,
  UpdateSurveyMutation,
} from '../API';
import * as queries from '../graphql/queries';
import * as customs from '../graphql/custom';
import { repeatCallingListApiToLimit } from 'utils/util';

class SurveyRepository {
  // Basic Query
  getSurvey = async (surveyID: string) => {
    const survey = (await API.graphql(
      graphqlOperation(
        queries.getSurvey,
        { id: surveyID },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: GetSurveyQuery };
    // console.log("get SURVEY ", JSON.stringify(survey));
    return survey.data;
  };

  listSurvey = async () => {
    // console.log("모든 설문 리스트 조회");
    const surveys = (await API.graphql(graphqlOperation(queries.listSurveys))) as { data: ListSurveysQuery };

    return surveys.data.listSurveys;
  };

  // Basic Mutation
  createSurvey = async (survey: CreateSurveyInput) => {
    // console.log("설문 추가");
    const newSurvey = (await API.graphql(
      graphqlOperation(
        customs.createSurvey,
        { input: survey },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: Survey };

    return newSurvey.data;
  };

  // Basic Mutation
  updateSurvey = async (params: UpdateSurveyInput) => {
    // console.log("설문 수정");
    const newSurvey = (await API.graphql(
      graphqlOperation(
        customs.updateSurvey,
        { input: params },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: UpdateSurveyMutation };

    return newSurvey.data;
  };

  surveyByDate = async ({ patientID, nextToken }: { patientID: string; nextToken?: string }) => {
    const param = {
      patientID,
      sortDirection: ModelSortDirection.DESC,
      filter: { surveyDone: { eq: true } },
    };
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      limit: 5,
      queryLimitBeforeFilter: 10,
      nextToken,
      targetGraphqlListQuery: queries.surveyByDate,
      targetGraphqlListFunctionDataFieldName: 'surveyByDate',
      targetGraphqlListQueryParam: param,
    });

    return res;
  };

  deleteSurvey = () => {};
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SurveyRepository();
