import { API, Auth, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import {
  GetTreatmentQuery,
  ModelSortDirection,
  TreatmentPatientByDateQuery,
  UpdateTreatmentInput,
  UpdateTreatmentMutation,
} from '../API';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { getReportForByeonjeung, updateTreatment } from '../graphql/custom';
import { repeatCallingListApiToLimit } from 'utils/util';

class TreatmentRepository {
  listDailyUsageStatistics = async (nextToken?: string) => {
    const res = await repeatCallingListApiToLimit({
      limit: Infinity,
      queryLimitBeforeFilter: 3000,
      nextToken,
      targetGraphqlListQuery: queries.listDailyUsageStatistics,
      targetGraphqlListFunctionDataFieldName: 'listDailyUsageStatistics',
      targetGraphqlListQueryParam: { nextToken },
    });
    return res;
  };

  deleteReport = async (treatmentID: string) => {
    await API.graphql(
      graphqlOperation(
        mutations.deleteReport,
        {
          input: {
            treatmentID,
          },
        },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    );
  };

  getTreatment = async (treatmentID: string) => {
    const response = (await API.graphql(
      graphqlOperation(
        queries.getTreatment,
        { id: treatmentID },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: GetTreatmentQuery };
    return response.data;
  };

  getReport = async (treatmentID: string) => {
    const response = (await API.graphql(
      graphqlOperation(
        getReportForByeonjeung,
        { treatmentID: treatmentID },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data };
    return response.data;
  };

  updateTreatment = async (params: UpdateTreatmentInput) => {
    const response = (await API.graphql(
      graphqlOperation(
        updateTreatment,
        { input: params },
        (await Auth.currentSession()).getIdToken().getJwtToken(),
      ),
    )) as { data: UpdateTreatmentMutation };
    return response.data;
  };

  treatmentsByPrescriptionStatus = async ({
    clinicID,
    nextToken,
    searchParam,
  }: {
    clinicID: string;
    nextToken?: string;
    searchParam?: { patientNameOrPhone?: string; dateRange?: string[] };
  }) => {
    const param = {
      clinicID: clinicID,
      sortDirection: ModelSortDirection.DESC,
      filter: {
        prescriptionStatus: { eq: 2 },
        isDeleted: { ne: true },
      },
    };
    if (!_.isEmpty(searchParam)) {
      if (searchParam?.patientNameOrPhone) {
        param.filter['patientNameForSearching'] = { contains: searchParam?.patientNameOrPhone.toLowerCase() };
      }
      if (searchParam?.dateRange) {
        param['treatmentedAt'] = { between: searchParam.dateRange };
      }
    }
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      limit: 5,
      queryLimitBeforeFilter: 10,
      nextToken,
      targetGraphqlListQuery: queries.treatmentsByPrescriptionStatus,
      targetGraphqlListFunctionDataFieldName: 'treatmentsByPrescriptionStatus',
      targetGraphqlListQueryParam: param,
    });

    return res;
  };

  treatmentPatientByDate = async ({
    patientID,
    treatmentedAt,
  }: {
    patientID: string;
    treatmentedAt?: string[];
  }) => {
    // console.log("진료내역 조회");
    try {
      const params = {
        patientID: patientID,
        sortDirection: ModelSortDirection.DESC,
        limit: 200,
        filter: {
          prescriptionStatus: { eq: 2 },
          isDeleted: { ne: true },
          treatmentedAt: { ge: treatmentedAt?.[0], lt: treatmentedAt?.[1] },
        },
      };
      if (!_.isArray(treatmentedAt) || treatmentedAt?.length !== 2) {
        delete params.filter.treatmentedAt;
      }
      const response = (await API.graphql(
        graphqlOperation(
          queries.treatmentPatientByDate,
          params,
          (await Auth.currentSession()).getIdToken().getJwtToken(),
        ),
      )) as { data: TreatmentPatientByDateQuery };
      // console.log(response.data.treatmentPatientByDate);

      return response.data.treatmentPatientByDate;
    } catch (e) {
      console.log(e);
    }
  };

  treatmentPatientByDateForInfiniteScroll = async ({
    patientID,
    nextToken,
  }: {
    patientID: string;
    nextToken?: string;
  }) => {
    const param = {
      patientID,
      sortDirection: ModelSortDirection.DESC,
      filter: {
        prescriptionStatus: { eq: 2 },
        isDeleted: { ne: true },
      },
    };
    if (nextToken) param['nextToken'] = nextToken;
    const res = await repeatCallingListApiToLimit({
      limit: 5,
      queryLimitBeforeFilter: 10,
      nextToken,
      targetGraphqlListQuery: queries.treatmentPatientByDate,
      targetGraphqlListFunctionDataFieldName: 'treatmentPatientByDate',
      targetGraphqlListQueryParam: param,
    });

    return res;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TreatmentRepository();
