/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { css } from '@emotion/css';
import message from 'antd-message';
import _ from 'lodash';
import { Loader } from '@aws-amplify/ui-react';

import { useStores } from 'stores/Context';
import { Row, Col, Input, TypographyText } from 'components/UI/atoms';
import { RangeDatePicker, Loading } from 'components/UI/molecules';
import tr from 'repository/TreatmentRepository';

import TreatmentHistoryCard from './TreatmentHistoryCard';
import TreatmentHistoryDetail from './TreatmentHistoryDetail';

const TreatmentHistoryList = ({
  mode,
  patientId,
}: {
  mode: 'treatmentHistoryList' | 'patientTreatmentHistoryList';
  patientId?: string;
}) => {
  const isMounted = useRef(false);
  const { clientStore, clinicStore } = useStores();
  const initDate = useMemo(
    () => [
      new Date(moment().startOf('day').add(-60, 'M').format('YYYY/MM/DD')),
      new Date(moment().endOf('day').format('YYYY/MM/DD')),
    ],
    [],
  );
  const [treatmentDataList, setTreatmentDataList] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState(initDate);
  const [nameOrPhoneForSearch, setNameOrPhoneForSearch] = useState<string>();
  const [nameOrPhone, setNameOrPhone] = useState<string>();
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(true);
  const [isListLoading, setIsListLoading] = useState<boolean>(false);
  const [isAdditionalListLoading, setIsAdditionalListLoading] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string | null | undefined>(undefined);
  const [target, setTarget] = useState<Element | null>(null);
  const [selectedTreatmentData, setSelectedTreatmentData] = useState<any>({});
  const [initDateTrigger, setInitDateTrigger] = useState<boolean>(false);

  const clinicId = useMemo(
    () => clientStore.metaData?.userRole.clinicID,
    [clientStore.metaData?.userRole.clinicID],
  );
  useEffect(() => {
    const ob = new IntersectionObserver(
      (e) => {
        if (e?.[0]?.isIntersecting) {
          handleSearch({ value: nameOrPhoneForSearch, dateRange, nextToken, clinicId, patientId });
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );

    target && ob.observe(target);

    return () => {
      ob.disconnect();
    };
  }, [target]);

  useEffect(() => {
    clinicStore.getClinic(clientStore.metaData?.userRole.clinicID!, false);
    document.getElementsByTagName('body')?.[0].classList.add('content-hidden');
    return () => {
      document.getElementsByTagName('body')?.[0].classList.remove('content-hidden');
    };
  }, []);

  const handleSearch = async ({
    initLoadingFlag = false,
    resetParamFlag = false,
    value,
    dateRange,
    clinicId,
    patientId,
    nextToken,
  }: {
    initLoadingFlag?: boolean;
    resetParamFlag?: boolean;
    value?: string;
    dateRange?: Date[];
    clinicId?: string;
    patientId?: string;
    nextToken?: string;
  }) => {
    try {
      if (initLoadingFlag) {
        await setIsGlobalLoading(true);
      } else {
        await setIsAdditionalListLoading(true);
      }

      if (resetParamFlag) {
        setNextToken(undefined);
        setIsListLoading(true);
      }
      let res = {
        items: [],
        nextToken: undefined,
      };
      if (mode === 'treatmentHistoryList') {
        if (!clinicId) {
          throw new Error('clinicId is not valid');
        }
        res = await tr.treatmentsByPrescriptionStatus({
          clinicID: clinicId,
          nextToken: resetParamFlag ? undefined : nextToken,
          searchParam: {
            patientNameOrPhone: value,
            dateRange: dateRange
              ? [moment(dateRange[0]).toISOString(), moment(dateRange[1]).add(1, 'd').toISOString()]
              : undefined,
          },
        });
      } else if (mode === 'patientTreatmentHistoryList') {
        if (!patientId) {
          throw new Error('patientId is not valid');
        }
        res = await tr.treatmentPatientByDateForInfiniteScroll({
          patientID: patientId,
          nextToken: resetParamFlag ? undefined : nextToken,
        });
      } else {
        throw new Error('mode is not valid');
      }

      setTreatmentDataList(
        resetParamFlag || nextToken === undefined ? res?.items : [...treatmentDataList, ...res?.items],
      );
      if (resetParamFlag) {
        setSelectedTreatmentData(res?.items?.[0]);
        setIsListLoading(false);
      }
      setNextToken(res?.nextToken);
    } catch (e) {
      console.log(e);
      message.error('진료내역 조회에 실패했습니다.');
    } finally {
      setIsGlobalLoading(false);
      setIsListLoading(false);
      setIsAdditionalListLoading(false);
    }
  };
  useEffect(() => {
    handleSearch({
      dateRange,
      value: nameOrPhoneForSearch,
      resetParamFlag: true,
      clinicId,
      patientId,
      initLoadingFlag: !isMounted.current,
    });
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedTreatmentData)) {
      // treatmentHistoryDetailWarpper 스크롤 위치 초기화
      const treatmentHistoryDetailWarpper = document.getElementById('treatmentHistoryDetailWarpper');
      treatmentHistoryDetailWarpper?.scrollTo(0, 0);
    }
  }, [selectedTreatmentData]);

  useEffect(() => {
    if (initDateTrigger) {
      setDateRange(initDate);
      setInitDateTrigger(false);
    }
  }, [initDateTrigger]);

  return (
    <Row
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1.4rem',
        flex: 1,
        width: '100%',
        height: '100%',
      }}
    >
      {mode === 'treatmentHistoryList' && (
        <Row style={{ display: 'flex', alignItems: 'center', columnGap: '0.4rem' }}>
          <RangeDatePicker
            initDateRange={initDate}
            initDateTrigger={initDateTrigger}
            setDateRangeFunc={setDateRange}
            defaultStartDate={initDate[0]}
            defaultEndDate={initDate[1]}
            onEnterPress={(dateRange) => {
              handleSearch({
                initLoadingFlag: _.isEmpty(treatmentDataList),
                value: nameOrPhoneForSearch,
                resetParamFlag: true,
                dateRange,
                clinicId,
                patientId,
              });
            }}
          />
          <Col style={{ position: 'relative' }}>
            <Input
              style={{
                height: '2.1rem',
                width: '14rem',
                border: '1px solid #afafaf',
                borderRadius: '3px',
                fontSize: '0.8rem',
                lineHeight: '0.8rem',
                outline: 'none',
                textIndent: '1.2rem',
              }}
              value={nameOrPhone}
              placeholder="환자명 또는 전화번호 뒷자리"
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  setNameOrPhoneForSearch(nameOrPhone);
                  handleSearch({
                    initLoadingFlag: _.isEmpty(treatmentDataList),
                    value: nameOrPhone,
                    resetParamFlag: true,
                    dateRange,
                    clinicId,
                    patientId,
                  });
                }
              }}
              onChange={(e) => {
                setNameOrPhone(e.target.value);
              }}
              autoComplete="off"
            />
            {nameOrPhone?.length > 0 && (
              <Col
                style={{
                  position: 'absolute',
                  display: 'flex',
                  right: '0.5rem',
                  top: '0.6rem',
                  cursor: 'pointer',
                  height: '1rem',
                }}
                onClick={() => {
                  setNameOrPhone(undefined);
                  setNameOrPhoneForSearch(undefined);
                  setDateRange(initDate);
                  handleSearch({
                    initLoadingFlag: _.isEmpty(treatmentDataList),
                    value: undefined,
                    resetParamFlag: true,
                    dateRange: initDate,
                    clinicId,
                    patientId,
                  });
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/serach_initialization_icon.webp`}
                  style={{ width: '1rem' }}
                  alt="serach_initialization_icon"
                />
              </Col>
            )}
            <Col
              style={{
                position: 'absolute',
                display: 'flex',
                left: '0.5rem',
                top: 'calc(50% + 0.05rem)',
                transform: 'translateY(-50%)',
                height: '1rem',
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/search_icon.webp`}
                style={{ width: '1rem' }}
                alt="search_icon"
              />
            </Col>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              height: '2.1rem',
              border: '1px solid #346AFF',
              borderRadius: '3px',
              background: '#FFFFFF',
              cursor: 'pointer',
              flexShrink: 0,
            }}
            onClick={() => {
              handleSearch({
                initLoadingFlag: _.isEmpty(treatmentDataList),
                value: nameOrPhoneForSearch,
                resetParamFlag: true,
                dateRange,
                clinicId,
                patientId,
              });
            }}
          >
            <TypographyText
              color="#346AFF"
              fontSize="1rem"
              letterSpacing="0.05em"
              fontWeight={700}
              lineHeight="1.2rem"
            >
              검색
            </TypographyText>
          </Col>
          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              height: '2.1rem',
              border: '1px solid #afafaf',
              borderRadius: '3px',
              background: '#ffffff',
              cursor: 'pointer',
              flexShrink: 0,
            }}
            onClick={() => {
              setNameOrPhone(undefined);
              setNameOrPhoneForSearch(undefined);
              setInitDateTrigger(true);
              handleSearch({
                initLoadingFlag: _.isEmpty(treatmentDataList),
                value: undefined,
                resetParamFlag: true,
                dateRange: initDate,
                clinicId,
                patientId,
              });
            }}
          >
            <TypographyText
              color="#1c252c"
              fontSize="1rem"
              letterSpacing="0.05em"
              fontWeight={700}
              lineHeight="1.2rem"
            >
              초기화
            </TypographyText>
          </Col>
        </Row>
      )}
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: 'calc(100% + 4.8rem)',
          marginLeft: '-2.4rem',
          padding: '1.4rem 2.4rem',
          flex: 1,
        }}
      >
        {isGlobalLoading ? (
          <Loading />
        ) : (
          <Row
            style={{
              height: '100%',
              flex: '1 1 0',
              display: 'flex',
              columnGap: '0.9rem',
            }}
          >
            {_.isEmpty(treatmentDataList) ? (
              <Row
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: '15%',
                  left: '45%',
                }}
              >
                <TypographyText yjTypeTypographyText="symptom-table-body" color="#AFAFAF">
                  {`진료 내역이 없습니다.`}
                </TypographyText>
              </Row>
            ) : (
              <>
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '525 1 0',
                    // height: '100%',
                  }}
                >
                  <Row
                    style={{
                      position: 'relative',
                      flex: '1 1 0',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '0.75rem',
                      height: '100%',
                      maxHeight: '100%',
                      overflowY: 'scroll',
                      paddingRight: '0.35rem',
                    }}
                    className={css`
                      &::-webkit-scrollbar {
                        width: 0.35rem;
                      }
                      &::-webkit-scrollbar-thumb {
                        background-color: #ebebeb;
                        border-radius: 0;
                        cursor: pointer;
                      }
                    `}
                  >
                    {isListLoading ? (
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '90%',
                        }}
                      >
                        <Loader size="large" filledColor={'gold'} width={'80px'} height={'80px'} />
                      </Row>
                    ) : (
                      treatmentDataList.map((t, index) => {
                        return (
                          <div key={t.id} ref={treatmentDataList.length - 1 === index ? setTarget : null}>
                            <TreatmentHistoryCard
                              mode={mode}
                              treatmentData={t}
                              selectedFlag={t.id === selectedTreatmentData?.id}
                              setSelectedTreatmentData={setSelectedTreatmentData}
                            />
                          </div>
                        );
                      })
                    )}
                    {!isListLoading && isAdditionalListLoading && (
                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Loader size="small" filledColor={'gold'} width={'4rem'} height={'4rem'} />
                      </Row>
                    )}
                  </Row>
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1010 1 0',
                    // height: '100%',
                  }}
                >
                  <Row
                    id="treatmentHistoryDetailWarpper"
                    style={{
                      flex: '1 1 0',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      maxHeight: '100%',
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                    }}
                    className={css`
                      &::-webkit-scrollbar {
                        width: 0.35rem;
                      }
                      &::-webkit-scrollbar-thumb {
                        background-color: #ebebeb;
                        border-radius: 0;
                        cursor: pointer;
                      }
                    `}
                  >
                    <TreatmentHistoryDetail treatmentData={selectedTreatmentData} />
                  </Row>
                </Col>
              </>
            )}
          </Row>
        )}
      </Row>
    </Row>
  );
};

export default observer(TreatmentHistoryList);
